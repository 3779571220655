import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import Question from "../../models/Question";
import {isMobile} from "react-device-detect";
import Palette from "../../util/Palette";
import Button from "react-bootstrap/Button";

export default function QnASection(props) {
    const {session} = props;
    const [questions, setQuestions] = useState([]);
    const [isQnAExtended, setQnAExtended] = useState(false)

    useEffect(() => {
        getQnA();
    }, [session])

    const getQnA = async () => {
        console.log("getting " + session.show_answers_to_user, session)
        if (session.show_answers_to_user) {
            try {
                const result = await new Question().getAll(session.code)
                const questionsTemp = [];

                result.map(question => {
                    if (question.answer) {
                        questionsTemp.push(question);
                    }
                })

                console.log(questionsTemp.length)
                setQuestions(questionsTemp)
            } catch (e) {
                console.log(e)
            }
        }
    }

    if (!session.show_answers_to_user || questions.length === 0) return null;

    return (
        <>
            <Row style={{
                fontFamily: 'OpenSans-Bold',
                color: '#302a28',
                fontSize: 16,
                marginTop: 50,
                marginLeft: 0
            }}>
                Pertanyaan
            </Row>

            <Row style={{marginTop: 30}}>
                {
                    questions.map(
                        (question, key) => {
                            if (!isQnAExtended && key > 1) {
                                return
                            }

                            return (
                                <Col xs={12} style={{marginBottom: 30}}>
                                    <div style={{
                                        border: '1px solid #D7D7D7CC',
                                        borderRadius: 8,
                                        paddingTop: 25,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                        <div
                                            style={{paddingLeft: 25, paddingRight: 25, flex: 1, paddingBottom: 25}}>
                                            <div style={{display: 'flex'}}>
                                                <img
                                                    style={{
                                                        width: '2.5em',
                                                        objectFit: "contain",
                                                        borderRadius: "50%",
                                                        height: '2.5em',
                                                        padding: 1,
                                                        backgroundColor: Palette.PRIMARY
                                                    }}
                                                    src={question.image_url ? question.image_url : require("../../asset/public_image_default_user.png")}/>

                                                <div style={{flex: 1, marginLeft: 15}}>
                                                    <div style={{
                                                        fontFamily: 'OpenSans-SemiBold',
                                                        fontSize: '1.2em',
                                                        whiteSpace: 'pre-line'
                                                    }}>
                                                        {question.question}
                                                    </div>

                                                    <div style={{
                                                        fontFamily: 'OpenSans-Regular',
                                                        fontSize: '.9em',
                                                        color: '#bababa'
                                                    }}>
                                                        {question.full_name}
                                                    </div>

                                                    <div style={{
                                                        marginLeft: 2,
                                                        color: '#5F5555A6',
                                                        fontFamily: 'OpenSans-Regular',
                                                        marginTop: 10,
                                                        flex: 1,
                                                        whiteSpace: 'pre-line'
                                                    }}>
                                                        {question.answer}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </Col>
                            )
                        }
                    )
                }

                {/*<Col xs={12} style={{marginBottom: 30}}>*/}
                {/*    <div style={{*/}
                {/*        border: '1px solid #D7D7D7CC',*/}
                {/*        borderRadius: 8,*/}
                {/*        paddingTop: 25,*/}
                {/*        display: 'flex',*/}
                {/*        flexDirection: 'column',*/}
                {/*    }}>*/}
                {/*        <div style={{paddingLeft: 25, paddingRight: 25, flex: 1, paddingBottom: 25}}>*/}
                {/*            <div style={{*/}
                {/*                fontFamily: 'OpenSans-SemiBold',*/}
                {/*                fontSize: '1.2em'*/}
                {/*            }}>*/}
                {/*                Tahu gak kamu apa alasan ninja memakai kain untuk penutup kepala?*/}
                {/*            </div>*/}

                {/*            <div style={{*/}
                {/*                fontFamily: 'OpenSans-Regular',*/}
                {/*                fontSize: '.9em',*/}
                {/*                color: 'grey'*/}
                {/*            }}>*/}
                {/*                Budi Santoso*/}
                {/*            </div>*/}

                {/*<div style={{marginTop: 10, display: 'flex', justifyContent: 'flex-end'}}>*/}
                {/*        <span style={{*/}
                {/*            backgroundColor: 'grey',*/}
                {/*            color: 'white',*/}
                {/*            fontFamily: 'OpenSans-SemiBold',*/}
                {/*            fontSize: '.85em',*/}
                {/*            paddingLeft: 20,*/}
                {/*            paddingRight: 20,*/}
                {/*            paddingTop: 3,*/}
                {/*            paddingBottom: 3,*/}
                {/*            borderRadius: 100,*/}
                {/*        }}>*/}
                {/*        Belum Terjawab*/}
                {/*        </span>*/}
                {/*</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Col>*/}

                <Col md={12}>
                    {
                        questions.length <= 2 ? null : <Button
                            style={{color: Palette.PRIMARY, fontFamily: 'OpenSans-SemiBold'}}
                            variant={'link'}
                            onClick={() => {
                                setQnAExtended(!isQnAExtended)
                            }}>
                                { isQnAExtended ? 'Tampilkan Beberapa' : 'Tampilkan Semua Pertanyaan'}
                        </Button>
                    }

                </Col>
            </Row>
        </>
    )
}
