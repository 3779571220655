import React, {Component, useEffect, useState} from "react";
import {Button, Dropdown, Nav, Navbar, NavDropdown} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import Palette from "../../util/Palette";
import {useHistory, useLocation} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import GlobalData from "../../util/GlobalData";
import EditorPageWrapper from "./editor/EditorPageWrapper";

export default function NotFound(props) {

    let history = useHistory()
    let routerLocation = useLocation()

    let routeName = "not-found"

    if(routerLocation.state){
        if(routerLocation.state.route){
            routeName = routerLocation.state.route
            window.history.pushState({}, '', GlobalData.domain+routeName);
        }
    }


    return (
        <EditorPageWrapper>
            <>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: isMobile ? "-6em" : '0em',
                }}>

                    <Container style={{
                        height: "100%",
                        color: Palette.SECONDARY,
                        flexDirection : "column",
                    }}>
                        <Row>
                            <Col
                                style={{
                                    textAlign: "center",
                                    display: "flex", justifyContent: "center",
                                    flexDirection  :"column"
                                }}
                                xs={12}>
                                <p style={{fontSize: "10em", fontWeight: "bold", marginTop : "0.5em"}}>404</p>
                                <p style={{fontSize: "2em"}}>Halaman yang kamu cari tidak ditemukan.</p>

                            </Col>
                        </Row>
                        <Row style={{
                            display : "flex",
                            justifyContent : "center"
                        }}>
                        </Row>
                    </Container>

                </div>
            </>
        </EditorPageWrapper>

    );

}
