import React, {Component, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {Button, Card, Col, Container, Dropdown, Nav, Navbar, NavDropdown, Row, Spinner, Tabs} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {TextField, Button as MaterialButton, ButtonGroup} from "@material-ui/core";
import Palette from "../../../../util/Palette";
import Dropzone from "react-dropzone";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {FaPlus, FaClock, FaPen, FaSave, FaTimes} from "react-icons/fa";
import EditorPageWrapper from "../EditorPageWrapper";
import IconButton from "@material-ui/core/IconButton";
import TemplateValidator from "../../../../util/TemplateValidator"

import 'moment/locale/id'

import Event from "../../../../models/Event"
import CustomButton from "../../../reusable/CustomButton";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Session from "../../../../models/Session";
import {FaUserFriends, FaBullhorn, FaMailBulk, MdSend} from "react-icons/all";
import Tab from "react-bootstrap/Tab";
import CreatableSelect from 'react-select/creatable';
import textFormatter from "../../../../util/textFormatter";
import Email from "../../../../models/Email";
import Class from "../../../../models/Class"
import FileUpload from "../../../reusable/FileUpload";
import Table from "react-bootstrap/Table"
import EmailTemplateEditorDialog from "./specifics/EmailTemplateEditorDialog"
import EmailTemplateDropdown from "./specifics/EmailTemplateDropdown"
import EmailTemplatePreview from "./specifics/EmailTemplatePreview"
import {animateScroll as scroll} from "react-scroll";


import EmailDisplayConfiguration from "./config/EmailDisplayConfiguration"
import MobTable from "../../../reusable/Table/MobTable"
import LagFreeTextEditor from "../../../reusable/LagFreeTextEditor";
import EmailBroadcast from "../../../reusable/EmailBroadcast";

export default function EmailManagementForGlobal(props) {

    let history = useHistory()
    let routerLocation = useLocation()

    //Prompt Params
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [activeSession, setActiveSession] = useState({})

    //Email Template States
    const [emailTemplates, setEmailTemplates] = useState([])
    const [isEmailTemplatesLoading, setIsEmailTemplatesLoading] = useState(false)
    const [showEditEmailTemplateDialog, setShowEmailTemplateDialog] = useState(false)
    const [showCreateEmailTemplateDialog, setShowCreateEmailTemplateDialog] = useState(false)
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState({})
    //array that contains the problems of the email templates

    //Send Email State
    const [recipients, setRecipients] = useState([])
    const [registrants, setRegistrants] = useState([])
    const [emailSubject, setEmailSubject] = useState("")
    const [emailContent, setEmailContent]= useState("")

    const [attachments, setAttachments] = useState([])
    const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)

    const [preview, setPreview] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id"
            },
            {
                Header: "Template Name",
                accessor: "template_name"
            },
            {
                Header: "Template Subject",
                accessor: "subject_template"
            },
            {
                Header: "Skenario",
                accessor: "scenario"
            }
        ]
    )

    const listStyle = {
        marginBottom: "20px"
    }



    useEffect(() => {
        setIsEmailTemplatesLoading(true)
        initializeData()
    }, [])


    const findEmailTemplate = (templateId) => {
        return emailTemplates.find(e => e.id === templateId)
    }


    const filterEmailTemplatesByScenario = (scenario) =>{
        return emailTemplates.filter((emailTemplate) => emailTemplate.scenario === scenario)
    }

    const renderTemplateEmailBody = () => {

        let interactions = [
            {
                name: "Edit",
                action: (emailTemplate) =>{

                    console.log("email template ", emailTemplate)

                    setSelectedEmailTemplate(emailTemplate)
                    setShowEmailTemplateDialog(true)
                }
            },
        ]

        return <>

            <EmailTemplateEditorDialog
                mode={"edit"}
                show={showEditEmailTemplateDialog}
                emailTemplate={selectedEmailTemplate}
                handleClose={() => {setShowEmailTemplateDialog(!showEditEmailTemplateDialog)}}
                activeSession={activeSession}
                onSaveSuccess={async (templateObject) =>{
                    //overwrite new values
                    setSelectedEmailTemplate({
                        ...selectedEmailTemplate,
                        ...templateObject
                    })
                    await getEmailTemplates()

            }}/>

            <EmailTemplateEditorDialog
                mode={"create"}
                show={showCreateEmailTemplateDialog}
                handleClose={() => {setShowCreateEmailTemplateDialog(!showCreateEmailTemplateDialog)}}
                activeSession={activeSession}
                onSaveSuccess={async (templateObject) =>{
                    setEmailTemplates([...emailTemplates,templateObject])
                }}/>


            <b style={{
                color: Palette.PRIMARY,
                fontSize: "2em",
                fontFamily: "OpenSans-Bold",
            }}>
                Pengaturan Email
            </b>
            <div style={{paddingBottom: "50px"}}>
                <MobTable columns={columns} data={emailTemplates} interactions={interactions}/>
                <div><Button style={{color: 'white'}} onClick={() => {setShowCreateEmailTemplateDialog(true)}}>Buat Template Baru</Button></div>
            </div>
        </>
    }


    const getRegistrations = async function (paramSession) {

        setIsLoadingBar(true)
        const session = new Session()

        console.log("active session", paramSession)

        try {
            let result = await session.getRegistrations(paramSession.id)
            console.log(result)

            let temp = result.map((obj, key) => {
                let tempJSON = JSON.parse(obj.visitor_information)
                return {
                    value : tempJSON["Email"],
                    label : tempJSON["Nama Lengkap"]
                }
            })

            setRegistrants(temp)
            console.log("temp", temp)

        } catch (e) {
            console.log(e)
        }
        setIsLoadingBar(false)

    }

    const initializeData = function() {
        let promises = []
        promises.push(getEmailTemplates())
        Promise.all(promises).then(results=>{
            setIsEmailTemplatesLoading(false)
        }).catch(e=>{
            alert("Terjadi Kesalahan")
            console.log(e)
        })

    }


    const getEmailTemplates = async function() {
        setIsEmailTemplatesLoading(true)

        const email = new Email()

        try{
            let templates = await email.getTemplates()

            console.log(templates)

            setEmailTemplates(templates)

            console.log(emailTemplates)

        } catch (e) {
            console.error(e)
            setIsEmailTemplatesLoading(false)
        }
        setIsEmailTemplatesLoading(false)
    }


    const processSubmit = async() =>{
        setIsLoadingBar(true)
        let email = new Email();

        if(recipients.length === 0){
            setIsLoadingBar(false)
            return promptError("Harap masukan tujuan pengiriman Email")
        }

        if(emailSubject.length === 0){
            setIsLoadingBar(false)
            return promptError("Harap mengisi subyek dari Email.")
        }

        if(emailContent.length === 0){
            setIsLoadingBar(false)
            return promptError("Isi Email tidak boleh kosong.")
        }

        try {

            let formattedRecipients = recipients.map((obj,key)=>{
                return obj.value
            })

            let formattedAttachments = attachments.map((obj,key)=> {
                return {
                    filename : obj.label,
                    path : obj.value
                }
            })

            let result = await email.sendBatch({
                "recipients": formattedRecipients,
                "subject": emailSubject,
                "content": emailContent,
                "attachments" : formattedAttachments
            })
            console.log(result)
            setIsLoadingBar(false)

            promptSuccess("Email Telah Diproses. Pada umumnya email akan terkirim dalam beberapa menit. Jika sudah beberapa jam dan email belum terkirim, harap hubungi tim kami.")

        }catch(e){
            console.log(e)
            setIsLoadingBar(false)
            promptError(JSON.parse(e));
        }
    }

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"email-management"}
            >
                {renderAlertBox()}
                {renderSuccessBox()}
                <Tabs defaultActiveKey="template" id="uncontrolled-tab-example">
                    <Tab
                        eventKey="template"
                        title={
                            <span style={{
                                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"
                            }}>
                                <FaMailBulk/>&nbsp;Template Email
                            </span>
                        }
                    >
                        <div style={{
                            padding: "1em"
                        }}>
                            {renderTemplateEmailBody()}
                        </div>
                    </Tab>

                    <Tab
                        title={
                            <span style={{
                                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"
                            }}>
                                <FaBullhorn/>&nbsp;Broadcast Email
                            </span>
                        }
                        eventKey="broadcast">
                        <div style={{
                            padding: "1em"
                        }}>
                            <EmailBroadcast
                                isLoadingBar={isLoadingBar}
                                setIsLoadingBar={setIsLoadingBar}
                            />
                        </div>
                    </Tab>
                </Tabs>
            </EditorPageWrapper>
        </>

    )

}
