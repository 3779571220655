import moment from "moment"
import ApiRequest from "../util/ApiRequest";

export const PAYMENT_TYPE = {
    "FREE": "FREE",
    "PAY_ONLINE": "PAY_ONLINE",
    "PAY_OFFLINE": "PAY_OFFLINE"
}

export const EVENT_STATUS = {
    "COUNTING_DOWN": "COUNTING_DOWN",
    "FINISHED": "FINISHED",
    "LIVE": "LIVE"
}

export const PUBLISH_STATUS = {
    "INITIAL": "INITIAL",
    "DRAFT": "DRAFT",
    "OPEN": "OPEN",
    "HIDDEN": "HIDDEN",
    "LIVE" : "LIVE",
    "DONE" : "DONE"
}


export default class Event {

    constructor() {
        this.id = -1
        this.name = "" //ada //WAJIB
        this.type = "type1" //smtr gk ada
        this.start_time = moment() // ada //WAJIB
        this.end_time = moment() // ada //WAJIB
        this.icon = null // kosonign dlu
        this.venue = "" // nanti gw tambahin //WAJIB
        this.description = "" // ada
        this.price = 0 // smtr gk ada
    }

    formatter(event){
        return {
            ...event,
            color_dark : event.color_dark,
            color_primary : event.color_primary,
            description: event.description,
            enabled: event.enabled,
            start_time : new moment(event.start_time),
            end_time: new moment(event.end_time),
            event_banner_url: event.event_banner_url,
        }
    }

    getAll = async () =>{

        let events = await ApiRequest.set("/v2/events", "GET");

        console.log(events)

        return events

    }

    getEventByURL = async (URL) => {


        let event = await ApiRequest.set("/v2/events/url/"+URL, "GET");
        return event

    }

    getEventMetrics = async (eventId, sessionId) => {

        let event = await ApiRequest.set(`/v2/events/${eventId}/detailMetrics?sessionId=${sessionId}`, "GET");

        return event

    }

    checkURLAvailability = async (url, eventId) => {
        let event = await ApiRequest.set(`/v2/events/check/url?url=${url}&eventId=${eventId}`, "GET");

        return event
    }


    uploadIcon = async (image,id) => {

        let formData = new FormData();

        console.log('blob', image)
        formData.append('upload', image, image.name);
        const response = await ApiRequest.setMultipart('/v2/events/' + (id?id:0) + '/event_icon/uploadWupdate', 'POST', formData);

        return response;

    }

    uploadBanner = async (image,id) => {

        let formData = new FormData();

        console.log('blob', image)
        formData.append('upload', image, image.name);
        const response = await ApiRequest.setMultipart('/v2/events/' + (id?id:0) + '/event_banner/uploadWupdate', 'POST', formData);

        return response;

    }

    createSingleSessionEvent = async(params)=>{

        let result = await ApiRequest.set("/v2/single_session_event", "POST", {
            event : params.event,
            session : params.session
        });

        return result

    }

    updateSingleSessionEvent = async(params)=>{
        let result = await ApiRequest.set("/v2/single_session_event/"+params.event.id, "PATCH", {
            event : params.event,
            session : params.session
        });

        return result
    }

    updatePublishStatus = async(eventId)=>{
        let result = await ApiRequest.set(`/v2/events/${eventId}/publishStatus/toggle`, "PATCH");

        return result
    }

}
