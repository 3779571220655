import React, {Component, useEffect, useState} from "react";
import {Slide} from "react-reveal";
import {Helmet} from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "../page/PageWrapper";
import Palette from "../../util/Palette";
import {Button, Navbar, Dropdown, Card} from "react-bootstrap";
import GlobalData from "../../util/GlobalData";
import {useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import {useCookies} from "react-cookie";
import {withCookies, Cookies} from 'react-cookie';
import {FaBars} from "react-icons/all";

import Container from "react-bootstrap/Container";
import EditProfileModal from "./EditProfileModal";
import NewViewEventDialog from "./modals/NewViewEventDialog";
import LoginModal from "./LoginModal";
import CustomDrawer from "./CustomDrawer";
import {IconButton} from '@material-ui/core';

export default function Header(props) {

    let [scrollPosition, setScrollPosition] = useState(0)
    const [editProfileShow, setEditProfileShow] = useState(false)
    let history = useHistory()
    let user = GlobalData.user
    const [cookie, setCookie, removeCookie] = useCookies(["token"])
    // let [isSideBarOpen, setIsSideBarOpen] = useState(true)

    let [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false)

    let header;

    if (isMobile) {
        header = <Slide top when={
            !props.shy || scrollPosition > 0.1
        }>
            <div style={{
                width: "100%",
                height: "4.5em",
                position: "fixed",
                top: 0,
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                background: "white",
                boxShadow: "0px 4px 8px 1px #888888"
            }}>
                {/*<Helmet>*/}
                {/*    <title>patjarmerah</title>*/}
                {/*    <meta name="description" content="Festival kecil literasi dan pasar buku keliling nusantara"/>*/}
                {/*    <meta name="keywords"*/}
                {/*          content="patjarmerah, patjar, merah, festival, buku, keliling, murah, diskon"/>*/}
                {/*</Helmet>*/}

                <Row style={{
                    alignItems: "center"
                }}>

                    <div style={{
                        margin: "absolute",
                        position: "absolute",
                        left: "0%",
                        width: "12%",
                        display: GlobalData.user ? "flex" : "none",
                        flexDirection: "row-reverse"
                    }}>
                        <IconButton
                            onClick={() => {
                                props.setIsSideBarOpen(!props.isSideBarOpen)
                            }}
                            onMouseDown={e => e.preventDefault()}
                        >
                            <FaBars style={{
                                fontSize: "1em"
                            }}/>
                        </IconButton>
                    </div>

                    <div>
                        <img
                            style={{
                                width: "100%",
                                objectFit: "contain",
                                maxHeight: "3.5em"
                            }}
                            src={require("../../asset/patjarmerah.png")}
                        />
                    </div>

                </Row>

            </div>
        </Slide>
    } else {

        header = <div style={{
            display: GlobalData.token ? "relative" : "fixed",
            flexDirection: "column"
        }}>
            <div style={{
                width: "100%",
                position: "fixed",
                top: 0,
                zIndex: 1000,
                display: "flex",
                background: "white",
                boxShadow: "1px 1px 1px 1px #eeeeee",
                padding: '10px'
            }}>

                <div xs={1} style={{
                    // position: "absolute",
                    // left: "1em",
                    // bottom: 0,
                    // top: '50%',
                    // transform: 'translateY(-50%)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '1em'
                }}>
                    <IconButton
                        onClick={() => {
                            props.setIsSideBarOpen(!props.isSideBarOpen)
                        }}
                        onMouseDown={e => e.preventDefault()}

                    >
                        <FaBars style={{
                            fontSize: "1em",
                            color : Palette.SECONDARY
                        }}/>
                    </IconButton>
                </div>
                <div>
                    <a>
                        <img
                            style={{
                                maxHeight: "4em",
                                objectFit: "contain",
                                marginTop: '6px',
                                padding : "4%",
                                cursor : "pointer",
                                marginLeft: '10px'
                            }}
                            onClick={()=>history.push("/")}
                            src={require("../../asset/patjarmerah.png")}
                        />
                    </a>
                </div>

                {/*<Container fluid style={{backgroundColor: 'red'}}>*/}
                {/*    /!*<Helmet>*!/*/}
                {/*    /!*    <title>patjarmerah</title>*!/*/}
                {/*    /!*    <meta name="description" content="Festival kecil literasi dan pasar buku keliling nusantara"/>*!/*/}
                {/*    /!*    <meta name="keywords"*!/*/}
                {/*    /!*          content="patjarmerah, patjar, merah, festival, buku, keliling, murah, diskon"/>*!/*/}
                {/*    /!*</Helmet>*!/*/}


                {/*    <Row style={{*/}
                {/*        display: "flex",*/}
                {/*        justifyContent: "flex-end"*/}
                {/*    }}>*/}

                {/*        <Col md={8} xs={10} style={{*/}
                {/*            marginBottom: "0.5em",*/}
                {/*            display: 'flex', alignItems: 'flex-end', justifyContent: "flex-start"*/}
                {/*        }}>*/}
                {/*            <a>*/}
                {/*                <img*/}
                {/*                    style={{*/}
                {/*                        maxHeight: "4em",*/}
                {/*                        objectFit: "contain",*/}
                {/*                        marginTop: '6px',*/}
                {/*                        padding : "4%",*/}
                {/*                        cursor : "pointer",*/}
                {/*                        marginLeft: '15px'*/}
                {/*                    }}*/}
                {/*                    onClick={()=>history.push("/")}*/}
                {/*                    src={require("../../asset/narabahasa-logo.png")}*/}
                {/*                />*/}
                {/*            </a>*/}
                {/*        </Col>*/}

                {/*        <Col md={4} style={{*/}
                {/*            marginBottom: "0.5em",*/}
                {/*            display: 'flex', alignItems: 'flex-end', justifyContent: "flex-end",*/}
                {/*            color : Palette.SECONDARY*/}
                {/*        }}>*/}



                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</Container>*/}
            </div>
        </div>


    }

    return <>
        {header}
        <EditProfileModal
            onHide={() => {
                setEditProfileShow(false)
            }}
            show={editProfileShow}
        />

        <NewViewEventDialog
            closeDialog={() => {
                setIsCreateEventModalOpen(false)
            }}
            show={isCreateEventModalOpen}/>

        <CustomDrawer
            isOpen={props.isSideBarOpen}
            onClose={() => {
                props.setIsSideBarOpen(false)
            }}
            activeTab={props.activeTab}
            setIsSideBarOpen={(isOpen)=>props.setIsSideBarOpen(isOpen)}
        />

    </>

}
