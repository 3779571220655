import React, {useEffect, useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
} from "@material-ui/core";

import {Col, Container, ListGroup, Modal, ModalBody, ModalTitle, Row, Tabs} from "react-bootstrap";
import CustomButton from "../CustomButton";
import ModalHeader from "react-bootstrap/ModalHeader";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";

import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Palette from "../../../util/Palette";
import OptionsFieldsEditor from "../OptionsFieldsEditor";
import textFormatter from "../../../util/textFormatter";
import {FiAlignJustify, FiCalendar, FiClock, FiInfo, FiMapPin, FiTv} from "react-icons/fi";
import {TiTicket} from "react-icons/ti";
import Tab from "react-bootstrap/Tab";
import {FaClipboardList, FaMailBulk, FaPlaneArrival} from "react-icons/all";
import DetailSection from "../DetailSection";
import RegisterForm from "../RegisterForm";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewModal(props) {

    const {event, session, onClose} = props;

    return (
        <Dialog
            open={props.show}
            maxWidth="lg"
            style={{
                fontFamily: "Montserrat"
            }}
            fullWidth={true}
            onClose={() => onClose()}
            TransitionComponent={Transition}>
            <>
                <ModalHeader>
                    <ModalTitle
                        style={{
                            fontFamily: "Montserrat"
                        }}
                        onClose={() => onClose()}>
                        <span style={{
                            fontFamily: "Montserrat",
                            fontWeight: "bold"
                        }}>Preview</span>
                    </ModalTitle>
                </ModalHeader>

                <ModalBody style={{
                    padding: 0
                }}>

                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                        <Tab
                            eventKey="home"
                            title={
                                <span style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                 <FaPlaneArrival/>&nbsp;Halaman Landing
                                </span>
                            }
                        >
                            <Container fluid>


                                <div>
                                    {/*<Row>*/}
                                    {/*    <Button onClick={() => history.push('/' + url + '/registration-success', {id: 5, fullName: 'Timotius Eka Valentino', email: 'eka.valentino@gmail.com'})}>abc</Button>*/}
                                    {/*</Row>*/}
                                    <Row style={{height: '50vh', overflow: 'hidden'}}>
                                        <img src={session.bannerUrl}
                                             style={{width: '100%', filter: 'blur(15px)', transform: 'scale(2)'}}/>
                                    </Row>

                                    <Container
                                        fluid
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            position: 'relative',
                                            top: '-47vh',
                                            marginBottom: '-47vh'
                                        }}
                                    >
                                        <Row>
                                            <img src={session.bannerUrl}
                                                 style={{
                                                     objectFit: 'cover',
                                                     width: '100%',
                                                     maxHeight: '45vh',
                                                     borderTopLeftRadius: 10,
                                                     borderTopRightRadius: 10,
                                                 }}/>
                                        </Row>

                                        <Row style={{
                                            backgroundColor: 'white',
                                            boxShadow: '0 1px 1px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.24)',
                                            borderBottomLeftRadius: 10,
                                            borderBottomRightRadius: 10,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            paddingTop: 25,
                                            marginBottom: 30,
                                        }}>
                                            <Container>
                                                <Row>
                                                    <Col md={9}>
                                                        <Row style={{
                                                            fontFamily: 'Nunito Sans',
                                                            fontWeight: '700',
                                                            fontSize: `calc(.7vw + 1rem)`,
                                                            marginLeft: 5,
                                                        }}>
                                                            {event.name}
                                                        </Row>
                                                        <Row style={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '300',
                                                            marginLeft: 5,
                                                        }}>
                                                            <div style={{
                                                                whiteSpace: "pre-line",
                                                            }}>
                                                                {event.shortDescription}
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col>
                                                        <Button variant={'outline-dark'}
                                                                disabled={true}
                                                                style={{
                                                                    width: '100%',
                                                                    borderRadius: 0,
                                                                    minHeight: '45px',
                                                                    fontFamily: 'Poppins',
                                                                    fontSize: 'calc(.1vw + 1rem)',
                                                                    marginTop: 15,
                                                                    marginBottom: 15,
                                                                    borderColor: 'grey'
                                                                }}>
                                                        </Button>
                                                    </Col>

                                                </Row>
                                            </Container>

                                            <span style={{
                                                width: '100%',
                                                height: 2,
                                                backgroundColor: '#f2f2f2',
                                                marginTop: 18,
                                                marginBottom: 30
                                            }}/>


                                            {/*<Row style={{marginBottom: 5}}>*/}
                                            {/*    <Col xs={2} md={1}*/}
                                            {/*         style={{*/}
                                            {/*             display: 'flex',*/}
                                            {/*             alignItems: 'center',*/}
                                            {/*             justifyContent: 'flex-end',*/}
                                            {/*             marginLeft: 2*/}
                                            {/*         }}>*/}
                                            {/*        <FiInfo/>*/}
                                            {/*    </Col>*/}
                                            {/*    <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '500'}}>*/}
                                            {/*        Status Registrasi*/}
                                            {/*    </Col>*/}
                                            {/*</Row>*/}

                                            {/*<Row>*/}
                                            {/*    <Col xs={2} md={1} style={{*/}
                                            {/*        display: 'flex',*/}
                                            {/*        alignItems: 'center',*/}
                                            {/*        justifyContent: 'flex-end',*/}
                                            {/*        marginLeft: 2*/}
                                            {/*    }}/>*/}
                                            {/*    <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '300'}}>*/}
                                            {/*        Menunggu Pembayaran*/}
                                            {/*    </Col>*/}
                                            {/*</Row>*/}

                                            <Row style={{
                                                // marginTop: 30,
                                                marginBottom: 5
                                            }}>
                                                <Col xs={2} md={1}
                                                     style={{
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         justifyContent: 'flex-end',
                                                         marginLeft: 2
                                                     }}>
                                                    <FiTv/>
                                                </Col>
                                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '500'}}>
                                                    URL Streaming
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={2} md={1} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    marginLeft: 2
                                                }}/>
                                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '300'}}>
                                                    {/*<Button variant={'outline-dark'} disabled>Tersedia dalam 03:50:01</Button>*/}
                                                    {/*<Button variant={'outline-dark'}>YouTube</Button>*/}

                                                    {/*<Button variant={'outline-dark'} style={{marginLeft: 10}}>Zoom</Button>*/}

                                                    {/*<Button variant={'outline-dark'} style={{marginLeft: 10}}>Google Meet</Button>*/}
                                                    Tersedia setelah login
                                                    {/*Ticket is required for this event*/}
                                                </Col>
                                            </Row>

                                            <Row style={{marginTop: 30, marginBottom: 5}}>
                                                <Col xs={2} md={1}
                                                     style={{
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         justifyContent: 'flex-end',
                                                         marginLeft: 2
                                                     }}>
                                                    <TiTicket/>
                                                </Col>
                                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '500'}}>
                                                    Harga Tiket
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={2} md={1} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    marginLeft: 2
                                                }}/>
                                                <Col style={{padding: 0}}>
                                                    <div style={{fontFamily: 'Poppins', fontWeight: '300'}}>
                                                        {event.price === 0 ? 'Gratis' : event.price ? 'Rp' + textFormatter.moneyFormatter(event.price) : null}
                                                    </div>
                                                </Col>
                                            </Row>

                                            {/*<Row>*/}
                                            {/*    <Col style={{*/}
                                            {/*        display: 'flex',*/}
                                            {/*        alignItems: 'center',*/}
                                            {/*        marginLeft: 15,*/}
                                            {/*        marginRight: 15,*/}
                                            {/*        marginTop: 30*/}
                                            {/*    }}>*/}
                                            {/*        <FiMapPin style={{marginRight: 15}}/>*/}
                                            {/*        <div style={{fontFamily: 'Poppins', fontWeight: '500'}}>Lokasi</div>*/}
                                            {/*    </Col>*/}
                                            {/*</Row>*/}

                                            {/*<Row>*/}
                                            {/*    <Col style={{*/}
                                            {/*        display: 'flex',*/}
                                            {/*        alignItems: 'center',*/}
                                            {/*        marginTop: 5,*/}
                                            {/*        marginLeft: 15,*/}
                                            {/*        marginRight: 15*/}
                                            {/*    }}>*/}
                                            {/*        <FiTv style={{marginRight: 15, opacity: 0}}/>*/}
                                            {/*        <div style={{fontFamily: 'Poppins', fontWeight: '300'}}>*/}
                                            {/*            {props.location}*/}
                                            {/*        </div>*/}
                                            {/*    </Col>*/}
                                            {/*</Row>*/}

                                            <Row style={{marginTop: 30, marginBottom: 5}}>
                                                <Col xs={2} md={1}
                                                     style={{
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         justifyContent: 'flex-end',
                                                         marginLeft: 2
                                                     }}>
                                                    <FiCalendar/>
                                                </Col>
                                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '500'}}>
                                                    Tanggal
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={2} md={1} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    marginLeft: 2
                                                }}/>
                                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '300'}}>
                                                    {moment(session.startDate).format("DD MMM YYYY")}
                                                </Col>
                                            </Row>

                                            <Row style={{marginTop: 30, marginBottom: 5}}>
                                                <Col xs={2} md={1}
                                                     style={{
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         justifyContent: 'flex-end',
                                                         marginLeft: 2
                                                     }}>
                                                    <FiClock/>
                                                </Col>
                                                <Col style={{padding: 0}}>
                                                    <div style={{fontFamily: 'Poppins', fontWeight: '500'}}>Pukul</div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={2} md={1} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    marginLeft: 2
                                                }}/>
                                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '300'}}>
                                                    {moment(session.startTime).format("HH:mm")} -  {moment(session.startTime).format("HH:mm")}
                                                </Col>
                                            </Row>

                                            <Row style={{marginTop: 30, marginBottom: 5}}>
                                                <Col xs={2} md={1}
                                                     style={{
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         justifyContent: 'flex-end',
                                                         marginLeft: 2
                                                     }}>
                                                    <FiAlignJustify/>
                                                </Col>
                                                <Col style={{padding: 0}}>
                                                    <div style={{fontFamily: 'Poppins', fontWeight: '500'}}>Deskripsi
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={2} md={1} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end',
                                                    marginLeft: 2
                                                }}/>
                                                <Col style={{
                                                    paddingLeft: 0,
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '300',
                                                    whiteSpace: "pre-line",
                                                }}>
                                                    {event.description}
                                                </Col>
                                            </Row>

                                        </Row>
                                    </Container>
                                </div>

                            </Container>
                        </Tab>
                        <Tab
                            eventKey="registration"
                            title={
                                <span style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <FaClipboardList/>&nbsp;Form Registrasi
                                </span>
                            }
                        >
                            <DetailSection event={event} register>
                                <Container style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>

                                    <RegisterForm
                                        fields={props.fields}/>

                                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                                        <Button variant={'dark'} disabled style={{
                                            width: '155px',
                                            height: '50px',
                                            fontFamily: 'Poppins',
                                            fontSize: 'calc(.2vw + 1rem)',
                                            fontWeight: '400',
                                            borderRadius: 0
                                        }}>Daftar</Button>
                                    </Row>
                                </Container>

                            </DetailSection>
                        </Tab>
                    </Tabs>


                </ModalBody>
            </>
        </Dialog>
    );
}

