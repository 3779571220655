export default {
    footer : {
        about_title : "Tentang Narabahasa",
        about_content : `Narabahasa adalah penyedia edukasi, konsultasi, publikasi, dan aplikasi kebahasaan dengan visi “kuasai bahasa, kuasai dunia”. Kami percaya bahwa bahasa adalah kunci untuk menguasai dunia.`,
        contact_title : "Kontak",
        contact_address : "Jalan Senopati 46, DKI Jakarta 12110",
        contact_telephone : "+62 811 9920 220",
        contact_email : "edukasi@narabahasa.id",
		other_title : "Tautan Lain",
		other_main_website : "Situs Utama",
		other_privacy_policy : "Kebijakan Privasi",
    },
	header : {
		login_button : "Masuk"
	},
    main_menu : {
        upcoming_class : "Kelas Publik Mendatang",
        my_class : "Kelas Saya",
        all : "Semua"
    },
    class_detail : {

		price : "Investasi",
		speaker : "Pengajar",
		free : "Gratis",
		code : "Kode",
		date : "Tanggal",
		time : "Waktu",
		category : "Kategori",
		quota: 'Kuota',

		registered : "TERDAFTAR",
		registration_closed : "PENDAFTARAN TUTUP",
		purchase : "BAYAR",
		register : "DAFTAR",

		button_will_be_active_later : "Tombol ini akan aktif 2 jam sebelum acara dimulai",
		watch : "Masuk Kelas",
		watch_recording : "Tonton Rekaman",
		download_attachment : "Unduh Materi",
		certificate : "Sertifikat",
		quiz : "Quiz",
		login_before_purchasing : "Harap masuk sebelum membeli tiket.",
		recording_not_available : "Rekaman belum tersedia",

		code_not_found : "Voucer tidak ditemukan",
		you_will_get_discount : "Anda akan mendapatkan diskon sebesar ",
		voucher_used : "Voucer ini telah digunakan "

    },
	rating : {
    	rating_and_review : "Nilai dan Ulasan",
		review : "Ulasan",
		add_review : "Tambah Ulasan",
		class_review : "Ulasan Kelas",
		please_fill_review_part1 : "Terima kasih telah mengikuti kelas ",
		please_fill_review_part2 : ".Terima kasih telah mengikuti kelas",
		class_wanted : "Kelas Yang Diinginkan",
		class_wanted_placeholder : "Sebutkan tema kelas yang ingin diikuti dikemudian hari.",
		class_question : "Pertanyaan",
		class_question_placeholder : "Pertanyaan akan diteruskan ke narasumber.",
		feedback : "Masukan",
		cancel : "Batal",
		send : "Kirim",
		thanks_for_response : "Terima kasih untuk umpan balik yang telah dikirim. Pertanyaan yang dikirim telah kami kumpulkan dan akan dijawab oleh narasumber."
	},
	login_pop_up : {
    	welcome : "Selamat Datang di Sinara",
		email : "Surel",
		password : "Kata Sandi",
		no_account : "Belum punya akun?",
		create_account : "Buat Akun",
		login : "Masuk",
		forgot_password : "Lupa kata sandi?",
	},
	register_pop_up :{
		register : "Daftar",
		register_newsletter: 'Saya ingin berlangganan nawala Narabahasa'
	},
	menu_pop_up :{
    	welcome_user : "Selamat Datang, ",
    	edit_profile : "Ubah Profil",
		sign_out : "Keluar",
		back : "Kembali"
	},
	profile_page : {
    	profile : "Profil",
    	name : "Nama Lengkap",
		email : "Alamat Surel",
		edit_profile : "Ubah Profil",
		birth_date : "Tanggal Lahir",
		gender : "Jenis Kelamin",
		organization : "Organisasi",
		city : "Kota Tempat Tinggal",
		front_of_name : "Gelar Depan",
		back_of_name : "Gelar Belakang",
		cancel : "Batal",
		save : "Simpan",
		phone_num: "Nomor Ponsel",
		subscribe_newsletter: 'Berlangganan Nawala'
	},
	QnA: {
		question: "Pertanyaan"
	}
}
