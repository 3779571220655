import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, Container, Dropdown, ListGroup, Nav, Navbar, NavDropdown, Row, Spinner} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import {useCookies} from 'react-cookie';
import GlobalData from "./../../util/GlobalData";
import {useHistory, useLocation, useParams} from "react-router-dom";

import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import {TextField, Button as MaterialButton, ButtonGroup} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Palette from "./../../util/Palette";
import Class from "../../models/Class";
import {
    FaPlus,
    FaClock,
    FaPen,
    FaSave,
    FaTimes,
    FaTrash,
    FaTimesCircle,
    FaPlaneDeparture,
    FaHeadset,
    FaEyeSlash,
    FaArchive, FaExternalLinkAlt, FaRegClone
} from "react-icons/fa";
import {MdEvent, MdSchedule, MdEdit} from "react-icons/md";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import * as Scroll from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll'

import MomentUtils from '@date-io/moment';
import {
    KeyboardDatePicker,
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'moment/locale/id'
import CustomDropzone from "./../reusable/CustomDropzone";

import Event, {PUBLISH_STATUS} from "./../../models/Event"
import CustomButton from "./../reusable/CustomButton";
import MiniTooltip from "./../reusable/MiniTooltip";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Card from "@material-ui/core/Card";
import FormFieldModal from "./../reusable/modals/FormFieldModal";
import NewPreviewModal from "./../reusable/modals/NewPreviewModal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FaCheckCircle, FaEye, FaRegClipboard, FaCaretUp, FaCaretDown} from "react-icons/all";
import AboutEditor from "./EventEditor/AboutEditor";
import PaymentInfoEditor from "./EventEditor/PaymentInfoEditor";
import RegistrationFormIntroductionEditor from "./EventEditor/RegistrationFormIntroductionEditor";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import AccordionCollapse from "react-bootstrap/AccordionCollapse";
import moment from "moment";
import Email from "../../models/Email"
import EventSummaryModal from "./modals/EventSummaryModal";
import textFormatter from "../../util/textFormatter";
import jwt from "jsonwebtoken"
import {CopyToClipboard} from "react-copy-to-clipboard";

let timer;
let anchor;

let popperFadeTimer;

export default function DemoEventEditor(props) {

    let history = useHistory()

    const classModel = new Class();
    const {event_url} = useParams()

    let {
        currentEvent,
        currentSession,
        setCurrentEvent,
        setCurrentSession,
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
        setIsLoadingBar,
        registrationInformation,
        setRegistrationInformation,
        processSubmit,
        isDataChanged,
        setIsDataChanged,
    } = props

    let {
        name,
        iconUrl,
        shortDescription,
        eventRoute,
        additionalInformation: eventAdditionalInformation,
        publishStatus
    } = currentEvent

    let {
        longDescription,
        startDate,
        startTime,
        endTime,
        bannerUrl,
        price,
        quota,
        speaker,
        onlinePayment,
        additionalInformation: sessionAdditionalInformation
    } = currentSession

    const [selectedPaymentPreset, setSelectedPaymentPreset] = useState(0)

    const [selectedStreamingMediaIndex, setSelectedStreamingMediaIndex] = useState(0)
    const [selectedLayoutIndex, setSelectedLayoutIndex] = useState(0)

    const [activeFieldModalParams, setActiveFieldModalParams] = useState({})
    const [isFieldModalVisible, setIsFieldModalVisible] = useState(false)
    const [isEditFieldModalVisible, setIsEditFieldModalVisible] = useState(false)
    const [isDraftPopperVisible, setIsDraftPopperVisible] = useState(false)
    const [isEventSummaryModalVisible, setIsEventSummaryModalVisible] = useState(false)

    //Other
    const [referenceHeight, setReferenceHeight] = useState("27vw")
    const [isHomePagePreviewVisible, setIsHomePagePreviewVisible] = useState(false)

    const [isEditingLocation, setIsEditingLocation] = useState(false)

    let [iconLoading, setIconLoading] = useState(false);
    let [bannerLoading, setBannerLoading] = useState(false);

    const [isPopperVisible, setIsPopperVisible] = useState(false)

    const [classObj, setClass] = useState({
        start_date: null,
        start_time: null,
        end_time: null,
        registration_start_date: null,
        registration_end_date: null,
        registration_start_time: null,
        registration_end_time: null,
        question_end_time : null,
        price: 150000,
        quota: 100,
        open_registration: true
    });

    const popPopper = () => {
        setIsPopperVisible(true)
        clearTimeout(popperFadeTimer)
        if (promptSuccess) {
            promptSuccess("URL Berhasil di salin.")
        }
    }

    useEffect(() => {
        if (props.classObj) {
            setClass({
                ...props.classObj,
                start_date: props.classObj.class_date,
                registration_start_date: new Date(props.classObj.registration_start_time),
                registration_end_date: new Date(props.classObj.registration_end_time)
            })
        }
        // checkEventURL(eventRoute !== undefined ? eventRoute : textFormatter.generateURL(name))
    }, [props.classObj])

    useEffect(() => {
        console.log("hoi " + currentSession.selectedStreamingMediaIndex, currentSession)

        let index = currentSession.selectedStreamingMediaIndex ? currentSession.selectedStreamingMediaIndex : 0
        changeStreamingPresetIndex(index)
        setIsDataChanged(false)

    }, [currentSession.selectedStreamingMediaIndex])

    useEffect(() => {

        let layoutType = currentEvent.additionalInformation.layoutType

        if (layoutType === "Layout1") {
            setSelectedLayoutIndex(0)
        } else if (layoutType === "Layout2") {
            setSelectedLayoutIndex(1)
        }

        setIsDataChanged(false)

    }, [currentEvent.additionalInformation.layoutType])


    /**
     * Things to be checked: templates and its variables, time
     */
    const evaluateBeforePublish = async () => {

        if (isDataChanged) {
            promptError("Harap simpan perubahan yang ada sebelum mempublikasikan eventmu.")
            return
        }

        let emailAPI = new Email()
        let problems = []
        try {

            //-- Template Section--//
            let templates = await emailAPI.getTemplates(currentEvent.id)

            const findEmailTemplate = (emailType) => {
                let templateId = parseInt(currentSession[`${emailType}_template_id`])
                return templates.find(emailTemplate => templateId === emailTemplate.id)
            }

            let waitingTemplate = findEmailTemplate("waiting")
            let reminderTemplate = findEmailTemplate("reminder")
            let pendingTemplate = findEmailTemplate("pending")
            let rejectTemplate = findEmailTemplate("rejected")

            if (currentSession.price > 0 && currentSession.online_payment) {
                //Waiting template musst have %LINK_PEMBAYARAN%
                if (!waitingTemplate.html_template.includes("%LINK_PEMBAYARAN%")) {
                    problems.push("Template untuk skenario Menunggu Pembayaran belum memiliki variabel %LINK_PEMBAYARAN%")
                }

            }
            if (currentSession.online_mode) {
                //Reminder template must have %STREAM_URL%
                if (!reminderTemplate.html_template.includes("%STREAM_URL%")) {
                    problems.push("Template untuk skenario Pengingat Acara Mulai belum memiliki variabel %STREAM_URL%")
                }

                //Waiting template cannot have %STREAM_URL%
                if (waitingTemplate.html_template.includes("%STREAM_URL%")) {
                    problems.push("Template untuk skenario Menunggu Pembayaran tidak dapat memiliki variabel %STREAM_URL%")
                }

                //Pending template cannot have %STREAM_URL%
                if (pendingTemplate.html_template.includes("%STREAM_URL%")) {
                    problems.push("Template untuk skenario Daftar Antrian tidak dapat memiliki variabel %STREAM_URL%")
                }

                //Reject template cannot have %STREAM_URL%
                if (rejectTemplate.html_template.includes("%STREAM_URL%")) {
                    problems.push("Template untuk skenario Daftar Antrian tidak dapat memiliki variabel %STREAM_URL%")
                }
            }

            //if current time already passed the start time
            if (new Date().getTime() > new Date(startTime).getTime()) {
                problems.push("Waktu sesi mulai melebihi waktu sekarang.")
            }


        } catch (err) {
            console.error(err)
            setErrorMessage("Ada kesalahan terjadi.")
        }

        return problems

    }

    const editSessionState = (key, value, s) => {

        let temp = {
            ...currentSession
        }

        if (s) {
            temp = {...s}
        }

        temp[key] = value
        setCurrentSession(temp)
        setIsDataChanged(true)

        return temp

    }

    const editClassState = (key, value, e) => {
        let temp = {
            ...currentEvent
        }
        if (e) {
            temp = {...e}
        }


        temp[key] = value
        setCurrentEvent(temp)

        setIsDataChanged(true)
    }

    const editSessionAdditionalInformationState = (key, value, e) => {
        let temp = {
            ...sessionAdditionalInformation
        }
        if (e) {
            temp = {...e}
        }

        temp[key] = value
        setCurrentSession({
            ...currentSession,
            additionalInformation: {
                ...temp
            }
        })

        setIsDataChanged(true)

    }

    const editEventAndSessionState = (key, value, e, s) => {

        let tempS = {
            ...currentSession
        };
        let tempE = {
            ...currentEvent
        };

        if (e) {
            console.log("E", e)
            tempE = {
                ...e
            }
        }
        if (s) {
            console.log("S", s)
            tempS = {
                ...s
            }
        }

        tempS[key] = value;
        tempE[key] = value;

        setCurrentEvent(tempE);
        setCurrentSession(tempS);
        setIsDataChanged(true)

        return {e: tempE, s: tempS}

    }

    const onBannerPicked = async function (image) {

        setIsLoadingBar(true)
        setBannerLoading(true)
        try {
            let result = await new Class().updateClassImage(event_url, image)


            console.log(result)


            setClass(
                {
                    ...classObj,
                    class_image_url: result.location
                }
            )

            setBannerLoading(false)
            setIsLoadingBar(false)

        } catch (e) {

            let tempMessage = "Kesalahan Terjadi"
            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }

            setBannerLoading(false)
            setIsLoadingBar(false)

            setErrorMessage(tempMessage)

            console.log(e)
        }
    }

    const onIconPicked = async function (image) {
        console.log(image)
        setIsLoadingBar(true)
        setIconLoading(true)
        try {
            let result = await new Event().uploadIcon(image, currentEvent.id)

            console.log(result)

            setIsLoadingBar(false)
            setIconLoading(false)
            editClassState("iconUrl", result.location)
        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"
            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            setErrorMessage(tempMessage)
            setIsLoadingBar(false)
            setIconLoading(false)

            console.log(e)
        }
    }

    const [isLoadingForURL, setIsLoadingForURL] = useState(false)
    const [urlErrors, setIsURLValid] = useState("")

    const checkEventURL = (paramRoute) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
            setIsLoadingForURL(true)
            setIsURLValid("")

            let route = paramRoute ? paramRoute : eventRoute ? eventRoute : textFormatter.generateURL(name)

            console.log("checking route : " + route, "||", paramRoute, "||", eventRoute, "||", name)

            let urlValid = textFormatter.validateURL(route)
            if (!urlValid) {
                setIsLoadingForURL(false)
                setIsURLValid("URL hanya dapat terdiri dari Huruf, Angka, dan simbol - dan _")
                return
            }

            console.log("woi ", currentEvent)

            let result = await new Event().checkURLAvailability(route, currentEvent.id)

            console.log(result)
            setIsLoadingForURL(false)
            if (result.url_used) {
                setIsURLValid("URL Telah Digunakan")
            }

        }, 1000);
    }

    const renderDateTimeForm = (isRegister) => {
        return <MuiPickersUtilsProvider utils={MomentUtils}>
            <Row>
                <Col md={12}>
                    <p style={{
                        color: Palette.PRIMARY,
                        fontFamily: "OpenSans-Bold",
                        marginTop: "1.5em",
                        fontSize: "1.2em"
                    }}>Tanggal & Waktu {isRegister ? 'Pendaftaran' : 'Kelas'}</p>
                </Col>
            </Row>
            <Row style={{
                paddingInlineStart: "2%",
                paddingInlineEnd: "2%",
            }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                }}>
                    <span style={{
                        fontSize: ".9rem",
                        fontFamily: 'OpenSans-SemiBold'
                    }}>Tanggal {isRegister ? 'Buka' : 'Kelas'} *</span>
                    <DatePicker
                        allowKeyboardControl={false}
                        // disablePast={true}
                        autoOk={true}
                        // disabled={publishStatus === PUBLISH_STATUS.DONE}
                        // minDateMessage={"Tanggal mulai harus lebih kecil dari tanggal berakhir"}
                        style={{
                            marginLeft: "0.5em",
                            width: "15em",
                            cursor: "pointer",
                            fontSize: '1.2em'
                        }}
                        format="D MMM YYYY"
                        InputProps={{
                            style: {
                                fontSize: "1em",
                                fontFamily: "OpenSans-Regular",
                                cursor: "pointer"
                            },
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <MdEvent style={{color: Palette.PRIMARY}}/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={isRegister ? classObj.registration_start_time : classObj.class_date}
                        onChange={date => {
                            if (isRegister) {
                                console.log(date)

                                let startTime = new moment(classObj.registration_start_time)

                                date.set({
                                    hour: startTime.hour(),
                                    minute: startTime.minute()
                                })

                                // let result = editEventAndSessionState("registration_start_date", new Date(date))

                                setClass(
                                    {
                                        ...classObj,
                                        registration_start_time: date,
                                        registration_start_date: date
                                    }
                                )

                            } else {
                                console.log(date)

                                let startTime = new moment(classObj.start_time)
                                let endTime = new moment(classObj.end_time)

                                date.set({
                                    hour: startTime.hour(),
                                    minute: startTime.minute()
                                })

                                let secondDate = new moment(date)
                                secondDate.set({
                                    hour: endTime.hour(),
                                    minute: endTime.minute()
                                })

                                let newQuestionClose = secondDate.add(24, 'hours')
                                newQuestionClose.set({
                                    hour: endTime.hour(),
                                    minute: endTime.minute()
                                })

                                setClass(
                                    {
                                        ...classObj,
                                        class_date: date,
                                        start_time: date,
                                        end_time: secondDate,
                                        question_end_time: newQuestionClose
                                    }
                                )
                            }
                        }}
                        placeholder={`Tanggal ${isRegister ? 'Buka' : 'Kelas'}`}/>

                </div>

                {isRegister ?
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 10
                    }}>
                        <span style={{fontSize: ".9rem", fontFamily: "OpenSans-SemiBold",}}>Tanggal Tutup *</span>
                        <DatePicker
                            allowKeyboardControl={false}
                            // disablePast={true}
                            autoOk={true}
                            // disabled={publishStatus === PUBLISH_STATUS.DONE}
                            // minDateMessage={"Tanggal mulai harus lebih kecil dari tanggal berakhir"}
                            style={{
                                marginLeft: "0.5em",
                                width: "15em",
                                cursor: "pointer",
                                fontSize: '1.2em'
                            }}
                            format="D MMM YYYY"
                            InputProps={{
                                style: {
                                    fontSize: "1em",
                                    fontFamily: "OpenSans-Regular",
                                    cursor: "pointer"
                                },
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <MdEvent style={{color: Palette.PRIMARY}}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={classObj.registration_end_time}
                            onChange={date => {
                                let endTime = new moment(classObj.registration_end_time)

                                date.set({
                                    hour: endTime.hour(),
                                    minute: endTime.minute()
                                })

                                // let result = editEventAndSessionState("registration_start_date", new Date(date))

                                setClass(
                                    {
                                        ...classObj,
                                        registration_end_date: date,
                                        registration_end_time: date
                                    }
                                )
                            }}
                            placeholder={"Tanggal Mulai"}/>

                    </div> : null

                }
            </Row>
            <Row style={{
                marginTop: "1em",
                paddingInlineStart: "2%",
                paddingInlineEnd: "2%",
            }}
            >
                <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    padding: 10
                }}>
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >
                        <span style={{
                            fontSize: ".9rem",
                            fontFamily: 'OpenSans-SemiBold'
                        }}>Waktu {isRegister ? 'Buka' : 'Mulai'} *</span>
                        <TimePicker
                            okLabel={"Simpan"}
                            cancelLabel={"Batal"}
                            ampm={false}
                            disabled={publishStatus === PUBLISH_STATUS.DONE}
                            allowKeyboardControl={false}
                            autoOk={true}
                            style={{
                                marginLeft: "0.5em",
                                width: "15em",
                                cursor: "pointer",
                                fontSize: '1.2em'
                            }}
                            format="HH:mm"
                            InputProps={{
                                style: {
                                    fontSize: "1em",
                                    fontFamily: "OpenSans-Regular",
                                    cursor: "pointer"
                                },
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <MdSchedule style={{color: Palette.PRIMARY}}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} value={isRegister ? classObj.registration_start_time : classObj.start_time}
                            onChange={(value) => {
                                if (isRegister) {

                                    let startDate = new moment(classObj.registration_start_date ? classObj.registration_start_date : classObj.class_date)
                                    let newValue = new moment(value)

                                    console.log("gabisa1", startDate)
                                    console.log("gabisa2", newValue)

                                    startDate.set({
                                        hour: newValue.hour(),
                                        minute: newValue.minute()
                                    })

                                    console.log("gabisa", startDate)

                                    setClass({
                                        ...classObj,
                                        registration_start_date: startDate,
                                        registration_start_time: startDate
                                    })

                                } else {
                                    let startDate = new moment(classObj.class_date)
                                    let newValue = new moment(value)

                                    startDate.set({
                                        hour: newValue.hour(),
                                        minute: newValue.minute()
                                    })

                                    console.log("bisa", startDate)

                                    setClass({
                                        ...classObj,
                                        class_date: startDate,
                                        start_time: startDate
                                    })
                                }
                                // console.log("CHANGE TIME VALUE:", value)
                                // editEventAndSessionState("startTime", value.toDate())
                            }}
                            placeholder={`Waktu ${isRegister ? 'Buka' : 'Mulai'} *`}/>
                    </span>
                </div>

                <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    padding: 10
                }}>
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >
                        <span style={{
                            fontSize: ".9rem",
                            fontFamily: 'OpenSans-SemiBold'
                        }}>     Waktu {isRegister ? 'Tutup' : 'Selesai'} * &nbsp;
                            {isRegister ? null : <MiniTooltip
                                content={"Samakan dengan waktu mulai jika waktu selesai tentatif"}/>}</span>
                             <TimePicker
                                 okLabel={"Simpan"}
                                 cancelLabel={"Batal"}
                                 ampm={false}
                                 disabled={publishStatus === PUBLISH_STATUS.DONE}
                                 allowKeyboardControl={false}
                                 autoOk={true}
                                 style={{
                                     marginLeft: "0.5em",
                                     width: "15em",
                                     cursor: "pointer",
                                     fontSize: "1.2em",
                                     fontFamily: "OpenSans-Bold",
                                     // cursor: "pointer"
                                 }}
                                 format="HH:mm"
                                 InputProps={{
                                     style: {
                                         fontSize: "1em",
                                         fontFamily: "OpenSans-Regular",
                                         cursor: "pointer"
                                     },
                                     endAdornment: (
                                         <InputAdornment position="start">
                                             <IconButton>
                                                 <MdSchedule style={{color: Palette.PRIMARY}}/>
                                             </IconButton>
                                         </InputAdornment>
                                     ),
                                 }} value={isRegister ? classObj.registration_end_time : classObj.end_time}
                                 onChange={(value) => {

                                     if (isRegister) {

                                         let endDate = new moment(classObj.registration_end_date ? classObj.registration_end_date : classObj.class_date)

                                         let newValue = new moment(value)

                                         endDate.set({
                                             hour: newValue.hour(),
                                             minute: newValue.minute()
                                         })

                                         setClass({
                                             ...classObj,
                                             registration_end_time: endDate,
                                             registration_end_date: endDate,
                                         })
                                     } else {

                                         let endDate = new moment(classObj.class_date)


                                         let newValue = new moment(value)

                                         endDate.set({
                                             hour: newValue.hour(),
                                             minute: newValue.minute()
                                         })

                                         let newQuestionClose = new moment(endDate)

                                         newQuestionClose.add(24, 'hours')

                                         console.log(endDate.format("DD MMM YYYY"))
                                         console.log(newQuestionClose.format("DD MMM YYYY"))

                                         newQuestionClose.set({
                                             hour: endDate.hour(),
                                             minute: endDate.minute()
                                         })


                                         setClass({
                                             ...classObj,
                                             end_time: endDate,
                                             question_end_time:  newQuestionClose
                                         })



                                     }
                                     // editEventAndSessionState("endTime", value.toDate())
                                 }} placeholder={`Waktu ${isRegister ? 'Tutup' : 'Selesai'}`}/>
                    </span>

                </div>

                {/*<div style={{*/}
                {/*    display: "flex",*/}
                {/*    alignItems: "flex-start",*/}
                {/*    flexDirection: "row",*/}
                {/*    padding: 10*/}
                {/*}}>*/}

                {/*    <span style={{*/}
                {/*        display: "flex",*/}
                {/*        flexDirection: "column",*/}
                {/*        marginTop: "1em"*/}
                {/*    }}>*/}
                {/*    <span style={{fontSize: ".9rem", fontFamily: 'OpenSans-SemiBold', display: "flex", flexDirection: "row", alignItems: "center"}}>*/}
                {/*    Waktu {isRegister ? 'Tutup' : 'Selesai'} * &nbsp;*/}
                {/*        {isRegister ? null : <MiniTooltip*/}
                {/*            content={"Samakan dengan waktu mulai jika waktu selesai tentatif"}/>}*/}

                {/*    </span>*/}
                {/*    <TimePicker*/}
                {/*        okLabel={"Simpan"}*/}
                {/*        cancelLabel={"Batal"}*/}
                {/*        ampm={false}*/}
                {/*        disabled={publishStatus === PUBLISH_STATUS.DONE}*/}
                {/*        allowKeyboardControl={false}*/}
                {/*        autoOk={true}*/}
                {/*        style={{*/}
                {/*            marginLeft: "0.5em",*/}
                {/*            width: "15em",*/}
                {/*            cursor: "pointer",*/}
                {/*            fontSize: "1em",*/}
                {/*            fontFamily: "OpenSans-Bold",*/}
                {/*            // cursor: "pointer"*/}
                {/*        }}*/}
                {/*        format="HH:mm"*/}
                {/*        InputProps={{*/}
                {/*            style: {*/}
                {/*                fontSize: "1em",*/}
                {/*                fontFamily: "OpenSans-Regular",*/}
                {/*                cursor: "pointer"*/}
                {/*            },*/}
                {/*            endAdornment: (*/}
                {/*                <InputAdornment position="start">*/}
                {/*                    <IconButton>*/}
                {/*                        <MdSchedule style={{color: Palette.PRIMARY}}/>*/}
                {/*                    </IconButton>*/}
                {/*                </InputAdornment>*/}
                {/*            ),*/}
                {/*        }} value={isRegister ? classObj.registration_end_time : classObj.end_time}*/}
                {/*        onChange={(value) => {*/}
                {/*            if (isRegister) {*/}
                {/*                setClass({*/}
                {/*                    ...classObj,*/}
                {/*                    registration_end_time: value*/}
                {/*                })*/}
                {/*            } else {*/}
                {/*                setClass({*/}
                {/*                    ...classObj,*/}
                {/*                    end_time: value*/}
                {/*                })*/}
                {/*            }*/}
                {/*            // editEventAndSessionState("endTime", value.toDate())*/}
                {/*        }} placeholder={`Waktu ${isRegister ? 'Tutup' : 'Selesai'}`}/>*/}
                {/*    </span>*/}
                {/*</div>*/}
            </Row>
        </MuiPickersUtilsProvider>
    }

    const renderAbout = () => {

        return <AboutEditor
            classObj={classObj}
            setClass={setClass}
            editSessionState={editSessionState}
            editClassState={editClassState}
            name={classObj.name}
            longDescription={longDescription}
            speaker={speaker}
            shortDescription={shortDescription}
            url={currentEvent.event_url}
            update={props.classObj}
            // editSessionState, speaker, shortDescription, editClassState, longDescription
        />

    }

    let streamingPresets = [
        {
            value: 0,
            dropdownComponent: <MenuItem style={{fontFamily: "Poppins"}} value={0} disabled>Pilih Salah Satu</MenuItem>,
            formComponent: <></>
        },
        {
            value: "Google Meet",
            formComponent: <>
                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: "1em"
                }}
                >
                    <Col md={12}>URL Stream</Col>
                </Row>
                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                }}
                >
                    <Col md={4} sm={12} style={{
                        fontSize: "1.2em",
                        display: "flex",
                        alignItems: "flex-end"
                    }}>
                        <LagFreeTextEditor
                            changeValue={value => {
                                editSessionAdditionalInformationState("streamingURL", value)
                            }}
                            style={{
                                marginLeft: "0.5em",
                                style: {
                                    fontSize: "1em",
                                }
                            }}
                            InputProps={{
                                style: {
                                    fontSize: "1em",
                                    cursor: "pointer"
                                }
                            }}
                            value={sessionAdditionalInformation.streamingURL} fullWidth
                            placeholder={"meet.google.com/asdfjkl"}/>
                    </Col>
                </Row>
            </>
        },
        {
            value: "Zoom",
            formComponent: <>
                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: "1em"
                }}
                >
                    <Col md={4} sm={12} style={{
                        fontSize: "1.2em",
                        display: "flex",
                        alignItems: "flex-end"
                    }}>
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1
                    }}>
                    <span style={{fontSize: "1rem"}}>URL Stream</span>
                    <LagFreeTextEditor
                        changeValue={value => {
                            editSessionAdditionalInformationState("streamingURL", value)
                        }}
                        style={{
                            marginLeft: "0.5em",
                            style: {
                                fontSize: "1em",
                            }
                        }}
                        InputProps={{
                            style: {
                                fontSize: "1em",
                                cursor: "pointer"
                            }
                        }}
                        value={sessionAdditionalInformation.streamingURL} fullWidth
                        placeholder={"https://us04web.zoom.us/j/721142"}/>
                    </span>
                    </Col>
                    {/*}
                    <Col md={4} sm={12} style={{
                    fontSize: "1.2em",
                    display: "flex",
                    alignItems: "flex-end",
                    flex: 1
                    }}
                    >
                    <span style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                    }}>
                    <span style={{fontSize: "1rem"}}>Password Ruangan</span>
                    <LagFreeTextEditor
                    changeValue={value => {
                    editSessionAdditionalInformationState("streamingRoomPassword", value)
                    }}
                    style={{
                    marginLeft: "0.5em",
                    style: {
                    fontSize: "1em",
                    }
                    }}
                    InputProps={{
                    style: {
                    fontSize: "1em",
                    cursor: "pointer"
                    }
                    }}
                    value={sessionAdditionalInformation.streamingRoomPassword} fullWidth
                    placeholder={"123456"}/>
                    </span>
                    </Col>
                    {*/}
                </Row>
            </>
        },
        {
            value: "Lainnya",
            formComponent: <>
                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    marginTop: "1em"
                }}
                >
                    <Col md={4} sm={12} style={{
                        fontSize: "1.2em",
                        display: "flex",
                        alignItems: "flex-end"
                    }}
                    >
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1
                    }}>
                    <span style={{fontSize: "1rem"}}>URL Stream</span>
                    <LagFreeTextEditor
                        changeValue={value => {
                            editSessionAdditionalInformationState("streamingURL", value)
                        }}
                        style={{
                            marginLeft: "0.5em",
                            style: {
                                fontSize: "1em",
                            }
                        }}
                        InputProps={{
                            style: {
                                fontSize: "1em",
                                cursor: "pointer"
                            }
                        }}
                        value={sessionAdditionalInformation.streamingURL} fullWidth
                        placeholder={"https://youtube.com/watch?v=adkfadj"}/>
                    </span>
                    </Col>
                    {/*}
                    <Col md={4} sm={12} style={{
                    fontSize: "1.2em",
                    display: "flex",
                    alignItems: "flex-end",
                    flex: 1
                    }}
                    >
                    <span style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1
                    }}>
                    <span style={{fontSize: "1rem"}}>Password Ruangan</span>
                    <TextField
                    onChange={evt => {
                    editSessionAdditionalInformationState("streamingRoomPassword", evt.target.value)
                    }}
                    style={{
                    marginLeft: "0.5em",
                    style: {
                    fontSize: "1em",
                    }
                    }}
                    InputProps={{
                    style: {
                    fontSize: "1em",
                    cursor: "pointer"
                    }
                    }}
                    value={sessionAdditionalInformation.streamingRoomPassword} fullWidth
                    placeholder={"123456"}/>
                    </span>
                    </Col>
                    {*/}
                </Row>
            </>
        },
    ]

    let changeStreamingPresetIndex = (index) => {
        setSelectedStreamingMediaIndex(index)
        console.log("idx :" + index)
        // console.log("test", streamingPresets[index])
        editSessionAdditionalInformationState("streamType", streamingPresets[index].value)
    }

    const renderUrlForm = () => {
        return <>
            <Row>
                <Col md={12}>
                    <p style={{
                        color: Palette.SECONDARY,
                        fontFamily: "Montserrat",
                        fontWeight: "800",
                        marginTop: "1.5em",
                        fontSize: "1.2em"
                    }}>Konfigurasi Streaming</p>
                </Col>
            </Row>
            <Row style={{
                paddingInlineStart: "2%",
                paddingInlineEnd: "2%",
                marginTop: "1em"
            }}
            >
                <Col md={12}>Media Streaming</Col>
            </Row>
            <Row style={{
                paddingInlineStart: "2%",
                paddingInlineEnd: "2%",
                marginTop: "0.5em"
            }}
            >
                <Col md={4} style={{
                    fontSize: "1.2em",
                    display: "flex",
                    alignItems: "flex-end"
                }}>
                    <Select
                        fullWidth
                        value={selectedStreamingMediaIndex}
                        onChange={evt => {
                            changeStreamingPresetIndex(evt.target.value)
                        }}
                        style={{
                            marginLeft: "0.5em",
                            style: {
                                fontSize: "1em",
                            }
                        }}
                        placeholder={"Pilih Salah Satu"}
                        defaultValue={0}
                    >
                        {/*<MenuItem style={{fontFamily: "Poppins"}} value={0} disabled>Pilih Salah Satu</MenuItem>*/}
                        {
                            streamingPresets.map((obj, key) => {
                                return obj.dropdownComponent ? obj.dropdownComponent
                                    :
                                    <MenuItem value={key}>
                                        <span style={{fontFamily: "Poppins"}}>{obj.value}</span>
                                    </MenuItem>
                            })
                        }
                    </Select>
                </Col>
            </Row>
            {
                streamingPresets[selectedStreamingMediaIndex].formComponent
            }
        </>
    }

    let layoutPresets = [
        {
            name: "Layout 1",
            id: "Layout1",
            src: require("../../asset/layout1.png")
        },
        {
            name: "Layout 2",
            id: "Layout2",
            src: require("../../asset/layout2.png")
        },
    ]

    const validateAndSubmit = async () => {
        setErrorMessage('')
        setSuccessMessage('')

        if (!classObj.name  || !classObj.class_date || !classObj.start_time || !classObj.end_time || !classObj.registration_start_date || !classObj.registration_end_date || !classObj.registration_start_time || !classObj.registration_end_time) {

            console.log(classObj.name, classObj.class_date, classObj.start_time, classObj.end_time, classObj.registration_start_date, classObj.registration_end_date, classObj.registration_start_time, classObj.registration_end_time)

            return promptError('Harap mengisi formulir dengan lengkap')
        } else if (new Date(classObj.start_time).getTime() > new Date(classObj.end_time).getTime()) {

            console.log(classObj.start_time)
            console.log(classObj.end_time)

            return promptError('Waktu mulai harus lebih awal dari waktu selesai')
        } else if (new Date(classObj.registration_start_date).getTime() > new Date(classObj.class_date).getTime()) {
            // console.log(new Date(classObj.class_date).getTime())
            // console.log(new Date(classObj.registration_start_date).getTime())
            return promptError("Tanggal kelas mulai harus lebih akhir dari tanggal buka pendaftaran")
        } else {
            const registrationStartDate = new Date(classObj.registration_start_date);
            // registrationStartDate.setHours(new Date(classObj.registration_start_time).getHours());
            // registrationStartDate.setMinutes(new Date(classObj.registration_start_time).getMinutes());

            const registrationEndDate = new Date(classObj.registration_end_date);
            // registrationEndDate.setHours(new Date(classObj.registration_end_time).getHours());
            // registrationEndDate.setMinutes(new Date(classObj.registration_end_time).getMinutes());

            if (props.classObj) {
                try {
                    const result = await classModel.update(classObj.code, {
                        ...classObj,
                        source: classObj.source,
                        class_date: classObj.class_date,
                        start_time: new Date(classObj.class_date).setTime(new Date(classObj.start_time).getTime()),
                        end_time: new Date(classObj.class_date).setTime(new Date(classObj.end_time).getTime()),
                        registration_start_time: registrationStartDate,
                        registration_end_time: registrationEndDate,
                        price: classObj.price,
                        quota: classObj.quota
                    })

                    if (result.success) {
                        alert('Data berhasil tersimpan')
                        window.location.reload();
                    }
                } catch (e) {
                    if (e.msg && typeof e.msg === 'string') {
                        return promptError(e.msg);
                    } else {
                        return promptError('Kesalahan terjadi');
                    }
                }


            } else {
                try {
                    const result = await classModel.createNew({
                        ...classObj,
                        class_date: classObj.class_date,
                        start_time: new Date(classObj.start_date).setTime(new Date(classObj.start_time).getTime()),
                        end_time: new Date(classObj.start_date).setTime(new Date(classObj.end_time).getTime()),
                        registration_start_time: registrationStartDate,
                        registration_end_time: registrationEndDate,
                    })

                    if (result.name === classObj.name) {
                        history.push("/editor")
                        return promptSuccess('Kelas berhasil dibuat');
                    }

                } catch (e) {
                    console.log('e', e)
                    if (e.msg && typeof e.msg === 'string') {
                        return promptError(e.msg);
                    } else {
                        return promptError('Kesalahan terjadi');
                    }
                }


            }


        }


        console.log(classObj)
        processSubmit()
    }

    const publish = async () => {
        let event = new Event();

        let result = await event.updatePublishStatus(currentEvent.id)
        if (result.new_status) {
            setIsDraftPopperVisible(false)
            setIsEventSummaryModalVisible(false)
            setCurrentEvent({
                ...currentEvent,
                publishStatus: result.new_status
            })
        }
    }

    const prePublish = async () => {
        try {
            //TODO Ganti loading yg lbh bagus
            setIsLoadingBar(true)
            let problems = await evaluateBeforePublish()
            console.log("PROBLEMS:", problems)
            if (problems.length === 0) {
                setIsEventSummaryModalVisible(true)
            } else {
                problems.unshift("Anda tidak dapat menerbitkan sesi ini karena alasan berikut:")
                let errors = problems.map(e => <div>{e}</div>)
                setErrorMessage(errors)
            }


        } catch (e) {
            console.log(e)
        } finally {
            setIsLoadingBar(false)
        }
    }

    const renderPublishModalComponent = () => {
        if (currentEvent.publishStatus === PUBLISH_STATUS.DRAFT) {
            return <>
                <CardContent>
                    <p>
                        <b>Apakah kamu mau mempublikasikan event ini?</b>
                    </p>
                    <p>
                        Agar peserta dapat mendaftar ke eventmu, kamu harus mempublikasikan eventmu terlebih dahulu.
                    </p>
                    <p>
                        Setelah event telah dipublikasi, kamu tidak dapat:
                        <ul style={{paddingInlineStart: "25px"}}>
                            <li>Mengubah URL halaman landing.</li>
                            <li>Memajukan tanggal Event.</li>
                            <li>Mengubah metode pembayaran.</li>
                            <li>Mengurangi jumlah kuota acara.</li>
                            <li>Mengubah x tiket.</li>
                        </ul>
                    </p>
                </CardContent>
                <CardActions style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end"
                }}>
                    <CustomButton
                        onClick={() => {
                            setIsDraftPopperVisible(false)
                        }}
                    >
                        Batal
                    </CustomButton>
                    <CustomButton
                        onClick={async () => {
                            prePublish()
                        }}
                    >
                        Publish
                    </CustomButton>
                </CardActions>

            </>
        }

        if (currentEvent.publishStatus === PUBLISH_STATUS.OPEN) {
            return <>
                <CardContent>
                    <p>
                        <b>Pendaftaran Eventmu sedang dibuka!</b>
                    </p>
                    <p>
                        Pendaftaran eventmu sedang dibuka. Kamu dapat mengubah semua data dalam event ini, kecuali :
                    </p>
                    <p>
                        <ul style={{paddingInlineStart: "25px"}}>
                            <li>Mengubah URL halaman landing.</li>
                            <li>Memajukan tanggal Event.</li>
                            <li>Mengubah metode pembayaran.</li>
                            <li>Mengurangi jumlah kuota acara.</li>
                            <li>Mengubah harga tiket.</li>
                        </ul>
                    </p>
                    <p>
                        Jika terjadi kendala, Kamu dapat menyembunyikan event ini kapan saja. Jika tersembunyi, peserta
                        tidak dapat melihat dan mendaftar melalui halaman event mu.
                    </p>
                </CardContent>
                <CardActions style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end"
                }}>
                    <CustomButton
                        onClick={() => {
                            setIsDraftPopperVisible(false)
                        }}
                    >
                        Batal
                    </CustomButton>
                    <CustomButton
                        onClick={async () => {
                            let event = new Event();
                            try {
                                let result = await event.updatePublishStatus(currentEvent.id)
                                if (result.new_status) {
                                    setIsDraftPopperVisible(false)
                                    setCurrentEvent({
                                        ...currentEvent,
                                        publishStatus: result.new_status
                                    })
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        }}
                    >
                        Sembunyikan
                    </CustomButton>
                </CardActions>

            </>
        }
        if (currentEvent.publishStatus === PUBLISH_STATUS.HIDDEN) {
            return <>
                <CardContent>
                    <p>
                        <b>Pendaftaran Eventmu sedang disembunyikan</b>
                    </p>
                    <p>
                        Pendaftaran eventmu sedang disembunyikan. Dalam mode ini, peserta tidak dapat melihat dan
                        mendaftar melalui halaman event mu.
                    </p>
                    <p>
                        Kamu dapat mengaktifkan kembali halaman pendaftaran mu dengan menekan tombol dibawah.
                    </p>
                </CardContent>
                <CardActions style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end"
                }}>
                    <CustomButton
                        onClick={() => {
                            setIsDraftPopperVisible(false)
                        }}
                    >
                        Batal
                    </CustomButton>
                    <CustomButton
                        onClick={async () => {
                            let event = new Event();
                            try {
                                let result = await event.updatePublishStatus(currentEvent.id)
                                if (result.new_status) {
                                    setIsDraftPopperVisible(false)
                                    setCurrentEvent({
                                        ...currentEvent,
                                        publishStatus: result.new_status
                                    })
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        }}
                    >
                        Publikasi
                    </CustomButton>
                </CardActions>

            </>
        }

    }

    const renderButtons = () => {

        return <div style={{
            position: "fixed",
            width: "100%",
            bottom: "0em",
            right: "0em",
            paddingTop: "1em",
            paddingBottom: "1em",
            paddingRight: "2em",
            zIndex: "300",
            boxShadow: "1px 1px 1px 1px #888888",
            background: "white",
            display: iconLoading || bannerLoading || isHomePagePreviewVisible || isFieldModalVisible || isEditFieldModalVisible ?
                "none" : "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
        }}>
            {/*<div style={{*/}
            {/*    color : "black",*/}
            {/*    textAlign : "right"*/}
            {/*}}>*/}
            {/*    Status Event : <b style={{color : "orange"}}>Draft</b>*/}
            {/*</div>*/}

            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
            }}>
                {/*
                    PUBLISH_STATUS.INITIAL === publishStatus ?
                    <MiniTooltip
                    position={"top"}
                    content={"Harap simpan data eventmu sebelum kamu bisa melihat preview."}>
                    <div>
                    <CustomButton
                    disabled={true}
                    variant={"outlined"} color="primary">
                    Preview&nbsp;&nbsp;<FaEye/>

                    </CustomButton>
                    </div>
                    </MiniTooltip>
                    :
                    <MiniTooltip
                    position={"top"}
                    content={"Harap simpan data eventmu untuk melihat preview terbaru."}>
                    <CustomButton
                    onClick={() => {
                    setIsHomePagePreviewVisible(true)
                    }}
                    disabled={PUBLISH_STATUS.INITIAL === publishStatus}
                    variant={"outlined"} color="primary">
                    Preview&nbsp;&nbsp;<FaEye/>

                    </CustomButton>
                    </MiniTooltip>

                    */}
                <CustomButton
                    onClick={() => history.push(`/editor/preview/${classObj.code}`)}
                    style={{color: 'white', width: 200, marginRight: 15, fontFamily: 'OpenSans-SemiBold'}}
                    variant={"contained"} color="secondary">
                    <>Tampilan Pengguna</>
                </CustomButton>

                <CustomButton
                    onClick={() => validateAndSubmit()}
                    style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                    variant={"contained"} color="primary">
                    <>Simpan&nbsp;&nbsp;<FaSave/></>
                </CustomButton>

                {/*<CustomButton*/}
                {/*    style={{*/}
                {/*        marginLeft: "1em"*/}
                {/*    }}*/}
                {/*    onClick={() => validateAndSubmit()}*/}
                {/*    variant={"outlined"} color="primary">*/}
                {/*    Simpan&nbsp;&nbsp;*/}
                {/*    /!*{isDataChanged ? "*" : ""}*!/*/}
                {/*    <FaSave/>*/}
                {/*</CustomButton>*/}

                {/*{*/}
                {/*    publishStatus ?*/}
                {/*        <Button*/}
                {/*            style={{*/}
                {/*                marginLeft: "1em"*/}
                {/*            }}*/}
                {/*            ref={ref => anchor = ref}*/}
                {/*            onClick={() => {*/}
                {/*                setIsDraftPopperVisible(!isDraftPopperVisible)*/}
                {/*            }}*/}
                {/*            onMouseDown={e => e.preventDefault()}*/}
                {/*            variant={*/}
                {/*                publishStatus === PUBLISH_STATUS.DRAFT ? "secondary" :*/}
                {/*                    publishStatus === PUBLISH_STATUS.DONE ? "primary" :*/}
                {/*                        publishStatus === PUBLISH_STATUS.LIVE ? "info" :*/}
                {/*                            publishStatus === PUBLISH_STATUS.HIDDEN ? "danger" :*/}
                {/*                                publishStatus === PUBLISH_STATUS.OPEN ? "success" : null*/}
                {/*            }>*/}
                {/*            <div style={{*/}
                {/*                display: "flex",*/}
                {/*                flexDirection: "row",*/}
                {/*                alignItems: "center",*/}
                {/*                color: "white"*/}
                {/*            }}>*/}
                {/*                <a style={{*/}
                {/*                    fontWeight: "lighter",*/}
                {/*                    color: publishStatus === "DRAFT" ? "black" : "white"*/}
                {/*                }}>{*/}

                {/*                    publishStatus === PUBLISH_STATUS.DRAFT ? "DRAFT" :*/}
                {/*                        publishStatus === PUBLISH_STATUS.DONE ? "SELESAI" :*/}
                {/*                            publishStatus === PUBLISH_STATUS.LIVE ? "SEDANG BERLANGSUNG" :*/}
                {/*                                publishStatus === PUBLISH_STATUS.HIDDEN ? "DISEMBUNYIKAN" :*/}
                {/*                                    publishStatus === PUBLISH_STATUS.OPEN ? "PENDAFTARAN DIBUKA" : null*/}

                {/*                }</a>*/}
                {/*                &nbsp;&nbsp;*/}
                {/*                {*/}
                {/*                    publishStatus === PUBLISH_STATUS.DRAFT ?*/}
                {/*                        <FaRegClipboard style={{color: "black"}}/> :*/}
                {/*                        publishStatus === PUBLISH_STATUS.DONE ? <FaArchive/> :*/}
                {/*                            publishStatus === PUBLISH_STATUS.LIVE ? <FaHeadset/> :*/}
                {/*                                publishStatus === PUBLISH_STATUS.HIDDEN ? <FaEyeSlash/> :*/}
                {/*                                    publishStatus === PUBLISH_STATUS.OPEN ? <FaPlaneDeparture/> : null*/}
                {/*                }*/}
                {/*                {*/}
                {/*                    isDraftPopperVisible ?*/}
                {/*                        <FaCaretDown style={{color: publishStatus === "DRAFT" ? "black" : "white"}}/>*/}
                {/*                        :*/}
                {/*                        <FaCaretUp style={{color: publishStatus === "DRAFT" ? "black" : "white"}}/>*/}
                {/*                }*/}

                {/*            </div>*/}
                {/*        </Button>*/}
                {/*        :*/}
                {/*        null*/}
                {/*}*/}


                <Popper
                    placement={"top-end"}
                    open={isDraftPopperVisible} anchorEl={anchor} transition>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={{
                            enter: 1000,
                            exit: 0
                        }}>
                            <Card style={{
                                zIndex: "350",
                                marginBottom: "2em",
                                position: "relative",
                                maxWidth: "25em"
                            }}>
                                {renderPublishModalComponent()}
                            </Card>
                        </Fade>
                    )}
                </Popper>
            </div>
        </div>
    }

    const renderRegistrationInfoForm = () => {
        return <>
            <Row style={{
                paddingInlineStart: "4%",
                paddingInlineEnd: "4%",
                alignItems: "center",
                marginTop : 30
            }}
            >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={classObj.open_registration}
                                // checked={classInfo.show_answers_to_user}
                                onChange={() => {
                                    setClass({
                                        ...classObj,
                                        open_registration : !classObj.open_registration
                                    })
                                }}
                                inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                        }
                        label={"Buka registrasi"}

                    />
            </Row>
            <Row>
                <Col md={12}>
                    <p style={{
                        color: Palette.PRIMARY,
                        fontFamily: "OpenSans-Bold",
                        marginTop: "1.5em",
                        fontSize: "1.2em"
                    }}>
                        Tiket dan Kuota
                    </p>
                </Col>
            </Row>
            <Row style={{
                paddingInlineStart: "2%",
                paddingInlineEnd: "2%",
                alignItems: "center",
            }}
            >
                <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    padding: 10
                }}>
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >
                    <LagFreeTextEditor
                        title={'Harga Tiket'}
                        changeValue={value => {
                            setClass({
                                ...classObj,
                                price: value
                            })
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <span
                                        style={{
                                            fontFamily: "OpenSans-Regular",
                                            color: "grey"
                                        }}>Rp</span>
                                </InputAdornment>
                            ),
                        }}
                        value={classObj.price}
                        placeholder={"150000"}/>
                    </span>
                </div>

                {/*<LagFreeTextEditor*/}
                {/*    title={'Kuota'}*/}
                {/*    changeValue={value => {*/}
                {/*        setClass({*/}
                {/*            ...classObj,*/}
                {/*            price : value*/}
                {/*        })*/}
                {/*    }}*/}
                {/*    InputProps={{*/}
                {/*        startAdornment: (*/}
                {/*            <InputAdornment position="start">*/}
                {/*                    <span*/}
                {/*                        style={{*/}
                {/*                            fontFamily: "OpenSans-Regular",*/}
                {/*                            color: "grey"*/}
                {/*                        }}>Rp</span>*/}
                {/*            </InputAdornment>*/}
                {/*        ),*/}
                {/*    }}*/}
                {/*    value={classObj.price}*/}
                {/*    placeholder={"0"}/>*/}

                {/*<span*/}
                {/*    style={{*/}
                {/*        display: "flex",*/}
                {/*        flexDirection: "row",*/}
                {/*        fontSize: ".9rem",*/}
                {/*        alignItems: "center",*/}
                {/*        fontFamily: 'OpenSans-SemiBold'*/}
                {/*    }}>*/}
                {/*    Kuota *&nbsp;*/}
                {/*    <MiniTooltip*/}
                {/*        title="Petunjuk Kuota"*/}
                {/*        content={<>*/}
                {/*            Isi 0 untuk sesi dengan kuota tidak terbatas*/}
                {/*        </>}/>*/}
                {/*</span>*/}
                <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    padding: 10
                }}>
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >
                    <LagFreeTextEditor
                        title={'Kuota'}
                        changeValue={value => {
                            setClass({
                                ...classObj,
                                quota: value
                            })
                        }}
                        style={{
                            marginLeft: "0.5em",
                            style: {
                                fontSize: "1em",
                            }
                        }}
                        InputProps={{
                            style: {
                                fontSize: "1em",
                                cursor: "pointer"
                            }
                        }}
                        value={classObj.quota}
                        placeholder={"Isi 0 Untuk Tak Terbatas"}
                        miniTooltip={
                            <MiniTooltip
                                title="Petunjuk Kuota"
                                content={<>
                                    Isi 0 untuk sesi dengan kuota tidak terbatas
                                </>}/>
                        }
                    />
                    </span>
                </div>
            </Row>
        </>
    }

    const renderContent = () => {
        return <>
            <Row>
                <Col md={12}>
                    <p style={{
                        color: Palette.PRIMARY,
                        fontFamily: "OpenSans-Bold",
                        fontSize: "1.2em",
                        marginTop: 15
                    }}>Banner Utama</p>
                </Col>
            </Row>

            <CustomDropzone
                width={"500px"}
                height={"500px"}
                // id={"reference-component"}
                aspect={1}
                imageSrc={classObj.class_image_url}
                loading={iconLoading}
                prompt={<>Tambah Banner<br/>(Rekomendasi 800*800)</>}
                onDrop={acceptedFiles => onBannerPicked(acceptedFiles)}
            />
            {/*{renderNameAndIconForm()}*/}
            {renderAbout()}

            {renderRegistrationInfoForm()}

            {renderDateTimeForm()}
            {renderDateTimeForm(true)}

            {/*{renderAssetForm()}*/}
            {/*{renderUrlForm()}*/}

            {/*{renderStyleEditor()}*/}

            {renderButtons()}

            <div style={{height: "3em"}}/>

            <NewPreviewModal
                show={isHomePagePreviewVisible}
                fields={registrationInformation}
                onClose={() => setIsHomePagePreviewVisible(false)}
                event={currentEvent}
                session={currentSession}
                url={`https://i.intellivent.id/${currentEvent.eventRoute}/preview`}
            />

            <EventSummaryModal
                show={isEventSummaryModalVisible}
                onClose={() => setIsEventSummaryModalVisible(false)}
                onSubmit={() => publish()}
                event={currentEvent}
                session={currentSession}
            />

        </>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    return (
        <div>
            {renderContent()}
        </div>
    );

}

