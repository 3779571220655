import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Modal,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tabs
} from "react-bootstrap"

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import 'bootstrap/dist/css/bootstrap.min.css';

import PropTypes from 'prop-types'

import 'moment/locale/id'
import {Input, TextField} from "@material-ui/core"
import Email from "../../../../../models/Email"
import moment from "moment"
import AlertBox from "../../../../reusable/AlertBox/AlertBox"
import LagFreeTextEditor from "../../../../reusable/LagFreeTextEditor";


export default function RegistrantRejectionDialog({registrant, activeSession, show, onReject, onAbort, onReasonChange, handleClose}) {

    RegistrantRejectionDialog.propTypes = {
        registrant: PropTypes.object,
        show: PropTypes.bool,
        onReject: PropTypes.func,
        onAbort: PropTypes.func,
        onReasonChange: PropTypes.func,
        handleClose: PropTypes.func,
        activeSession: PropTypes.object
    }



    return(<Modal  show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
            Tolak Registrasi
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col lg={12}>Anda akan menolak pendaftaran {registrant.full_name} dari sesi {activeSession.session_name}.</Col>
            </Row>
            <Row>
                <Col lg={12}><br/></Col>
            </Row>

            <Row>
                <Col lg={3}> Alasan Penolakan</Col>
                <Col lg={9}><LagFreeTextEditor style={{width:"100%"}} changeValue={(val) =>  onReasonChange(val)}/></Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onAbort}>Batal</Button>
            <Button onClick={onReject}>Tolak</Button>
        </Modal.Footer>
    </Modal>)

}
