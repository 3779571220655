import React, {useEffect, useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
} from "@material-ui/core";

import {Col, Container, ListGroup, Modal, ModalBody, ModalTitle, Row} from "react-bootstrap";
import CustomButton from "../CustomButton";
import ModalHeader from "react-bootstrap/ModalHeader";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";

import {FaTimes, MdAdd, MdClear} from "react-icons/all";
import Card from "react-bootstrap/Card";
import Divider from "@material-ui/core/Divider";
import Palette from "../../../util/Palette";
import OptionsFieldsEditor from "../OptionsFieldsEditor";
import LagFreeTextEditor from "../LagFreeTextEditor";
import {MdEdit} from "react-icons/md";
import {FaPlus, FaTrash} from "react-icons/fa";
import FormFieldModal from "./FormFieldModal";
import Survey from "../../../models/Survey";
import moment from "moment";
import PieChart from "../Charts/PieChart";
import LineChart from "../Charts/LineChart";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SurveyResultModal(props) {

    let [errorMessage, setErrorMessage] = useState("")
    let [surveyResponse, setSurveyResponse] = useState([])
    let [survey, setSurvey] = useState({})

    useEffect(() => {

        if (props.survey) {
            let selectedSurvey = props.survey
            setSurvey(selectedSurvey)

            console.log(selectedSurvey)

            if (selectedSurvey.id) {
                getSurveyResult(selectedSurvey.id)
            }

        }

    }, [props.survey])

    const getSurveyResult = async (id) => {
        let surveyModel = new Survey()
        let result = await surveyModel.getSurveyResults(id)
        console.log(result)

        let formattedResult = result.map((obj, key) => {
            return {
                ...obj,
                answers: JSON.parse(obj.answer_json)
            }
        })

        setSurveyResponse(formattedResult)
    }

    const onClose = () => {

        props.onClose()

    }
    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const generateResultCardBody = (question) => {

        console.log(question)

        if (question.type === "text" || question.type === "tanggal") {
            return <>
                <ListGroup style={{
                    width: "100%",
                    marginTop: "1em",
                }} variant={"flush"}>
                    {
                        surveyResponse?.map((answ, key) => {
                            console.log(answ)
                            return <ListGroup.Item
                                key={key}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}
                            >
                                {
                                    question.type === "tanggal" ?
                                        new moment(answ.answers[question.field]).format("DD MMMM YYYY")
                                        :
                                        answ.answers[question.field]
                                }

                            </ListGroup.Item>
                        })
                    }
                </ListGroup>
            </>
        }

        if (question.type === "option" || question.type === "checkbox") {

            let labels = [...question.options]
            let result = []

            for(let l of labels){
                let count = 0;
                for(let sr of surveyResponse){
                    let answer = sr.answers[question.field]
                    console.log(surveyResponse.answers, question.field)
                    if(answer === l){
                        count++;
                    }
                }
                result.push(count)
            }

            console.log(labels)
            console.log(result)

            return <PieChart
                labels={labels}
                data={result}
            />
        }

        return <>
            Not Compatible Type
        </>
    }

    const renderContent = () => {
        return <>
            <Row style={{
                marginTop: "0.5em",
                fontSize: "0.9em"
            }}
            >
                <Col md={12}>Jumlah Responden : {surveyResponse?.length} orang</Col>
            </Row>

            <Row style={{
                marginTop: "1em",
                fontSize: "0.9em"
            }}
            >
                <Col md={12}>Hasil</Col>

                {
                    survey?.questions?.map((obj, key) => {
                        return <Col md={6}>
                            <Card style={{
                                marginTop: "1em", padding: "1em",
                                display: "flex",
                                flexDirection: "column",
                                height : "300px"
                            }}>
                                {obj.field}
                                {
                                    generateResultCardBody(obj)
                                }
                            </Card>

                        </Col>
                    })
                }
            </Row>

        </>
    }

    return (
        <Dialog
            open={props.show}
            maxWidth="lg"
            style={{
                fontFamily: "Montserrat"
            }}
            fullWidth={true}
            onClose={() => onClose()}
            TransitionComponent={Transition}>
            <>
                <ModalHeader closeButton onHide={onClose}>
                    <ModalTitle
                        style={{
                            fontFamily: "Montserrat"
                        }}
                        onClose={() => onClose()}>
                        <span style={{
                            fontFamily: "Montserrat",
                            fontWeight: "bold"
                        }}>Hasil Survey</span>
                    </ModalTitle>
                </ModalHeader>

                <ModalBody>

                    <Container fluid>

                        {renderAlertBox()}

                        {renderContent()}

                    </Container>

                </ModalBody>
            </>
        </Dialog>
    );
}

