import React, {useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
} from "@material-ui/core";

import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import MomentUtils from '@date-io/moment';

// import {ButtonGroup} from "react-bootstrap";
import Palette from "../../../util/Palette";
import CustomButton from "../CustomButton";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserInfoModal(props) {

    const {member, show} = props

    const onClose = () => {
        props.onClose()
    }

    const renderModalFooter = () => {

        return <DialogActions>

            {/*<div style={{*/}
            {/*    position : "absolute",*/}
            {/*    left : 16*/}
            {/*}}>*/}
            {/*    Step {this.state.step} of 2*/}
            {/*</div>*/}
            <CustomButton
                color="primary"
                onClick={() => {
                    onClose()
                }}>
                Tutup
            </CustomButton>
        </DialogActions>
    }

    const summaryPoints = [
        {
            data: member.id,
            label: "id",
        },
        {
            data: member.full_name,
            label: "Nama"
        },
        {
            data: member.email,
            label: "Email"
        },
        {
            data: member.phone_num,
            label: "Nomor Telfon"
        },
        {
            data: member.gender === "MALE" ? "Pria" : "Wanita",
            label: "Jenis Kelamin"
        },
        {
            data: moment(member.date_of_birth).format("DD MMM YYYY"),
            label: "Tanggal Lahir"
        },
        {
            data: moment(member.created_at).format("DD/MM/YY HH:mm"),
            label: "Waktu Pendaftaran"
        },
        {
            data: moment(member.modified_at).format("DD/MM/YY HH:mm"),
            label: "Terakhir Dimodifikasi"
        },
    ]

    return (
        <Dialog
            open={show}
            maxWidth="md"
            fullWidth={true}
            onClose={() => onClose()}
            TransitionComponent={Transition}>
            <>
                <DialogTitle onClose={() => this.onClose()}>
                    <span style={{
                        fontWeight: "bold"
                    }}>Informasi Pengguna</span>
                </DialogTitle>
                <DialogContent>
                    <div style={{
                    }}>

                        <table style={{width : "100%"}}>

                            {
                                summaryPoints.map((obj, key) => {
                                    return <tr>
                                        <td style={{
                                            width : "25%", verticalAlign : "top",
                                            paddingTop  : obj.breakpoint ? "2em" : "0.5em",
                                        }} colSpan={obj.heading ? 2 : 1}>
                                            <b style={{
                                                fontSize : obj.heading ? "1em" : null,
                                                color : obj.heading ? Palette.PRIMARY : "black"
                                            }}>{obj.label}</b><br/>
                                        </td>
                                        <td style={{
                                            width : "75%", verticalAlign : "top",
                                            paddingTop  : obj.breakpoint ? "2em" : "0.5em"
                                        }} colSpan={obj.heading ? 0 :1}>
                                            {obj.data}
                                        </td>
                                    </tr>
                                })
                            }

                        </table>
                    </div>

                </DialogContent>
                {renderModalFooter()}
            </>
        </Dialog>
    );
}

