function template(className, classDate, speakerName, participantName ) {
    return( '<html lang="en">\n' +
    '\n' +
    '<head>\n' +
    '    <meta charset="utf-8">\n' +
    '    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">\n' +
    '</head>\n' +
    '<body>\n' +
    '<div class="container-fluid" style="background-color: #F6F6F8; padding-top: 30px; padding-bottom: 30px">\n' +
    '    <table style="width: 100%">\n' +
    '        <tr align="center">\n' +
    '            <td>\n' +
    '                <div style="background-color: #FFFFFF; -webkit-box-shadow: 0px 0px 5px 0px rgba(209,209,209,1); -moz-box-shadow: 0px 0px 5px 0px rgba(209,209,209,1); box-shadow: 0px 0px 5px 0px rgba(209,209,209,1); border-radius: 5px; max-width: 750px; padding-bottom: 30px"\n' +
    '                     align="left">\n' +
    '                    <div class="container">\n' +
    '                        <div class="row" style="padding-top: 30px">\n' +
    '                            <table style="width: 100%">\n' +
    '                                <tr align="center">\n' +
    '                                    <td>\n' +
    '                                        <img src="https://intellivent.sgp1.digitaloceanspaces.com/pm%20x%20gri.jpeg"\n' +
    '                                             style="max-width: 700px"/>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '                            </table>\n' +
    '                        </div>\n' +
    '                        <!--MAIN EMAIL START-->\n' +
    '                        <div class="row"\n' +
        '                             style="padding: 30px; padding-bottom: 20px; font-size: 28px; color: #000000B3">\n' +
        '                            Hai, '+participantName+'!\n' +
        '                        </div>\n' +
        '                        <div class="row" style="padding: 30px; padding-top: 0px; font-size: 20px; color: #000000B3; background-color: #FFFFFF">\n' +
        '                            <div style="line-height: 30px;">\n' +
        '                                <br/>\n' +
        '                                Terima kasih sudah mengikuti sesi <b>"'+className+'"</b> bersama <b>'+speakerName+'</b> pada\n' +
        '                                patjarmerah x Festival Pembaca Indonesia pada <b>'+classDate+'</b> lalu. Berikut kami kirimkan sertifikat\n' +
        '                                elektronik milikmu.<br/>\n' +
        '                                <br/>\n' +
        '                                Patjarboekoe bisa mendapatkan informasi mengenai kegiatan lainnya dari\n' +
        '                                <b>patjarmerah</b> dengan mengunjungi laman\n' +
        '                                berikut: <a href="https://patjarmerah.com/">Website patjarmerah</a><br/>\n' +
        '                                <br/>\n' +
        '                                Sampai berjumpa lagi dalam sesi virtual lainnya di patjarmerah, ya!<br/>\n' +
        '                                <br/>\n' +
        '                                Salam,<br/>\n' +
        '                                <b>patjarmerah x Festival Pembaca Indonesia 2021</b><br/>\n' +
        '\n' +
        '                            </div>\n' +
        '                        </div>\n' +
    '\n' +
    '                        <!--MAIN EMAIL END-->\n' +
    '                        <div class="row" style="height: 1px; background-color: #E7E7E7"></div>\n' +
    '\n' +
    '                        <table style="width: 100%;">\n' +
    '\n' +
    '                            <tr align="center">\n' +
    '                                <td align="center" style="vertical-align:center">\n' +
    '                                    <br/>\n' +
    '                                    <a href="https://www.instagram.com/patjarmerah_id/"><img\n' +
    '                                            src="https://intellivent.sgp1.digitaloceanspaces.com/patjarmerah/emailbg/papermoon/pm.png"\n' +
    '                                            style="width:80px;margin-right:20px"></a><br/>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '\n' +
    '                        </table>\n' +
    '\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '    </table>\n' +
    '</div>\n' +
    '</body>\n' +
    '\n' +
    '</html>')}

export default template;
