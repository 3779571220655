import React, {Component, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {Button, Card, Col, Container, Dropdown, Nav, Navbar, NavDropdown, Row, Spinner, Tabs} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {TextField, Button as MaterialButton, ButtonGroup} from "@material-ui/core";
import Palette from "../../../../util/Palette";
import Dropzone from "react-dropzone";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {FaPlus, FaClock, FaPen, FaSave, FaTimes} from "react-icons/fa";
import EditorPageWrapper from "../EditorPageWrapper";
import IconButton from "@material-ui/core/IconButton";
import TemplateValidator from "../../../../util/TemplateValidator"

import 'moment/locale/id'

import Event from "../../../../models/Event"
import CustomButton from "../../../reusable/CustomButton";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Session from "../../../../models/Session";
import {FaUserFriends, FaBullhorn, FaMailBulk, MdSend} from "react-icons/all";
import Tab from "react-bootstrap/Tab";
import CreatableSelect from 'react-select/creatable';
import textFormatter from "../../../../util/textFormatter";
import Email from "../../../../models/Email";
import Class from "../../../../models/Class"
import FileUpload from "../../../reusable/FileUpload";
import Table from "react-bootstrap/Table"
import EmailTemplateEditorDialog from "./specifics/EmailTemplateEditorDialog"
import EmailTemplateDropdown from "./specifics/EmailTemplateDropdown"
import EmailTemplatePreview from "./specifics/EmailTemplatePreview"
import {animateScroll as scroll} from "react-scroll";


import EmailDisplayConfiguration from "./config/EmailDisplayConfiguration"
import MobTable from "../../../reusable/Table/MobTable"
import LagFreeTextEditor from "../../../reusable/LagFreeTextEditor";
import EmailBroadcast from "../../../reusable/EmailBroadcast";

export default function EmailManagementForClass(props) {

    const {event_url} = useParams()
    let history = useHistory()
    let routerLocation = useLocation()

    //Prompt Params
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [activeSession, setActiveSession] = useState({})

    //Class State
    const [activeClass, setActiveClass] = useState({})


    //Email Template States
    const [emailTemplates, setEmailTemplates] = useState([])
    const [isEmailTemplatesLoading, setIsEmailTemplatesLoading] = useState(false)
    const [showEditEmailTemplateDialog, setShowEmailTemplateDialog] = useState(false)
    const [showCreateEmailTemplateDialog, setShowCreateEmailTemplateDialog] = useState(false)
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState({})
    //array that contains the problems of the email templates
    const [problems, setProblems] = useState([])

    //Send Email State
    const [recipients, setRecipients] = useState([])
    const [registrants, setRegistrants] = useState([])
    const [emailSubject, setEmailSubject] = useState("")
    const [emailContent, setEmailContent]= useState("")

    const [attachments, setAttachments] = useState([])
    const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id"
            },
            {
                Header: "Template Name",
                accessor: "template_name"
            },
            {
                Header: "Template Subject",
                accessor: "subject_template"
            },
            {
                Header: "Skenario",
                accessor: "scenario"
            }
        ]
    )

    const listStyle = {
        marginBottom: "20px"
    }

    const [preview, setPreview] = useState(false)

    useEffect(() => {
        setIsEmailTemplatesLoading(true)
        initializeData()
    }, [])



    const seekProblem = (templateType) => {
        return problems.find(problem =>  problem.type === templateType)
    }

    const findEmailTemplate = (templateId) => {
        return emailTemplates.find(e => e.id === templateId)
    }


    const filterEmailTemplatesByScenario = (scenario) =>{
        return emailTemplates.filter((emailTemplate) => emailTemplate.scenario === scenario)
    }

    const handleTemplateChangeForScenario = (scenario, templateId) =>{
        setIsLoading(true)
        let lcScenario = scenario.toLowerCase()
        let ClassInstance = new Class()
        let body = {}
        body[lcScenario+"_template_id"] = templateId
        ClassInstance.update(activeClass.code,body)
            .then(response=>{
                setActiveClass({
                    ...activeClass,
                    ...body
                })
                setIsLoading(false)
            })
            .catch(error=>{
                alert("Update class error. check logs")
                console.error(error)
                setIsLoading(false)
            })

    }

    //To render the dropdown of each scenario to select the appropriate template
    const renderTemplateScenarioSelector = (scenario, scenarioName) =>{
        let lcScenario = scenario.toLowerCase()
        //value 0 artinya di convert jadi null. column scenario_template_id akan di nullkan.
        return <>
            {scenarioName}<br/>
            <Select
                fullWidth
                value={activeClass[lcScenario + "_template_id"] ? activeClass[lcScenario + "_template_id"] : 0}
                onChange={evt => {
                    let templateId = evt.target.value
                    templateId = templateId !== 0 ? templateId : null
                    handleTemplateChangeForScenario(scenario, templateId)
                }}
                IconComponent={()=>(<></>)}
                style={{
                    marginLeft: "0.5em",
                    style: {
                        fontSize: "1em",
                    }
                }}
                placeholder={`Pilih template untuk skenario ${scenarioName}`}
                defaultValue={activeClass[lcScenario + "_template_id"] ? activeClass[lcScenario + "_template_id"] : 0}
            >
                <MenuItem value={0}>
                    <span style={{fontFamily: "Poppins"}}>Template "{scenarioName}" biasa</span>
                </MenuItem>
                {
                    filterEmailTemplatesByScenario(scenario).map((e, key) => {
                        return <MenuItem value={e.id}>
                            <span style={{fontFamily: "Poppins"}}>{e.template_name}</span>
                        </MenuItem>
                    })
                }
            </Select>

            </>
    }

    const renderTemplateEmailBody = () => {

        let interactions = [
            {
                name: "Edit",
                action: (emailTemplate) =>{

                    console.log("email template ", emailTemplate)

                    setSelectedEmailTemplate(emailTemplate)
                    setShowEmailTemplateDialog(true)
                }
            },
        ]

        return <>

            <EmailTemplateEditorDialog
                mode={"edit"}
                show={showEditEmailTemplateDialog}
                emailTemplate={selectedEmailTemplate}
                handleClose={() => {setShowEmailTemplateDialog(!showEditEmailTemplateDialog)}}
                activeSession={activeSession}
                onSaveSuccess={async (templateObject) =>{
                    //overwrite new values
                    setSelectedEmailTemplate({
                        ...selectedEmailTemplate,
                        ...templateObject
                    })
                    await getEmailTemplates()

            }}/>

            <EmailTemplateEditorDialog
                mode={"create"}
                show={showCreateEmailTemplateDialog}
                handleClose={() => {setShowCreateEmailTemplateDialog(!showCreateEmailTemplateDialog)}}
                activeSession={activeSession}
                onSaveSuccess={async (templateObject) =>{
                    setEmailTemplates([...emailTemplates,templateObject])
                }}/>


            <b style={{
                color: Palette.PRIMARY,
                fontSize: "2em",
                fontFamily: "OpenSans-Bold",
            }}>
                Pengaturan Email Kelas
            </b>

            <p>
                Sistem registrasi memiliki fitur automasi email. Dengan fitur ini, peserta acara akan mendapatkan
                email otomatis sebagai berikut :
            </p>

            <ul>
                <li style={{marginBottom:"50px"}}>
                    {renderTemplateScenarioSelector("WAITING", "Menunggu Pembayaran")}
                </li>

                <li style={{marginBottom:"50px"}}>
                    {renderTemplateScenarioSelector("ACCEPTED", "Pendaftaran Berhasil")}
                </li>

                <li style={{marginBottom:"50px"}}>
                    {renderTemplateScenarioSelector("REJECTED", "Pendaftaran Ditolak")}
                </li>

                <li style={{marginBottom:"50px"}}>
                    {renderTemplateScenarioSelector("PENDING", "Masuk Daftar Antrian")}
                </li>

                <li style={{marginBottom:"50px"}}>
                    {renderTemplateScenarioSelector("REMINDER", "Pengingat Sesi Dimulai")}
                </li>
            </ul>


            <b style={{
                color: Palette.PRIMARY,
                fontSize: "2em",
                fontFamily: "OpenSans-Bold",
            }}>
                Daftar Template Email
            </b>

            <div style={{paddingBottom: "50px"}}>
                <MobTable columns={columns} data={emailTemplates} interactions={interactions}/>
                <div><Button style={{color: 'white'}} onClick={() => {setShowCreateEmailTemplateDialog(true)}}>Buat Template Baru</Button></div>
            </div>


        </>
    }

    const renderEmailTemplateTable = (emailTemplates) =>{
        let emailTemplateFields = ["Template Name", "Template Subject", "Options"]
        let tableBody;
        let emailTemplateTable
        if(isEmailTemplatesLoading) {
            tableBody = <tbody>
                    <tr>
                        <td><Spinner animation={"grow"} size={"md"}/></td>
                        <td><Spinner animation={"grow"} size={"md"}/></td>
                        <td><Spinner animation={"grow"} size={"md"}/></td>
                        <td><Spinner animation={"grow"} size={"md"}/></td>
                    </tr>
                </tbody>

        } else {
            tableBody = <tbody>
                {
                    emailTemplates.map((emailTemplate, key) => {
                        return <tr>
                            <td>{key + 1}</td>
                            <td>{emailTemplate.template_name}</td>
                            <td>{emailTemplate.subject_template}</td>
                            <td><Button onClick={() => {
                                console.log("Mounting template:" , emailTemplate)
                                setSelectedEmailTemplate(emailTemplate)
                                setShowEmailTemplateDialog(true)
                            }}>Ubah</Button></td>
                        </tr>
                    })
                }
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><Button onClick={() =>{setShowCreateEmailTemplateDialog(true)}}>Tambah</Button></td>
                </tr>
                </tbody>

        }
        emailTemplateTable = <Table
            style={{
                marginTop: "1em"
            }}
            striped hover responsive>
            <thead>
            <tr>
                <th>#</th>
                {
                    emailTemplateFields.map((field, key) => {
                        return <th>
                            {field}
                        </th>
                    })
                }
            </tr>
            </thead>
                {tableBody}
        </Table>

        return emailTemplateTable
    }

    const handleChange = (newValue, actionMeta) => {
        if(newValue===null){
            return setRecipients([])
        }
        setRecipients(newValue)
    };

    const handleAttachmentChanges = (newValue) =>{
        setAttachments(newValue)
    }

    const getRegistrations = async function (paramSession) {

        setIsLoadingBar(true)
        const session = new Session()

        console.log("active session", paramSession)

        try {
            let result = await session.getRegistrations(paramSession.id)
            console.log(result)

            let temp = result.map((obj, key) => {
                let tempJSON = JSON.parse(obj.visitor_information)
                return {
                    value : tempJSON["Email"],
                    label : tempJSON["Nama Lengkap"]
                }
            })

            setRegistrants(temp)
            console.log("temp", temp)

        } catch (e) {
            console.log(e)
        }
        setIsLoadingBar(false)

    }

    const initializeData = function() {
        let promises = []
        promises.push(getEmailTemplates())
        promises.push(getClassInfo())
        Promise.all(promises).then(results=>{
            setIsEmailTemplatesLoading(false)
        }).catch(e=>{
            alert("Terjadi Kesalahan. Harap Periksa Jaringan Anda. Jika terus terjadi, harap hubungi admin.")
            console.log(e)
        })

    }


    const getEmailTemplates = async function() {
        setIsEmailTemplatesLoading(true)

        const email = new Email()

        try{
            let templates = await email.getTemplates()

            console.log(templates)

            setEmailTemplates(templates)

            console.log(emailTemplates)

        } catch (e) {
            console.error(e)
            setIsEmailTemplatesLoading(false)
        }
        setIsEmailTemplatesLoading(false)
    }

    const getClassInfo = async function() {
        setIsEmailTemplatesLoading(true)
        const ClassInstance = new Class()
        let cls = await ClassInstance.getByCode(event_url)
        setActiveClass(cls)
    }

    const handleTemplateDropdownChange = async (scenario, templateId) => {
        let sessionId = activeClass.code

    }


    const processSubmit = async() =>{
        setIsLoadingBar(true)
        let email = new Email();

        if(recipients.length === 0){
            setIsLoadingBar(false)
            return promptError("Harap masukan tujuan pengiriman Email")
        }

        if(emailSubject.length === 0){
            setIsLoadingBar(false)
            return promptError("Harap mengisi subyek dari Email.")
        }

        if(emailContent.length === 0){
            setIsLoadingBar(false)
            return promptError("Isi Email tidak boleh kosong.")
        }

        try {

            let formattedRecipients = recipients.map((obj,key)=>{
                return obj.value
            })

            let formattedAttachments = attachments.map((obj,key)=> {
                return {
                    filename : obj.label,
                    path : obj.value
                }
            })

            let result = await email.sendBatch({
                "recipients": formattedRecipients,
                "subject": emailSubject,
                "content": emailContent,
                "attachments" : formattedAttachments
            })
            console.log(result)
            setIsLoadingBar(false)

            promptSuccess("Email Telah Diproses. Pada umumnya email akan terkirim dalam beberapa menit. Jika sudah beberapa jam dan email belum terkirim, harap hubungi tim kami.")

        }catch(e){
            console.log(e)
            setIsLoadingBar(false)
            promptError(JSON.parse(e));
        }
    }

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"email-management"}
            >
                {renderAlertBox()}
                {renderSuccessBox()}
                <Tabs defaultActiveKey="template" id="uncontrolled-tab-example">
                    <Tab
                        eventKey="template"
                        title={
                            <span style={{
                                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"
                            }}>
                                <FaMailBulk/>&nbsp;Template Email
                            </span>
                        }
                    >
                        <div style={{
                            padding: "1em"
                        }}>
                            {renderTemplateEmailBody()}
                        </div>
                    </Tab>

                    <Tab
                        title={
                            <span style={{
                                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"
                            }}>
                                <FaBullhorn/>&nbsp;Broadcast Email
                            </span>
                        }
                        eventKey="broadcast">
                        <div style={{
                            padding: "1em"
                        }}>
                            <EmailBroadcast
                                classCode={event_url}
                                isLoadingBar={isLoadingBar}
                                setIsLoadingBar={setIsLoadingBar}
                            />
                        </div>
                    </Tab>
                </Tabs>
            </EditorPageWrapper>
        </>

    )

}
