import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import Palette from "../../../util/Palette";
import GlobalData from "../../../util/GlobalData";
import {useCookies} from "react-cookie";
import User from "../../../models/User";
import CustomButton from "../../reusable/CustomButton";
import {FaFileDownload, FaKey, FaPen, FaSave, FaTimes} from "react-icons/all";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {animateScroll as scroll} from "react-scroll";
import MobTable from "../../reusable/Table/MobTable";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import Member from "../../../models/Users";
import UserParticipationModal from "../../reusable/modals/UserParticipationModal";
import UserInfoModal from "../../reusable/modals/UserInfoModal";
import EditUserModal from "../../reusable/EditUserModal";
import {CSVLink} from "react-csv";
import Admin from "../../../models/Admin";
import Users from "../../../models/Users";
import moment from "moment";
import Button from "@material-ui/core/Button";

const defaultPasswordData = {
    oldPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
}

let member = new Users();

export default function Profile(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)

    const [isEditingPassword, setIsEditingPassword] = useState(false)

    const [userList, setUserList] = useState([])
    const [deletedUsers, setDeletedUsers] = useState([])

    const [isClassModalVisible, setClassModalVisible] = useState(false)

    const [selectedUser, setSelectedUser] = useState({});

    const [isUserInfoModalVisible1, setUserInfoModalVisible1] = useState(false)
    const [isUserInfoModalVisible, setUserInfoModalVisible] = useState(false)
    const [selectedUserInfo, setSelectedUserInfo] = useState({})

    const [isDelete, setIsDelete] = useState(false)

    let columns = [
        {
            Header: "ID",
            accessor: "id"
        },
        {
            Header: "Full Name",
            accessor: "full_name"
        },
        {
            Header: "Date of Birth",
            accessor: "date_of_birth",
            Cell: (cellInfo) => {

                let rowInfo = new moment(cellInfo.row.values).format("MM/DD/YYYY");
                return <>{rowInfo}</>

            }
        },
        {
            Header: "Gender",
            accessor: "gender"
        },
        {
            Header: "Phone Number",
            accessor: "phone_num"
        },
        {
            Header: "E-mail",
            accessor: "email"
        },
        {
            Header: "Created at",
            accessor: "created_at",
            Cell: (cellInfo) => {

                let rowInfo = new moment(cellInfo.row.values).format("MM/DD/YYYY");
                return <>{rowInfo}</>

            }
        },
        {
            Header: "Modified at",
            accessor: "modified_at",
            Cell: (cellInfo) => {

                let rowInfo = new moment(cellInfo.row.values).format("MM/DD/YYYY");
                return <>{rowInfo}</>

            }
        },
        {
            Header: "City",
            accessor: "city"
        },
    ]

    const member = new Users();

    const interactions = [
        {
            name: "Kelas",
            action: (registrant) => {
                setClassModalVisible(true)
                setSelectedUser(registrant)
            },
            style: {width: 100}
        },
        {
            name: "Detail",
            action: (registrant) => {
                setUserInfoModalVisible1(true)
                setSelectedUser(registrant)
            },
            style: {width: 100}
        }
    ];


    const deletedUserInteraction = [
        {
            name: "Pulihkan",
            action: (member) => {
                unDeleteUser(member.id)
            },
            style: {width: 100}
        }
    ];


    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = async () => {
        setIsLoadingBar(true)
        try {
            let result = await member.getAll()
            console.log(result);

            let tempUsers = []
            let tempDeletedUsers = []

            for(let r of result){
                tempUsers.push(r)
            }

            setUserList(tempUsers)
            setDeletedUsers(tempDeletedUsers)
            console.log(tempUsers)

            setIsLoadingBar(false)


        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    const closeUserParticipationModal = () => {
        setClassModalVisible(false)
        setSelectedUser({})
    }

    const closeUserInfoModal = () => {
        setUserInfoModalVisible1(false)
        setSelectedUser({})
    }

    const activateUser = async(id) =>{

        try{
            let member = new Member();
            let result = await member.activate(id)

            getUserData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const deleteUser = async() =>{

        try{
            let member = new Member();
            let result = await member.delete(selectedUser.id)

            setIsDelete(false)

            getUserData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const unDeleteUser = async(id) =>{

        try{
            let member = new Member();
            let result = await member.unDelete(id)

            getUserData()

            console.log(result)

        }catch(e){
            console.log(e)
        }

    }

    const getNewsletterRegistrant = () =>{
        let filteredRegistrant = userList.filter((obj)=>{return obj.subscribe_newsletter && obj.active})
        return filteredRegistrant.map((obj, key) => {
            return {
                "Email" : obj.email,
                "FullName" : obj.full_name
            }
        })
    }

    return (
        <>

            <Dialog open={isDelete}
                    maxWidth="sm"
                    fullWidth={true}
            >
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi Hapus Peserta
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin menghapus peserta <span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedUser.name}</span>?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setIsDelete(false)
                                }}
                                style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                onClick={deleteUser}
                                variant={"contained"}
                                color="secondary">
                                Hapus
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <EditUserModal
                isOpen={isUserInfoModalVisible}
                onClose={() => {
                    setUserInfoModalVisible(false)
                    setSelectedUserInfo({})
                    getUserData()
                }}
                user={selectedUserInfo}
            />

            <UserParticipationModal
                isOpen={isClassModalVisible}
                onClose={closeUserParticipationModal}
                user={selectedUser}
            />

            <UserInfoModal
                show={isUserInfoModalVisible1}
                onClose={closeUserInfoModal}
                member={selectedUser}
            />

            {/*<UserInfoModal*/}
            {/*    show={isUserInfoModalVisible}*/}
            {/*    onClose={() => setUserInfoModalVisible(false)}*/}
            {/*    member={selectedUserInfo}*/}
            {/*/>*/}

            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"users"}
            >
                <>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 30}}>
                        <div style={{
                            marginTop: "1em",
                            marginBottom: "0.5em",
                            color: Palette.PRIMARY,
                            fontSize: "1.75em",
                            fontFamily: "OpenSans-Bold",
                            flex: 1
                        }}>
                            Daftar Pengguna
                        </div>

                        <CustomButton
                            disabled
                            style={{
                                opacity: 0, marginTop: 20, borderWidth: 0, fontFamily: 'OpenSans-SemiBold'
                            }}
                            variant={"outlined"}>+ Buat Kelas Baru</CustomButton>
                    </div>


                    <Tabs defaultActiveKey="active">
                        <Tab
                            eventKey="active"
                            title={
                                "Aktif"
                            }
                        >
                            <CSVLink
                                style={{marginTop: "1em", color: 'white'}}
                                separator={";"}
                                data={getNewsletterRegistrant()}
                                filename={`email-peserta.csv`}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Download Data Untuk Email&nbsp;&nbsp;<FaFileDownload/>
                            </CSVLink>
                            <MobTable
                                columns={columns}
                                data={userList}
                                interactions={interactions}
                            />
                        </Tab>
                        <Tab
                            eventKey="deleted"
                            title={
                                "Terhapus"
                            }
                        >
                            <MobTable
                                columns={columns}
                                data={deletedUsers}
                                interactions={deletedUserInteraction}
                            />
                        </Tab>
                    </Tabs>
                </>
            </EditorPageWrapper>

        </>
    );

}
