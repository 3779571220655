import React, {Component, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {
    Button,
    Col,
    Container,
    Dropdown,
    Modal,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tab,
    Tabs
} from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';

import {CSVLink} from "react-csv";

import {useHistory, useLocation, useParams} from "react-router-dom";

import Palette from "../../../../util/Palette";

import EditorPageWrapper from "../EditorPageWrapper";

import 'moment/locale/id'
import CustomDropzone from "../../../reusable/CustomDropzone";

import Event, {PAYMENT_TYPE, PUBLISH_STATUS} from "../../../../models/Event"
import CustomButton from "../../../reusable/CustomButton";
import MiniTooltip from "../../../reusable/MiniTooltip";

import Session from "../../../../models/Session";
import {FaEye, FaUserFriends, FaFileDownload, FaUserPlus} from "react-icons/all";
import MobTable from "../../../reusable/Table/MobTable"
import moment from "moment"
import RegistrantInspectorDialog from "./specifics/ReigstrantInspectorDialog"
import ApiRequest from "../../../../util/ApiRequest"
import User from "../../../../models/User"
import Registration from "../../../../models/Registration"
import RegistrantRejectionDialog from "./specifics/RegistrantRejectionDialog"
import AlertBox from "../../../reusable/AlertBox/AlertBox"

import textFormatter from "../../../../util/textFormatter";

import _ from "lodash"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

import Class from "../../../../models/Class";
import Participation from "../../../../models/Participation";
import InviteUserModal from "../../../reusable/InviteUserModal";
import EditUserModal from "../../../reusable/EditUserModal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RegistrantNoteDialog from "./specifics/RegistrantNoteDialog"

let classModel = new Class()

export default function UserManagement(props) {

    const {event_url} = useParams()
    let history = useHistory()

    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [activeSession, setActiveSession] = useState({})
    const [activeEvent, setActiveEvent] = useState({})
    const [activeClass, setActiveClass] = useState({})
    const [registrationField, setRegistrationFields] = useState([])
    const [registrantsProcessed, setRegistrantsProcessed] = useState([])

    const [paymentType, setPaymentType] = useState("FREE")

    //Alert message
    let [errorMessage, setErrorMessage] = useState("")
    let [successMessage, setSuccessMessage] = useState("")

    //for the table
    const [registrants, setRegistrants] = useState([])
    const [requiredVisitorInformation, setRequiredVisitorInformation] = useState([])


    //For the note dialog
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [selectedParticipant, setSelectedParticipant] = useState({})

    const [isInviting, setIsInviting] = useState(false)

    //Processed Info for the seperate tables
    const categorizedRegistrants = useMemo(() => {
        let waiting = registrants.filter(r => r.status === Registration.states.WAITING)
        let accepted = registrants.filter(r => r.status === Registration.states.ACCEPTED)
        let rejected = registrants.filter(r => r.status === Registration.states.REJECTED || r.status === Registration.states.CANCELLED)
        let pending = registrants.filter(r => r.status === Registration.states.PENDING)

        return {
            waiting,
            accepted,
            rejected,
            pending
        }

    }, [registrants])

    //for the registrant dialog
    const [activeRegistrant, setActiveRegistrant] = useState({})
    const [registrantInspectorOpen, setRegistrantInspectorOpen] = useState(false)

    //for the rejection reason dialog
    const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false)
    const [rejectedRegistrant, setRejectedRegistrant] = useState({})
    const [rejectionMessage, setRejectionMessage] = useState("")

    const [totalIncome, setTotalIncome] = useState(0)



    const columns = [
        {
            Header: "ID Registrasi",
            accessor: "id"
        },
        {
            Header: "Nama Lengkap",
            accessor: "user.full_name",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let fullName = rowInfo["user.full_name"]

                return <>
                    {fullName}
                </>
            }
        },
        {
            Header: "Email",
            accessor: "user.email",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let email = rowInfo["user.email"]

                return <>
                    {email}
                </>
            }
        },
        {
            Header: "Presensi",
            accessor: "is_present",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let isClaimed = rowInfo["is_present"]
                let id = rowInfo["id"]

                return <>
                    <Select
                        value={isClaimed}
                        onChange={(val) => {
                            updateAbsent(id, !isClaimed)
                        }}
                    >
                        <MenuItem value={1}>Ya</MenuItem>
                        <MenuItem value={0}>Tidak</MenuItem>
                    </Select>
                    {/*{isClaimed ? "Ya" : "Tidak"}*/}
                </>
            }
        },
        {
            Header: "Status Pembayaran",
            accessor: "status",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values

                let value = cellInfo.value

                let statusString = "MENUNGGU PEMBAYARAN"

                //console.log(cellInfo)
                let color = "#000000"

                if (rowInfo.status === "ACCEPTED") {

                    color = "LimeGreen"
                    statusString = "DITERIMA"


                } else if (rowInfo.status === "REJECTED" ||rowInfo.status === "CANCELLED" ) {
                    color = "Red"
                    statusString = "DITOLAK"

                } else if (rowInfo.status === "WAITING") {
                    color = "MediumTurquoise"
                    statusString = "MENUNGGU PEMBAYARAN"

                } else if (rowInfo.status === "PENDING") {
                    color = "DarkOrange"
                    statusString = "DALAM ANTREAN"

                }
                return <p style={{color: color}}>{statusString}</p>
            }
        },
        {
            Header: "Harga",
            accessor: "transaction",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let transaction = rowInfo["transaction"]

                return <>
                    {transaction.amount ? `Rp${textFormatter.moneyFormatter(transaction.amount)}` : "-"}
                </>
            }
        },
        {
            Header: "Catatan",
            accessor: "note",
            Cell: (cellInfo) => {
                let participant = cellInfo.row.original
                let note = participant.note ?? "-"
                if(note.length > 35) {
                    note = note.substr(0, 35) + "..."
                }
                return <>{note}</>
            }
        },
        {
            Header: "Opsi",
            accessor: "confirmed_at",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values
                let originalRowInfo = cellInfo.row.original
                return <div style={{display: "flex", flexDirection: "row"}}>
                    <CustomButton
                        style={{
                            marginRight: 10
                        }}
                        variant={"outlined"}
                        onClick={() => {
                            setActiveRegistrant(rowInfo)
                            setRegistrantInspectorOpen(true)
                        }}
                    >
                        Info
                    </CustomButton>

                    {
                        rowInfo.status === "ACCEPTED" && rowInfo.confirmed_at === null ?
                            <CustomButton variant={"outlined"} onClick={async () => {
                                try {
                                    setIsLoadingBar(true)

                                    let result = await Registration.acceptParticipant(rowInfo.id)
                                    console.log(result)
                                    await getParticipants()
                                    setIsLoadingBar(false)
                                    alert("Sukses")

                                } catch {
                                    alert("Terjadi Kesalahan")
                                    setIsLoadingBar(false)

                                    console.error()
                                }

                            }}>
                                KONFIRMASI
                            </CustomButton>
                            :
                            null
                    }
                    {
                        <CustomButton
                            variant={"outlined"}
                            onClick={() =>{
                                setSelectedParticipant(originalRowInfo)
                                setShowNoteModal(true)
                            }}
                        >
                            Catatan
                        </CustomButton>
                    }

                </div>
            }
        },
    ]

    //Interaction information for MobTable
    const interactions = []

    const getParticipants = async () => {

        let result = await classModel.getClassParticipant(event_url)
        console.log('from get class participant',result)

        let totalIncome = 0;

        for (let r of result) {

            if (r.status === "ACCEPTED") {
                totalIncome += r.transaction.amount
            }

        }

        let formattedRegistrants = result.map((obj,key)=>{
            return {
                ...obj.member,
                ...obj,
                member : {...obj.member}
            }
        })

        console.log('From formatted registrants',formattedRegistrants);
        console.log('total income: ', totalIncome);

        setTotalIncome(totalIncome)
        setRegistrants(formattedRegistrants)

    }

    useEffect(() => {
        getParticipants()
        getClassInfo()
    }, [])

    const getClassInfo = async function() {
        const ClassInstance = new Class()
        let cls = await ClassInstance.getByCode(event_url)
        setActiveClass(cls)
    }


    const updateAbsent = async (id, newValue) => {

        try {
            let participationModel = new Participation();

            let result = await participationModel.updateAbsent(id, newValue)

            let tempRegistrants = [...registrants]

            let idx = registrants.findIndex((obj)=>obj.id === id)
            tempRegistrants[idx] = {
                ...tempRegistrants[idx],
                is_present : newValue ? 1 : 0
            }

            console.log(tempRegistrants)

            setRegistrants(tempRegistrants)

        } catch (e) {
            console.log(e)
            setErrorMessage(JSON.stringify(e))
        }


    }

    const getNewsletterRegistrant = () =>{
        let filteredRegistrant = categorizedRegistrants.accepted.filter((obj)=>{return obj.subscribe_newsletter})
        return filteredRegistrant.map((obj, key) => {
            return {
                "Email" : obj.email,
                "FullName" : obj.full_name
            }
        })
    }

    const renderContent = () => {

        //console.log(categorizedRegistrants.accepted)

        return <>
            <AlertBox error message={errorMessage} handleClose={() => {
                setErrorMessage("")
            }}/>
            <AlertBox message={successMessage} handleClose={() => {
                setSuccessMessage("")
            }}/>
            <Row style={{marginBottom: "1em"}}>
                <Col md={12}>
                    <Collapse
                        in={activeEvent.publish_status === PUBLISH_STATUS.DRAFT || activeEvent.publish_status === PUBLISH_STATUS.HIDDEN}>
                        <Alert
                            severity="warning"
                            action={
                                <></>
                            }
                            style={{
                                marginTop: "1em"
                            }}
                        >
                            Pendaftaran acara
                            mu {activeEvent.publish_status === PUBLISH_STATUS.DRAFT ? "belum" : "sedang tidak"} dibuka.
                            Aktifkan pendaftaran acaramu&nbsp;
                            <a
                                onClick={() => {
                                    history.push("/editor/detail/" + activeEvent.event_url)
                                }}
                                href={""}>disini</a>.
                        </Alert>
                    </Collapse>
                </Col>
            </Row>
            <RegistrantNoteDialog
                registrant={selectedParticipant}
                onClose={() =>{setShowNoteModal(false)}}
                isOpen={showNoteModal}
                onSaveSuccess={(participant) =>{
                    let participants = [...registrants]
                    let i = participants.findIndex(p=>p.id===participant.id)
                    participants[i].note = participant.note
                    setRegistrants(participants)
                    setShowNoteModal(false)
                }}/>
            <RegistrantInspectorDialog show={registrantInspectorOpen}
                                       registrant={activeRegistrant}
                                       handleClose={() => setRegistrantInspectorOpen(false)}
                                       onAccept={async () => {
                                           setIsLoadingBar(true)
                                           try {
                                               let participationModel = new Participation()
                                               await participationModel.accept(activeRegistrant.id)
                                               getParticipants()
                                               setIsLoadingBar(false)
                                               setRegistrantInspectorOpen(false)
                                           } catch (e) {
                                               console.log(e)
                                               setIsLoadingBar(false)
                                           }

                                       }}
                                       onReject={async (registrant) => {
                                           setRejectionDialogOpen(true)
                                           setRejectedRegistrant(registrant)
                                           setRegistrantInspectorOpen(false)
                                       }}
                                       onSendScenarioEmail={async (scenario) =>{
                                           setIsLoadingBar(true)
                                           try{
                                               let participationModel = new Participation()
                                               await participationModel.sendScenarioEmail(activeRegistrant.id, scenario)
                                               alert("Pengiriman email berhasil!")
                                               setRegistrantInspectorOpen(false)
                                           } catch (e) {
                                               console.log(e)
                                               alert("Sebuah kesalahan terjadi. Periksa console.")
                                           }
                                           setIsLoadingBar(false)
                                       }}
                                       activeSession={activeSession}
            />

            <RegistrantRejectionDialog show={rejectionDialogOpen}
                                       registrant={rejectedRegistrant}
                                       handleClose={() => setRejectionDialogOpen(false)}
                                       onReject={async () => {
                                           setIsLoadingBar(true)
                                           try {
                                               let participationModel = new Participation()
                                               await participationModel.reject(activeRegistrant.id, rejectionMessage)
                                               getParticipants()
                                               setIsLoadingBar(false)
                                               setRegistrantInspectorOpen(false)
                                               setRejectionDialogOpen(false)
                                           } catch (e) {
                                               console.log(e)
                                               setIsLoadingBar(false)
                                           }
                                       }}
                                       onAbort={() => {
                                           setRejectionDialogOpen(false);
                                           setRejectedRegistrant({})
                                       }}
                                       onReasonChange={(reason) => {
                                           setRejectionMessage(reason)
                                       }}
                                       activeSession={activeSession}
            />
            <span>
                Peserta Sesi
            </span>

            <b style={{
                color: Palette.SECONDARY,
                fontSize: "2em",
                fontFamily: "Montserrat"
            }}>
                {activeEvent.name}
            </b>
            <div style={{marginBottom: "1em"}}>
                <span>
                    <FaUserFriends/>&nbsp; Peserta Sesi : {categorizedRegistrants.waiting.length + categorizedRegistrants.accepted.length}{activeSession.quota > 0 ? `/${activeSession.quota}` : null}
                </span>
                <div>
                    <br/>
                    <b>Ringkasan</b>
                    <br/>

                    <div style={{marginTop: 4}}>
                        <div style={{marginLeft: 5}}>Terdaftar
                            : {categorizedRegistrants.waiting.length + categorizedRegistrants.accepted.length}</div>
                        <div style={{marginLeft: 5}}>Telah Bayar : {categorizedRegistrants.accepted.length}</div>
                        <div style={{marginLeft: 5}}>Pemasukan :
                            Rp{textFormatter.moneyFormatter(totalIncome)}</div>
                    </div>

                </div>
            </div>

            <Row
                style={{
                    marginBottom: "3em",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                <CSVLink
                    style={{marginLeft: "1em", marginRight: "1em", color: 'white'}}
                    separator={";"}
                    data={
                        categorizedRegistrants.accepted.map((obj, key) => {
                            console.log('categorized registrants', obj);
                            return {
                                timestamp : new moment(obj.accepted_at).format("MM/DD/YYYY HH:mm:ss"),
                                "Nama Lengkap" : obj.full_name,
                                "Organisasi" : obj.organization,
                                "Surel" : obj.email,
                                "Nomor Ponsel" : obj.phone_num ? `${obj.phone_num}` : "",
                                "Topik Pelatihan" : activeClass.name,
                                "Sumber Informasi" : obj.source,
                                "Kota" : obj.city,
                                "Nawala" : obj.subscribe_newsletter,
                                "Bukti Bayar" : "",
                                "Kuitansi" : "",
                                "#Daftar" : obj.id,
                                "Tanggal Daftar" :  new moment(obj.created_at).format("MM/DD/YYYY HH:mm:ss"),
                                "Biaya" : obj?.transaction?.amount ?  obj?.transaction?.amount : 0,
                            }
                        })
                    }
                    filename={`daftar-peserta.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    Download Semua Data&nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>

                <CSVLink
                    style={{marginRight: "1em", color: 'white'}}
                    separator={";"}
                    data={getNewsletterRegistrant()}
                    filename={`email-peserta.csv`}
                    className="btn btn-primary"
                    target="_blank"
                >
                    Download Data Untuk Email&nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>

                <div>
                    <Button
                        style={{
                            marginRight: 10,
                            color: "white"
                        }}
                        variant={"primary"}
                        onClick={() => {
                            setIsInviting(true)
                        }}
                    >
                        Undang Peserta&nbsp;&nbsp;<FaUserPlus/>
                    </Button>

                </div>

            </Row>


            {
                registrants.length > 0 ?
                    <>
                        <Tabs defaultActiveKey="all">
                            <Tab
                                eventKey="all"
                                title={
                                    "Semua"
                                }
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns} data={registrants} interactions={interactions}/>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="accepted"
                                title={"Diterima"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns} data={categorizedRegistrants.accepted}
                                              interactions={interactions}/>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="waiting"
                                title={"Menunggu Pembayaran"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns} data={categorizedRegistrants.waiting}
                                              interactions={interactions}/>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="rejected"
                                title={"Ditolak"}
                            >
                                <div style={{
                                    marginTop: 10
                                }}>
                                    <MobTable columns={columns} data={categorizedRegistrants.rejected}
                                              interactions={interactions}/>
                                </div>
                            </Tab>
                        </Tabs>

                    </>
                    :
                    <>
                        Data Pendaftaran tidak ditemukan
                    </>
            }

        </>
    }


    return (
        <>
            <InviteUserModal
                isOpen={isInviting}
                onClose={() => {
                    setIsInviting(false)
                    getParticipants()
                }}
                eventUrl={event_url}
            />
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"user-management"}
            >
                {renderContent()}
            </EditorPageWrapper>
        </>

    );

}
