import React, {Component, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useCookies} from 'react-cookie';
import GlobalData from "../../../util/GlobalData";
import {useHistory, useParams} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import EditorPageWrapper from "./EditorPageWrapper";
import Event, {PUBLISH_STATUS} from "../../../models/Event";
import {Card, Col, Container, Row} from "react-bootstrap";
import textFormatter from "../../../util/textFormatter";
import Palette from "../../../util/Palette";
import {FaUserFriends, FaEye, FaPlus} from "react-icons/all";
import NewViewEventDialog from "../../reusable/modals/NewViewEventDialog";
import MobTable from "../../reusable/Table/MobTable";
import moment from "moment";
import EventEditor from "../../reusable/EventEditor";
import {animateScroll as scroll} from "react-scroll";
import DemoEventEditor from "../../reusable/DemoEventEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaTimes} from "react-icons/fa";
import Class from "../../../models/Class";

let previousEvent;
let previousSession;

export default function EditorMain(props) {

    let winWidth = window.innerWidth
    let [events, setEvents] = useState([])
    let [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false)
    const {event_url} = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [classObj, setClass] = useState({});

    const [currentEvent, setCurrentEvent] = useState({
        name: event_url ? event_url : "",
        iconUrl : "",
        shortDescription: "",
        eventRoute : "",
        additionalInformation : {
            registrationFormIntroduction : "",
        }
    })
    const [currentSession, setCurrentSession] = useState({
        name: "",
        speaker:"",
        quota: -1,
        price: 0,
        requirePayment: false,
        description: "",
        startDate : new Date(),
        startTime: new Date(),
        endTime: new Date(),
        imageUrl: "",
        onlineMode: true,
        streamUrl: "",
        onlinePayment: false,
        allowRegistration: true,
        selectedPaymentPreset : 0,
        additionalInformation : {
        }
    })
    const [registrationFields, setRegistrationFields] = useState([])

    const [isDataChanged, setIsDataChanged] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")


    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }
    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }


    useEffect(()=>{
        // getEventData()

        getClassByCode();
    },[])

    const getClassByCode = async () => {
        const classModel = new Class();
        const classInfo = await classModel.getByCode(event_url);

        setClass(classInfo)
    }

    // const getEventData = async function () {
    //
    //     setIsLoading(true)
    //
    //     const event = new Event();
    //     try {
    //
    //         const receivedEvent = await event.getEventByURL(event_url)
    //
    //         setIsLoading(false)
    //
    //         const receivedSession = receivedEvent.sessions[0]
    //
    //         let tempEvent = {...currentEvent}
    //         let tempSession = {...currentSession}
    //
    //         console.log("receivedEvent", receivedEvent)
    //
    //         tempSession = {
    //             ...receivedSession,
    //             id : receivedSession.id,
    //             longDescription: receivedSession.description,
    //             startDate: new Date(receivedSession.start_time),
    //             startTime: new Date(receivedSession.start_time),
    //             endTime: new Date(receivedSession.end_time),
    //             bannerUrl : receivedSession.image_url,
    //             price : receivedSession.price,
    //             quota: receivedSession.quota,
    //             speaker: receivedSession.session_speaker,
    //             online_payment: receivedSession.online_payment,
    //             selectedPaymentPreset : 0,
    //             additionalInformation : receivedSession.additional_information ? JSON.parse(receivedSession.additional_information) : {}
    //         }
    //
    //         tempEvent = {
    //             ...receivedEvent,
    //             id : receivedEvent.id,
    //             name : receivedEvent.name,
    //             iconUrl : receivedEvent.event_icon_url,
    //             startDate: new Date(receivedSession.start_time),
    //             startTime: new Date(receivedSession.start_time),
    //             endTime: new Date(receivedSession.end_time),
    //             shortDescription: receivedEvent.short_description,
    //             eventRoute: receivedEvent.event_url,
    //             sessions : tempSession,
    //             publishStatus : receivedEvent.publish_status,
    //             additionalInformation: receivedEvent.additional_information ? JSON.parse(receivedEvent.additional_information) : {}
    //         }
    //
    //
    //         if (tempSession.price === 0) {
    //             tempSession.selectedPaymentPreset = 0
    //         } else if (tempSession.online_payment && tempSession.price > 0) {
    //             tempSession.selectedPaymentPreset = 2
    //         } else if (!tempSession.online_payment && tempSession.price > 0) {
    //             tempSession.selectedPaymentPreset = 1
    //         }
    //
    //
    //         if (tempSession.additionalInformation.streamType){
    //             if(tempSession.additionalInformation.streamType === "Google Meet"){
    //                 tempSession.selectedStreamingMediaIndex = 1
    //             }
    //             else if(tempSession.additionalInformation.streamType === "Zoom"){
    //                 tempSession.selectedStreamingMediaIndex = 2
    //             }
    //             else{
    //                 tempSession.selectedStreamingMediaIndex = 3
    //             }
    //         }
    //
    //         let requiredVisitorInformation = JSON.parse(receivedEvent.required_visitor_information)
    //         console.log(requiredVisitorInformation)
    //         setRegistrationFields(requiredVisitorInformation)
    //
    //
    //         previousEvent = tempEvent
    //         previousSession = tempSession
    //
    //         console.log("done getting data", currentEvent)
    //
    //         setCurrentEvent({...tempEvent})
    //         setCurrentSession({...tempSession})
    //
    //
    //
    //     } catch (e) {
    //         console.log(e)
    //
    //         setIsLoading(false)
    //     }
    //
    // }

    const processSubmit = async () => {


        // setSuccessMessage("")
        // setErrorMessage("")
        //
        // console.log("curevent ", currentEvent, currentSession)
        //
        // if(currentEvent.publishStatus === PUBLISH_STATUS.OPEN || currentEvent.publishStatus === PUBLISH_STATUS.HIDDEN){
        //
        //     let prevStartDate = moment(previousSession.startDate)
        //     if(prevStartDate.isAfter(currentSession.startDate)){
        //         promptError("Tanggal dan Waktu untuk acara yang sudah dipublikasi tidak dapat dimajukan.")
        //         return
        //     }
        //
        //     let prevStartTime = moment(previousSession.startTime)
        //     if(prevStartTime.isAfter(currentSession.startTime)){
        //         promptError("Tanggal dan Waktu untuk acara yang sudah dipublikasi tidak dapat dimajukan.")
        //         return
        //     }
        //
        //     let prevQuota = previousSession.quota
        //     if(currentSession.quota < prevQuota){
        //         promptError("Kuota dari acara yang telah dipublikasi tidak dapat dikurangi.")
        //         return
        //     }
        //     // console.log("prev quota " + prevQuota, "curr quota " + currentSession.quota)
        //
        // }
        //
        // setErrorMessage("")
        //
        // if (!currentEvent.name) {
        //     promptError("Harap isi nama acara.")
        //     return
        // }
        //
        // if (!currentEvent.shortDescription.length > 200) {
        //     promptError("Deskripsi singkat acara tidak boleh melebihi 200 huruf.")
        //     return
        // }
        //
        // if (currentSession.startTime > currentSession.endTime) {
        //     promptError("Waktu selesai harus setelah waktu mulai.")
        //     return
        // }
        //
        // if (currentSession.quota < 0 || isNaN(currentSession.quota)) {
        //     promptError("Harap mengisi kuota acara.")
        //     return
        // }
        //
        // if (!currentSession.additionalInformation.streamType && !currentSession.additionalInformation.streamingURL){
        //     promptError("Harap mengisi lokasi Streaming.")
        //     return
        // }
        //
        // setIsLoadingBar(true)
        //
        // let eventParams = {
        //     id : currentEvent.id,
        //     name: currentEvent.name,
        //     short_description: currentEvent.shortDescription,
        //     description: currentEvent.longDescription,
        //     price :0,
        //     start_time: currentEvent.startTime,
        //     end_time: currentEvent.endTime,
        //     event_banner_url: currentSession.bannerUrl,
        //     event_icon_url: currentEvent.iconUrl,
        //     required_visitor_information: JSON.stringify(registrationFields),
        //     event_type: "WEBINAR",
        //     additional_information : currentEvent.additionalInformation,
        //     event_url: currentEvent.eventRoute ? currentEvent.eventRoute : currentEvent.name.toLowerCase().replace(/ /g, "-")
        // }
        //
        // let sessionParams = {
        //     id : currentSession.id,
        //     session_name: currentSession.name,
        //     session_speaker: currentSession.speaker,
        //     quota: currentSession.quota,
        //     price: currentSession.price,
        //     require_payment: currentSession.price > 0,
        //     description: currentSession.longDescription,
        //     startTime: currentSession.startTime,
        //     endTime: currentSession.endTime,
        //     image_url: currentSession.bannerUrl,
        //     online_mode: true,
        //     stream_url: "",
        //     additional_information : JSON.stringify(currentSession.additionalInformation),
        //     online_payment: currentSession.onlinePayment,
        //     allow_registration: true
        // }
        // // return console.log("woi", currentSession.bannerUrl)
        //
        // let event = new Event();
        //
        // try {
        //     let result = await event.updateSingleSessionEvent({
        //         event: eventParams,
        //         session: sessionParams
        //     })
        //     console.log(result)
        //
        //     previousEvent = {...currentEvent}
        //     previousSession = {...currentSession}
        //
        //     setIsLoadingBar(false)
        //     setIsDataChanged(false)
        //
        //     promptSuccess("Data telah tersimpan!")
        //
        // } catch (e) {
        //     console.log(e)
        //     setIsLoadingBar(false)
        //     promptError(e.msg ? JSON.stringify(e.msg) : "")
        // }


    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    return (
        <>
            <EditorPageWrapper
                activeTab={"detail"}
            >

                {renderAlertBox()}
                {renderSuccessBox()}

                <>
                    <div style={{
                        marginTop: "0.5em",
                        // marginBottom: "0.5em",
                        color: Palette.PRIMARY,
                        fontFamily: "OpenSans-Bold",
                        fontWeight: "800",
                        fontSize: "1.5em"
                    }}>Kelas</div>

                    <br/>

                    <DemoEventEditor
                        classObj={classObj}
                        setClass={setClass}
                        setSuccessMessage={msg=>promptSuccess(msg)}
                        setErrorMessage={(msg)=>promptError(msg)}
                        activeEvent={(msg)=>setCurrentEvent(msg)}
                        activeSession={(msg)=>setCurrentSession(msg)}
                        currentEvent={currentEvent}
                        currentSession={currentSession}
                        setCurrentEvent={(newEvent)=>setCurrentEvent(newEvent)}
                        setCurrentSession={(newSession)=>setCurrentSession(newSession)}
                        registrationInformation={registrationFields}
                        setRegistrationInformation={(rf)=>setRegistrationFields(rf)}
                        isLoading={isLoadingBar}
                        setIsLoadingBar={(isLoading)=>setIsLoadingBar(isLoading)}
                        processSubmit={()=>processSubmit()}
                        isDataChanged={isDataChanged}
                        setIsDataChanged={setIsDataChanged}
                    />
                </>
            </EditorPageWrapper>
        </>

    );

}
