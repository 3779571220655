import textFormatter from "../../util/textFormatter";
import React from "react";

export default function ImageThumbnail(props) {

    let name = props.name
    let iconUrl = props.iconUrl

    return <>
        {
            iconUrl ? <img style={{
                height: "2.5em",
                width: "2.5em",
                ...props.style,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
                borderWidth: 2,
                borderRadius: 2,
                marginRight: 10,
                marginBottom: 10,
                borderColor: '#eeeeee',
                borderStyle: 'dashed',
                backgroundColor: '#fafafa',
                color: '#bdbdbd',
                outline: 'none',
                fontSize: "1.5em",
                transition: 'border .24s ease-in-out'
            }} src={iconUrl}/> : <div style={{
                height: "2.5em",
                width: "2.5em",
                ...props.style,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: "center",
                borderWidth: 2,
                borderRadius: 2,
                marginRight: 10,
                marginBottom: 10,
                borderColor: '#eeeeee',
                borderStyle: 'dashed',
                backgroundColor: '#fafafa',
                color: '#bdbdbd',
                outline: 'none',
                fontSize: "1.5em",
                fontWeight: "bold",
            }}>{textFormatter.createInitial(name)}</div>
        }
    </>
}
