import React, {Component, useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useCookies} from 'react-cookie';
import GlobalData from "../../../util/GlobalData";
import {useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import EditorPageWrapper from "./EditorPageWrapper";
import Event from "../../../models/Event";
import Class from "../../../models/Class"
import {Button, Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import textFormatter from "../../../util/textFormatter";
import Palette from "../../../util/Palette";
import MobTable from "../../reusable/Table/MobTable";
import moment from "moment";
import CustomButton from "../../reusable/CustomButton";
import {Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import Topic from "../../../models/Topic";
import {FaSave} from "react-icons/fa";
import ManageTopicModal from "../../reusable/ManageTopicModal";
import CustomDropzone from "../../reusable/CustomDropzone";
import {IoMdEye, IoMdEyeOff} from "react-icons/io";
import {FaMailBulk} from "react-icons/all";

export default function EditorMain(props) {
    const classModel = new Class();
    const topicModel = new Topic();
    let winWidth = window.innerWidth
    let [events, setEvents] = useState([])
    let [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false)

    const [upcomingClass, setUpcomingClass] = useState([])
    const [upcomingClassWithoutDeleted, setUpcomingClassWithoutDeleted] = useState([])

    const [archiveClass, setArchiveClass] = useState([])
    const [archiveClassWithoutDeleted, setArchiveClassWithoutDeleted] = useState([])

    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isModalBtnHovered, setModalBtnHovered] = useState(false);

    let history = useHistory()
    const [isDelete, setDelete] = useState(false)
    const [selectedClass, setSelectedClass] = useState({});
    const [topics, setTopics] = useState([]);
    const [topicsWithoutDeleted, setTopicsWithoutDeleted] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState({});
    const [isManageTopicModalShown, setManageTopicModalShown] = useState(false);
    const [isTopicModalShown, setTopicModalShown] = useState(false);
    const [isDeletingTopic, setDeletingTopic] = useState(false);
    const [isDeletedClassShown, setDeletedClassShown] = useState(true);
    const [isDeletedShown, setDeletedShown] = useState(true);
    let [bannerUrl, setBannerUrl] = useState(null)

    const [isLoading, setIsLoading] = useState(false)

    let [iconLoading, setIconLoading] = useState("");

    useEffect(() => {
        // getAllEvents()
        getAllClass()
        getAllTopics()
    }, [])

    const getAllTopics = async () => {
        const topicList = await topicModel.getAll();

        const topicsWithoutDeletedTemp = [];

        topicList.forEach(topic => {
            if (topic.active) topicsWithoutDeletedTemp.push(topic)
        })

        setTopicsWithoutDeleted(topicsWithoutDeletedTemp);
        setTopics(topicList);
    }

    const deleteTopic = async () => {
        try {
            const result = await topicModel.delete(selectedTopic.code);

            if (result.success) {
                getAllTopics();
                setDeletingTopic(false);
                alert('Topik berhasil terhapus')
            }
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }
    }

    const undeleteTopic = async (code) => {
        try {
            const result = await topicModel.undelete(code);

            if (result.success) getAllTopics()
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }
    }

    const getAllClass = async () => {

        setIsLoading(true)

        try{
            const classList = await classModel.getAll();

            const classesWithoutDeletedTemp = [];

            const archiveAll = []
            const archiveNoDeleted =[]

            const upcomingAll = []
            const upcomingNoDeleted = []

            classList.forEach(classObj => {
                if (classObj.active) classesWithoutDeletedTemp.push(classObj)
            })

            for(let classObj of classList){

                classObj.acceptedCount = classObj.metrics?.accepted
                classObj.waitingCount = classObj.metrics?.waiting

                if(new Date(classObj.start_time) > new Date()){
                    if (classObj.active) {upcomingNoDeleted.push(classObj)}
                    upcomingAll.push(classObj)
                }else{
                    if (classObj.active) archiveNoDeleted.push(classObj)
                    archiveAll.push(classObj)
                }

            }

            let upcomingAllReversed = upcomingAll.reverse()
            let upcomingNDReversed = upcomingNoDeleted.reverse()

            setUpcomingClass(upcomingAllReversed)
            setUpcomingClassWithoutDeleted(upcomingNDReversed)

            console.log(archiveAll)

            setArchiveClass(archiveAll)
            setArchiveClassWithoutDeleted(archiveNoDeleted)
            setIsLoading(false)

        }catch(e){
            console.log(e)
            setIsLoading(false)
        }

    }

    const interactions = [
        {
            name: "Kelola",
            action: (obj) => {
                history.push("/editor/classDetail/" + obj.code)
            },
            style: {width: 135},
            condition: (classObj) => {
                return true
                // return classObj.active
            },
        },
        {
            name: "Partisipan",
            action: (obj) => {
                history.push("/editor/visitors/" + obj.code)
            },
            style: {width: 135},
            condition: (classObj) => {
                return true
                // return classObj.active
            },
        },
        {
            name: "Hapus",
            action: (obj) => {
                setSelectedClass(obj)
                setDelete(true)
            },
            variant: 'secondary',
            style: {width: 135},
            condition: (classObj) => {
                return classObj.active
            },
        },
        {
            name: "Pulihkan",
            action: (obj) => {
                setSelectedClass(obj)
                undeleteClass(obj)
            },
            variant: 'info',
            style: {width: 135},
            condition: (topic) => {
                return !topic.active
            },
        }
    ];

    const topicInteractions = [
        {
            name: "Sunting",
            action: (obj) => {
                setManageTopicModalShown(true)
                setSelectedTopic(obj);
            },
            style: {width: 100},
            condition: (topic) => {
                return topic.active
            },
            // condition: (topic) => {
            //     console.log(topic)
            //     return !topic.active
            // },
        },
        {
            name: "Hapus",
            action: (obj) => {
                setDeletingTopic(true);
                setSelectedTopic(obj)
            },
            variant: 'secondary',
            style: {width: 100},
            condition: (topic) => {
                return topic.active
            },
        },
        {
            name: "Pulihkan",
            action: (obj) => {
                undeleteTopic(obj.code)
            },
            variant: 'info',
            style: {width: 100},
            condition: (topic) => {
                return !topic.active
            },
        }
    ]

    let columns = [

        {
            Header: 'Code',
            accessor: 'code'
        },
        {
            Header: 'Session Name',
            accessor: 'name'
        },
        {
            Header: 'Speaker Name',
            accessor: 'speaker'
        },
        {
            Header: 'Date Held',
            accessor: 'class_date',
            Cell: (cellInfo) => {

                let rowInfo = new moment(cellInfo.row.values).format("MM/DD/YYYY");
                return <>{rowInfo}</>

            }
        },
        {
            Header: 'Start Time',
            accessor: 'start_time',
            Cell: (cellInfo) => {

                let rowInfo = new moment(cellInfo.row.values).format("MM/DD/YYYY HH:mm:ss");
                return <>{rowInfo}</>

            }
        },
        {
            Header: 'Price',
            accessor: 'price'
        },
        {
            Header: 'Registration',
            accessor: null
        },
    ];

    let topicColumn = [
        {
            Header: 'Kode',
            accessor: 'code',
        },
    ]

    const undeleteClass = async (classObj) => {
        try {
            const result = await classModel.undelete(classObj.code);

            console.log(result)

            if (result.success) {
                getAllClass()

                alert('Kelas ' + classObj.name + ' berhasil dipulihkan!')
            }
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }
    }

    const deleteClass = async () => {
        try {
            const result = await classModel.delete(selectedClass.code);

            console.log(result)

            if (result.success) {
                alert('Kelas ' + selectedClass.name + ' berhasil terhapus!')

                getAllClass()

                setDelete(false);
            }
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                alert(e.msg);
            } else {
                alert('Kesalahan terjadi');
            }
        }
    }

    const onBannerPicked = async function (image) {
        console.log(image)
        try {
            let result = await new Event().uploadBanner(image, 0)

            console.log(result)

            setBannerUrl(result.location)
        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"
            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            setIconLoading(false)

            console.log(e)
        }
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingFull={isLoading}
                activeTab={"home"}
            >
                <Dialog open={isTopicModalShown}
                        maxWidth="md"
                        fullWidth={true}
                        onClose={() => setTopicModalShown(false)}
                >
                    <DialogTitle>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                Kelola Topik

                                <Row style={{marginLeft: 2, marginTop: 15}}>
                                    <Button variant={'info'}
                                            onClick={() => setDeletedShown(!isDeletedShown)}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 30,
                                                fontSize: '.65em',
                                                fontFamily: 'OpenSans-SemiBold',
                                                // marginTop: 4,
                                                width: 200,
                                                color: 'white', marginRight: 5
                                            }}
                                    >
                                        {isDeletedShown ? <IoMdEyeOff style={{marginRight: 10}}/> :
                                            <IoMdEye style={{marginRight: 10}}/>}
                                        {isDeletedShown ? 'Sembunyikan Tersembunyi' : 'Tampilkan Tersembunyi'}
                                    </Button>

                                    <Button variant={'outline-primary'}
                                            onMouseEnter={() => setModalBtnHovered(true)}
                                            onMouseLeave={() => setModalBtnHovered(false)}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 30,
                                                fontSize: '.65em',
                                                fontFamily: 'OpenSans-SemiBold',
                                                color: isModalBtnHovered ? 'white' : Palette.PRIMARY,
                                                width: 150,
                                                marginRight: 5
                                            }}
                                            onClick={() => setManageTopicModalShown(true)}
                                    >
                                        + <span style={{marginLeft: 6}}>Tambah Baru</span>
                                    </Button>

                                    {/*<Button variant={'info'}*/}
                                    {/*        style={{*/}
                                    {/*            display: 'flex',*/}
                                    {/*            alignItems: 'center',*/}
                                    {/*            justifyContent: 'center',*/}
                                    {/*            height: 30,*/}
                                    {/*            fontSize: '.8em',*/}
                                    {/*            fontFamily: 'OpenSans-SemiBold',*/}
                                    {/*            // marginTop: 4,*/}
                                    {/*            width: 200,*/}
                                    {/*            color: 'white', marginRight: 5*/}
                                    {/*        }}*/}
                                    {/*>*/}
                                    {/*    <IoMdEye style={{marginRight: 10}}/>*/}
                                    {/*    Tampilkan Terhapus*/}
                                    {/*</Button>*/}
                                    {/*<Button variant={'outline-primary'}*/}
                                    {/*        onMouseEnter={() => setModalBtnHovered(true)}*/}
                                    {/*        onMouseLeave={() => setModalBtnHovered(false)}*/}
                                    {/*        style={{*/}
                                    {/*            display: 'flex',*/}
                                    {/*            alignItems: 'center',*/}
                                    {/*            justifyContent: 'center',*/}
                                    {/*            height: 30,*/}
                                    {/*            fontSize: '.65em',*/}
                                    {/*            fontFamily: 'OpenSans-SemiBold',*/}
                                    {/*            color: isModalBtnHovered ? 'white' : Palette.PRIMARY,*/}
                                    {/*            width: 140,*/}
                                    {/*            marginLeft: 2,*/}
                                    {/*            marginTop: 15*/}
                                    {/*        }}*/}
                                    {/*        onClick={() => setManageTopicModalShown(true)}*/}
                                    {/*>*/}
                                    {/*    + <span style={{marginLeft: 6}}>Tambah Baru</span>*/}
                                    {/*</Button>*/}
                                </Row>
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent>
                        <MobTable
                            columns={topicColumn}
                            data={isDeletedShown ? topics : topicsWithoutDeleted}
                            interactions={topicInteractions}
                        />
                    </DialogContent>
                </Dialog>


                <Dialog open={isDeletingTopic}
                        maxWidth="sm">
                    <>
                        <DialogTitle onClose={() => this.onClose()}>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Topik
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus topik <span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedTopic.name}</span>?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDeletingTopic(false)
                                    }}
                                    style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                    onClick={deleteTopic}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <ManageTopicModal visible={isManageTopicModalShown} hide={() => {
                    setSelectedTopic({})
                    setManageTopicModalShown(false)
                }} selectedTopic={selectedTopic} updateData={getAllTopics}/>

                <Dialog open={isDelete}
                        maxWidth="sm"
                        fullWidth={true}
                >
                    <>
                        <DialogTitle onClose={() => this.onClose()}>
                            <Row>
                                <Col style={{
                                    fontFamily: "OpenSans-Bold",
                                    color: Palette.PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    Konfirmasi Hapus Kelas
                                </Col>
                            </Row>
                            <hr/>
                        </DialogTitle>

                        <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                            Apakah anda yakin ingin menghapus kelas <span
                            style={{fontFamily: 'OpenSans-Bold'}}>{selectedClass.name}</span>?


                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: 30,
                                marginRight: 15,
                                marginBottom: 15
                            }}>
                                <CustomButton
                                    onClick={() => {
                                        setDelete(false)
                                    }}
                                    style={{fontFamily: 'OpenSans-SemiBold', width: 120}}
                                    variant={"outlined"}
                                    color="primary">
                                    Batal
                                </CustomButton>

                                <CustomButton
                                    style={{marginLeft: 10, fontFamily: 'OpenSans-SemiBold', width: 120}}
                                    onClick={deleteClass}
                                    variant={"contained"}
                                    color="secondary">
                                    Hapus
                                </CustomButton>
                            </div>
                        </DialogContent>
                    </>
                </Dialog>

                <>

                    <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                        <div style={{
                            color: Palette.PRIMARY,
                            fontSize: "1.75em",
                            fontFamily: "OpenSans-Bold",
                            marginRight: 25
                        }}>
                            Daftar Kelas
                        </div>

                        <Row style={{marginLeft: 2, marginTop: 15}}>
                            <Button variant={'info'}
                                    onClick={() => setDeletedClassShown(!isDeletedClassShown)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 30,
                                        fontSize: '.8em',
                                        fontFamily: 'OpenSans-SemiBold',
                                        // marginTop: 4,
                                        width: 200,
                                        color: 'white', marginRight: 5
                                    }}
                            >
                                {isDeletedClassShown ? <IoMdEyeOff style={{marginRight: 10}}/> :
                                    <IoMdEye style={{marginRight: 10}}/>}
                                {isDeletedClassShown ? 'Sembunyikan Terhapus' : 'Tampilkan Terhapus'}
                            </Button>

                            {/*}
                            <Button variant={'primary'}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 30,
                                        fontSize: '.8em',
                                        fontFamily: 'OpenSans-SemiBold',
                                        // marginTop: 4,
                                        width: 140,
                                        color: 'white'
                                    }}
                                    onClick={() => setTopicModalShown(true)}
                            >
                                Kelola Topik
                            </Button>
                            {*/}


                            <Button variant={'outline-primary'}
                                    onMouseEnter={() => setBtnHovered(true)}
                                    onMouseLeave={() => setBtnHovered(false)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 30,
                                        fontSize: '.8em',
                                        fontFamily: 'OpenSans-SemiBold',
                                        color: isBtnHovered ? 'white' : Palette.PRIMARY,
                                        width: 140,
                                        marginLeft: 5
                                    }}
                                    onClick={() => history.push("/create-class")}
                            >
                                + <span style={{marginLeft: 6}}>Buat Kelas Baru</span>
                            </Button>
                        </Row>

                        {/*<CustomButton*/}
                        {/*    onClick={() => setAdminManagementModalVisible(true)}*/}
                        {/*    style={{marginTop: 20, borderWidth: 0, fontFamily: 'OpenSans-SemiBold'}}*/}
                        {/*    variant={"outlined"}>+ Tambah Admin</CustomButton>*/}
                    </div>

                    {/*<div style={{display: 'flex', alignItems: 'center', marginBottom: 30}}>*/}
                    {/*    <div style={{*/}
                    {/*        marginTop: "1em",*/}
                    {/*        marginBottom: "0.5em",*/}
                    {/*        fontFamily: "OpenSans-Bold",*/}
                    {/*        flex: 1*/}
                    {/*    }}>Daftar Kelas</div>*/}

                    {/*    <CustomButton*/}
                    {/*        onClick={() => history.push("/create-event")}*/}
                    {/*        style={{marginTop: 20, borderWidth: 0, fontFamily: 'OpenSans-SemiBold', fontWeight: '500'}}*/}
                    {/*        variant={"outlined"}>+ Buat Kelas Baru</CustomButton>*/}
                    {/*</div>*/}

                    {/*<b style={{*/}
                    {/*    marginTop: "1em",*/}
                    {/*    marginBottom: "0.5em",*/}
                    {/*    fontFamily: "Montserrat"*/}
                    {/*}}>Daftar Kelas</b>*/}

                    <br/>


                    <Tabs defaultActiveKey="upcoming">
                        <Tab
                            eventKey="upcoming"
                            title={
                                "Akan Datang"
                            }
                        >
                            <div style={{
                                marginTop : 10
                            }}>
                                <MobTable
                                    columns={ columns}
                                    data={isDeletedClassShown ? upcomingClass : upcomingClassWithoutDeleted}
                                    interactions={interactions}
                                />
                            </div>
                        </Tab>
                        <Tab
                            eventKey="archive"
                            title={"Archive"}
                        >
                            <div style={{
                                marginTop : 10
                            }}>
                                <MobTable
                                    columns={columns}
                                    data={isDeletedClassShown ? archiveClass : archiveClassWithoutDeleted}
                                    interactions={interactions}
                                />
                            </div>
                        </Tab>
                    </Tabs>

                </>
            </EditorPageWrapper>
        </>

    );

}
