import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import Palette from "../../util/Palette";

export default class EditProfileModal extends Component {

    defaultRegisterState = {
        passwordMismatch: false,
        emailIsRegistered: false,
    }

    constructor(props) {
        super(props);

        console.log("active user", GlobalData.user)

        this.state = {
            user: {
                ...GlobalData.user
                // date_of_birth: "1997-01-01 13:00:00"
            },
            error: null,
            registerState: {
                ...this.defaultRegisterState
            },
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.show === false && this.props.show === true){
            console.log("will updte here")

            console.log("useerrr",  GlobalData.user)


            this.setState({
                user : {
                    ... GlobalData.user
                }
            })
        }
    }

    async handleSubmit() {

        this.setState({
            ...this.state,
            error: null
        })

        let userModel = new User()

        let param = {
            ...GlobalData.user,
            ...this.state.user
        }

        try{
            let result = await userModel.updateUser(param)
            console.log(result)
            if (this.props.customOnHide) this.props.customOnHide();

            alert("Profil Berhasil Dirubah")

        }catch(e){

            let msg = "Terjadi Kesalahan"

            if (e.msg) {
                if (e.code === "DUPLICATE_ACCOUNT") {
                    msg = "Email Telah Terdaftar"
                }
                this.setState({
                    error: "Email Telah Terdaftar"
                })
            }

            this.setState({
                ...this.state,
                error: msg
            })
            console.log(e)
        }




    }

    renderEditProfileBody() {

        return <> <p style={{
            fontSize: "0.8em"
        }}>
            Ubah Profil<br/>
        </p>
            <Form
                style={{
                    fontSize: "0.7em"
                }}>
                <Form.Group>
                    <Form.Label>Email <a
                        style={{color: "red", display: this.state.registerState.emailIsRegistered ? "inline" : "none"}}>(Email
                        Telah Terdaftar)</a></Form.Label>
                    <Form.Control
                        value={this.state.user.email}
                        onChange={evt => this.setState({
                            user: {
                                ...this.state.user,
                                email: evt.target.value
                            }
                        })}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Nama Lengkap</Form.Label>
                    <Form.Control
                        value={this.state.user.full_name}
                        onChange={evt => this.setState({
                            user: {
                                ...this.state.user,
                                full_name: evt.target.value
                            }
                        })}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Nomor Telfon</Form.Label>
                    <Form.Control
                        value={this.state.user.phone_num}
                        onChange={evt => this.setState({
                            user: {
                                ...this.state.user,
                                phone_num: evt.target.value
                            }
                        })}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>
            </Form>
        </>
    }

    render() {

        let props = this.props

        return <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.props.show}
            onHide={() => {
                this.setState({
                    error: null,
                    registerState: {...this.state.registerState, passwordMismatch: false, emailIsRegistered: false}
                })
                this.props.onHide();
            }}
        >
            <Modal.Header>
                {/*<Modal.Title>*/}
                <Container>
                    <Row>
                        <Col md={6} xs={6} style={{display: 'flex', alignItems: 'center'}}>
                            <img
                                style={{
                                    height: "4vh",
                                    objectFit: "contain",
                                }}
                                src={GlobalData.subfolder + "image/logo/patjarmerah.png"}/>
                        </Col>
                        <Col md={6} xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {props.customOnHide ? <p style={{fontWeight: 'bold', cursor: 'pointer', color: 'grey'}}
                                                     onClick={props.customOnHide}>x</p> : null}
                        </Col>
                    </Row>
                </Container>


                {/*</Modal.Title>*/}
            </Modal.Header>
            <Modal.Body>
                {this.renderEditProfileBody()}
                <span style={{
                    color: "red",
                    fontSize: "0.8em"
                }}>
                    {this.state.error ? this.state.error : props.message}
                </span>

            </Modal.Body>
            <Modal.Footer>
                <Container style={{padding: 0}}>
                    <Row>
                        <Col md={6} style={{marginTop: '10px'}}>

                        </Col>
                        <Col md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                style={{backgroundColor: Palette.PRIMARY, borderWidth: 0}}
                                onClick={() => this.handleSubmit()}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    }
}
