import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import React, {useEffect, useRef, useState} from "react"
import {TextField} from "@material-ui/core";
import {AiOutlineMail, AiOutlinePhone, AiOutlineHome, AiOutlineGift, AiOutlineUser} from "react-icons/ai";
import {FaBirthdayCake} from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Select from 'react-select';
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import {MdEvent, MdSchedule} from "react-icons/all";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import MomentUtils from '@date-io/moment';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Cookies from "universal-cookie";

export default function RegisterForm(props) {

    const {
        fields,
    } = props

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container>
                {
                    fields.map((obj, key) => {

                        let header = <>
                            <Col md={12}>
                                {obj.field}
                            </Col>
                            {
                                obj.description ?
                                    <Col
                                        style={{
                                            marginLeft: "0.5em",
                                            marginTop: "0.5em",
                                            marginBottom: "0.5em",
                                            color: "grey"
                                        }}
                                        md={12}>
                                        {obj.description}
                                    </Col>
                                    :
                                    null
                            }
                        </>

                        let body = <>
                            <Col md={6}>
                                <TextField
                                    fullWidth
                                    disabled={true}
                                    style={{
                                        marginLeft: "1em",
                                        marginTop: "0.5em",
                                    }}
                                    InputProps={{
                                        style: {
                                            fontSize: "1.2rem",
                                            borderWidth: 0,
                                            boxShadow: 'none',
                                            fontFamily: 'Poppins',
                                            borderRadius: 0,
                                        }
                                    }}
                                    placeholder={obj.field}/>
                            </Col>
                        </>


                        if (obj.type === "isian" || obj.type === "email") {
                            body = <>
                                <Col md={6}>
                                    <TextField
                                        disabled={true}
                                        fullWidth
                                        style={{
                                            marginLeft: "1em",
                                            marginTop: "0.5em",
                                        }}
                                        InputProps={{
                                            style: {
                                                fontSize: "1.2rem",
                                                borderWidth: 0,
                                                boxShadow: 'none',
                                                fontFamily: 'Poppins',
                                                borderRadius: 0,
                                            }
                                        }}
                                        placeholder={obj.field}/>
                                </Col>
                            </>
                        }

                        if (obj.type === "tanggal") {
                            body = <>
                                <Col md={6} xs={12}>
                                    <DatePicker
                                        fullWidth
                                        disabled={true}
                                        allowKeyboardControl={false}
                                        format="DD MMM YYYY"
                                        style={{
                                            marginLeft: "1em",
                                            marginTop: "0.5em",
                                        }}
                                        InputProps={{
                                            style: {
                                                fontSize: "1em",
                                                fontFamily: "Montserrat",
                                                cursor: "pointer"
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <MdEvent style={{color: 'black'}}/>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder={"Tanggal Mulai"}/>
                                </Col>
                            </>
                        }

                        if (obj.type === "checkbox") {
                            body = <>
                                {
                                    obj.options.map((opt, key) => {
                                        return <Col
                                            style={{
                                                marginLeft: "1em",
                                                marginTop: "0.5em"
                                            }}
                                            md={12} sm={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        disabled={true}
                                                        style={{
                                                            fontFamily: "Poppins",
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                fontSize: "1em",
                                                                color: "green"
                                                            },
                                                        }}
                                                        color="primary"
                                                        checked={false}
                                                        name={opt}/>}
                                                label={
                                                    <span
                                                        style={{fontFamily: "Poppins", fontSize: "1.2rem"}}>
                                                    {opt}
                                                </span>}
                                            />
                                        </Col>
                                    })
                                }

                            </>
                        }

                        if (obj.type === "option") {
                            body = <>
                                <div style={{height: "0.5em"}}/>
                                <RadioGroup>
                                    <Row style={{
                                        marginLeft: "0.5em",
                                        marginTop: "0.5em"
                                    }}>
                                        {
                                            obj.options.map((opt, key) => {
                                                return <Col
                                                    style={{
                                                        marginLeft: "1em",
                                                    }}
                                                    md={12} sm={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                disabled={true}
                                                                style={{
                                                                    fontFamily: "Poppins",
                                                                }}
                                                                InputProps={{
                                                                    style: {
                                                                        fontSize: "1em",
                                                                        color: "green"
                                                                    },
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton>
                                                                                <MdEvent style={{color: 'black'}}/>
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                color="primary"
                                                                value={opt}
                                                                name={opt}/>}
                                                        label={
                                                            <span
                                                                style={{fontFamily: "Poppins", fontSize: "1.2rem"}}>
                                                    {opt}
                                                </span>}
                                                    />
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </RadioGroup>

                            </>
                        }

                        return <Row style={{
                            fontSize: "1.2rem",
                            marginBottom: "2em",
                        }}>
                            {header}
                            {body}
                        </Row>

                    })
                }

            </Container>
        </MuiPickersUtilsProvider>
    )
}
