import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Admin {
    updateMyProfile = async (admin) => {
        return await ApiRequest.set("/v1/admin", "PUT", {
            email: admin.email,
            name: admin.name
        });
    }

    login = async (admin) =>{

        let userData = await ApiRequest.set("/v1/admins/login", "POST", {
            email : admin.email,
            password : admin.password
        });

        GlobalData.user = userData

        if(userData.token) {
            GlobalData.token = userData.token
        }

        return userData

    }

    tokenLogin = async()=>{
        let userData = await ApiRequest.set(`/v1/admins/tokenlogin`, "POST");
        GlobalData.user = userData
        // console.log(GlobalData.user)
        return userData
    }

    getAll = async () => {
        return await ApiRequest.set("/v1/admins", "GET");
    }

    createNew = async (admin) => {
        return await ApiRequest.set("/v1/admins/create", "POST", {
            email : admin.email,
            password : admin.password,
            role: admin.role,
            name: admin.name
        });
    }

    update = async (admin, id) => {
        return await ApiRequest.set(`/v1/admin/${id}`, "PUT", admin);
    }

    updatePassword = async (params, id) => {
        return await ApiRequest.set(`/v1/admin/password`, "PUT", {
            oldPassword : params.oldPassword,
            newPassword : params.newPassword
        });
    }

    ban = async (id) => {
        return await ApiRequest.set(`/v1/admin/${id}`, "DELETE");
    }

    unban = async (id) => {
        return await ApiRequest.set(`/v1/admin/${id}`, "PATCH");
    }
}
