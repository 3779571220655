import ApiRequest from "../util/ApiRequest";

export default class Question {
    getAll = async (code) => {
        return await ApiRequest.set(`/v1/class/${code}/question`, "GET");
    }

    addQuestion = async (code, question, participantId) => {

        return await ApiRequest.set(`/v1/class/${code}/question/ask`, "POST",{
            question : question,
            participant_id : participantId
        });
    }

    addQuestionWithFullName = async (code, question, askerName) => {

        return await ApiRequest.set(`/v1/class/${code}/question/ask`, "POST",{
            question : question,
            asker_name :askerName
        });
    }

    toggle = async (id) => {
        return await ApiRequest.set(`/v1/question/${id}/toggle`, "PUT");
    }

    updateQuestion = async(questionId, question) =>{
        return await ApiRequest.set(`/v1/question/${questionId}/answer`, "POST",question);
    }

    uploadQuestionCSV = async(file, classCode) =>{

        let formData = new FormData();

        // console.log('blob', file)
        formData.append('upload', file, file.name);

        return await ApiRequest.setMultipart(`/v1/class/${classCode}/question/upload`, "POST", formData);

    }

    commitQuestions = async (questions, classCode) =>{
        return await ApiRequest.set(`/v1/class/${classCode}/question/upload/commit`, "POST",questions);
    }

}
