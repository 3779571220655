import {Component, useEffect, useState} from "react";
import React from "react";
import Header from "../reusable/Header";
import LoginModal from "../reusable/LoginModal";
import {useCookies} from 'react-cookie';
import GlobalData from "../../util/GlobalData";
import User from "../../models/User"
import Footer from "../reusable/Footer";
import {
    isMobile
} from "react-device-detect";
import ForgotPasswordModal from "../reusable/ForgotPasswordModal";
import NewViewEventDialog from "../reusable/modals/NewViewEventDialog";

import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Palette from "../../util/Palette";
import {IconContext} from "react-icons";
import CustomDrawer from "../reusable/CustomDrawer";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import EditorHeader from "../reusable/EditorHeader";

export default function PageWrapper(props) {

    const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
    const [loginShow, setLoginShow] = useState(false);
    const [cookie, setCookie, removeCookie] = useCookies(["token"])
    const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)
    const [userData, setUserData] = useState(GlobalData.user);
    let [scrollPosition, setScrollPosition] = useState(0)
    let [isSideBarOpen, setIsSideBarOpen] = useState(false)

    let doLogin = async () => {
        console.log("do login is triggered")
        if (!GlobalData.token && cookie.token) {
            console.log("There is Token")
            GlobalData.token = cookie.token
            let userModel = new User()
            let user = await userModel.tokenLogin()

            setUserData(user)

        }
    }

    useEffect(() => {
        doLogin()
    }, [cookie.token])

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: Palette.PRIMARY,
                secondary : Palette.SECONDARY
            }
        },
    });

    useEffect(() => window.addEventListener('scroll', listenToScroll))

    useEffect(() => {
        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, []);

    function listenToScroll() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        setScrollPosition(scrolled)

        // console.log("how to scroll " + scrolled)
    }

    const renderFooter = () => {
        return <>
            <div style={{
                flex :1,
                width : "100%",
                height: "16em",
                backgroundImage: `url(${require("../../asset/footer-bg.png")})`,
                backgroundPosition: "center top",
                backgroundSize: "cover",
                // backgroundPosition: "center",
            }}
            >

                <Container style={{
                    height: "100%"
                }}>

                    <Row style={{
                        height: "100%",
                        paddingBottom: "1em"
                    }}>
                        <Col
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                height: "100%",
                            }}
                            md={12}
                        >
                            <style>
                                {
                                    `
                                            a{
                                                color : white
                                            }
                                            a:hover {
                                                color: ${Palette.PRIMARY};
                                            }
                                            `
                                }
                            </style>
                            <div style={{
                                fontFamily: "Poppins",
                                color: "white",
                                textAlign: "center",
                            }}>
                                Powered By
                                <a
                                    href={"https://intellivent.id"}>
                                    <b style={{
                                        fontFamily: "Spartan",
                                        fontWeight: "900",
                                        fontSize: "1.2em"
                                    }}>
                                        &nbsp;INTELLIVENT
                                    </b>
                                </a>
                            </div>

                        </Col>
                    </Row>

                </Container>

            </div>
        </>
    }

    // console.log("scpstn " + (scrollPosition * -150))

    return (
        <ThemeProvider theme={theme}>
            <IconContext.Provider value={{style: {verticalAlign: 'middle'}}}>
                <div
                    style={{
                        // backgroundImage : `url(${require("../../asset/try-4.png")})`,
                        // backgroundSize : "cover",
                        // backgroundAttachment: "fixed",
                        backgroundPosition: `0px ${scrollPosition * 150}px`,
                        backgroundImage: isMobile ? null : `url(${require('../../asset/cblgy.png')})` ,
                        // backgroundImage:`url(${require('../../asset/cblgi.png')})`,
                        backgroundSize: "cover",
                        paddingTop: isMobile ? 0 : '22x',
                        minHeight : "100vh",
                        display :"flex",
                        flexDirection : "column"
                    }}>

                    {
                        GlobalData.user ?
                            <EditorHeader
                                // active={props.headerActive}
                                // activeTab={props.activeTab}
                                isSideBarOpen={isSideBarOpen}
                                setIsSideBarOpen={setIsSideBarOpen}
                            />
                            :
                            <Header
                                shy={props.headerShy}
                                isLoggedIn={isLoggedIn}
                                active={props.headerActive}
                            />
                    }


                    <div
                        style={{
                            height: props.headerShy ? 0 : "8em",
                            fontFamily: "Poppins"
                        }}
                    />
                    {
                        props.children
                    }

                    <div style={{height: "10em"}}/>

                    {renderFooter()}
                </div>
            </IconContext.Provider>
        </ThemeProvider>
    );

}
