import {Button, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import React from "react";
import {FaInfoCircle} from "react-icons/fa";

export default function MiniTooltip(props){

    let parentProps = props

    function renderTooltip(props) {
        return (
            <Popover id="popover-basic" {...props}>
                {
                    props.title ? <Popover.Title as="h3">{parentProps.title}</Popover.Title> : null
                }
                <Popover.Content>
                    {parentProps.content}
                </Popover.Content>
            </Popover>
        );
    }

    return <OverlayTrigger
        placement={props.position?props.position : "right"}
        delay={{ show: 250, hide: 100 }}
        overlay={renderTooltip}
    >
        {
            props.children ?
                props.children
                :
                <FaInfoCircle style={{
                    color : "grey",
                    verticalAlign : "middle",
                    ...props.containerStyle
                }}/>
        }

    </OverlayTrigger>

}
