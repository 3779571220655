import ApiRequest from "../util/ApiRequest";

export default class Users {

    update = async(param) => {
        return await ApiRequest.set("/v1/members", "POST", param);
    }

    activate = async(id) => {
        return await ApiRequest.set(`/v1/member/${id}/activate`, "PUT");
    }

    getAll = async () => {
        return await ApiRequest.set("/v1/users", "GET");
    }

    memberSearch = async (searchquery) =>{
        return await ApiRequest.set("/v1/members/search", "POST", {search:searchquery})
    }

    delete = async(id) => {
        return await ApiRequest.set("/v1/members", "DELETE", {id : id});
    }

    unDelete = async(id) => {
        return await ApiRequest.set("/v1/members", "PATCH", {id : id});
    }

    getMemberParticipation = async (id) =>{
        return await ApiRequest.set(`/v1/member/${id}/participation`, "GET");
    }

    getParticipation = async () => {
        return await ApiRequest.set("/v1/participant", "GET");
    }

}
