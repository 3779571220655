import React, {useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
} from "@material-ui/core";

import {useHistory} from "react-router-dom";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import MomentUtils from '@date-io/moment';

import Event from '../../../models/Event'
// import {ButtonGroup} from "react-bootstrap";
import Palette from "../../../util/Palette";
import CustomButton from "../CustomButton";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewEventDialog(props) {

    let [name, setName] = useState("")
    let [selectedPreset, setSelectedPreset] = useState(0)
    let history = useHistory()

    const onClose = () => {
        props.closeDialog()
    }

    const eventPreset = [
        {
            title: "Webinar",
            description: "Anda ingin mengadakan seminar ? Atau acara yang hanya melibatkan satu sesi ? Template ini cocok untuk anda!"
        },
        // {
        //     title: "Acara Parallel",
        //     description: "Acara mu besar dan memiliki beberapa sesi? Template ini dapat mengakomodasi banyak acara dan pendaftaran untuk masing-masing sesi!"
        // }
    ]

    const changeDescription = (index) => {
        setSelectedPreset(index)
    }

    const renderModalFooter = () => {

        return <DialogActions>

            {/*<div style={{*/}
            {/*    position : "absolute",*/}
            {/*    left : 16*/}
            {/*}}>*/}
            {/*    Step {this.state.step} of 2*/}
            {/*</div>*/}
            <CustomButton
                color="primary"
                onClick={() => {
                    onClose()
                }}>
                Batal
            </CustomButton>
            <CustomButton
                color="primary"
                disabled={name.length === 0}
                onClick={() => {
                    history.push({
                        pathname: "/create-event",
                        state: {
                            event_name: name
                        }
                    })
                }}>
                Lanjutkan
            </CustomButton>
        </DialogActions>
    }

    const {show} = props;

    return (
        <Dialog
            open={show}
            maxWidth="sm"
            fullWidth={true}
            onClose={() => onClose()}
            TransitionComponent={Transition}>
            <>
                <DialogTitle onClose={() => this.onClose()}>
                    <span style={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold"
                    }}>Lengkapi Data Acara Mu !</span>
                </DialogTitle>
                <DialogContent>
                    <div style={{
                        fontFamily : "Poppins"
                    }}>

                        <Grid container spacing={4}>
                            <Grid item xs={11}>
                                <TextField
                                    inputProps={{
                                        style: {fontSize: "2em", fontFamily :"Montserrat", fontWeight : "bold"}
                                    }}
                                    value={name}
                                    onChange={evt => setName(evt.target.value)}
                                    onBlur={evt => setName(evt.target.value)}
                                    placeholder="Nama Acara"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{
                            marginTop: "0.5em"
                        }}>
                            <Grid item xs={12}>
                                Pilih Template Acara :
                            </Grid>
                            <Grid item xs={12}>
                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    {
                                        eventPreset.map((obj, key) => {
                                            return <CustomButton
                                                onMouseDown={e => e.preventDefault()}
                                                onClick={() => {
                                                    changeDescription(key)
                                                }}
                                                variant={selectedPreset === key ? "contained" : "outlined"}>{obj.title}</CustomButton>

                                        })
                                    }
                                </ButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    eventPreset[selectedPreset].description
                                }
                            </Grid>
                        </Grid>

                    </div>

                </DialogContent>
                {renderModalFooter()}
            </>
        </Dialog>
    );
}

