import React, {useEffect, useState} from "react";
import Email from "../../models/Email";
import Palette from "../../util/Palette";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import Select from "react-select";
import textFormatter from "../../util/textFormatter";
import CustomButton from "./CustomButton";
import LagFreeTextEditor from "./LagFreeTextEditor";
import FileUpload from "./FileUpload";
import {MdSend} from "react-icons/all";
import {animateScroll as scroll} from "react-scroll";
import EditorPageWrapper from "../page/editor/EditorPageWrapper";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaTimes} from "react-icons/fa";
import Session from "../../models/Session";
import Member from "../../models/Users";
import Class from "../../models/Class";
import {datetime} from "react-table/src/sortTypes"
import RichText from "./RichText";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";

let richTextEditor;

export default function EmailBroadcast(props){


    //Prompt Params
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    //Send Email State
    const [recipients, setRecipients] = useState([])
    const [registrants, setRegistrants] = useState([])
    const [emailSubject, setEmailSubject] = useState("")
    const [emailContent, setEmailContent]= useState("")

    const [attachments, setAttachments] = useState([])
    const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)

    const [absentMembers, setAbsentMembers] = useState([])

    const {isLoadingBar, setIsLoadingBar, classCode} = props

    const processSubmit = async() =>{

        setIsLoadingBar(true)
        let newEmailContent = emailContent

        if(richTextEditor){
            // console.log("This exist")
            // console.log(richTextEditor?.editor.props.editorState)
            newEmailContent = draftToHtml(convertToRaw(richTextEditor?.editor.props.editorState.getCurrentContent()))
            setEmailContent(newEmailContent)

        }

        newEmailContent = newEmailContent.replace(/(<p><\/p>)+/g, "<br>")

        console.log(newEmailContent)

        let email = new Email();

        if(recipients.length === 0){
            setIsLoadingBar(false)
            return promptError("Harap masukan tujuan pengiriman Email")
        }

        if(emailSubject.length === 0){
            setIsLoadingBar(false)
            return promptError("Harap mengisi subyek dari Email.")
        }

        if(newEmailContent.length === 0){
            setIsLoadingBar(false)
            return promptError("Isi Email tidak boleh kosong.")
        }

        try {

            let formattedRecipients = recipients.map((obj,key)=>{
                return obj.value
            })

            let formattedAttachments = []
            if(attachments){
                formattedAttachments = attachments.map((obj,key)=> {
                    return {
                        filename : obj.label,
                        path : obj.value
                    }
                })
            }


            let result = await email.sendBatch({
                "recipients": formattedRecipients,
                "subject": emailSubject,
                "content": newEmailContent,
                "attachments" : formattedAttachments
            })

            setRecipients([])
            setEmailContent("")
            setEmailSubject("")

            console.log(result)
            setIsLoadingBar(false)
            setErrorMessage("")

            promptSuccess("Email Telah Diproses. Pada umumnya email akan terkirim dalam beberapa menit. Jika sudah beberapa jam dan email belum terkirim, harap hubungi tim kami.")

        }catch(e){
            console.log(e)
            promptError(e.msg ? JSON.stringify(e.msg) : "Terjadi Kesalahan")
            setIsLoadingBar(false)
            // promptError(JSON.parse(e));
        }
    }

    useEffect(()=>{
        if(registrants.length === 0){
            getRegistrations()
        }
    },[])

    const getRegistrations = async function () {

        setIsLoadingBar(true)

        try {

            console.log(classCode, classCode||false)

            if(classCode){

                let classModel = new Class()
                let result = await classModel.getClassParticipant(classCode)

                let filteredMembers = result.filter((obj,key)=>{
                    return obj.status === "ACCEPTED"
                })

                console.log(result)

                let absentMembers = []

                let temp = filteredMembers.map((obj, key) => {

                    let member = obj.member

                    if(!obj.is_present){
                        absentMembers.push({
                            value : member["email"],
                            label : member["full_name"] + " | " +  member["email"]
                        })
                    }

                    return {
                        value : member["email"],
                        label : member["full_name"] + " | " +  member["email"]
                    }
                })

                setRegistrants(temp)
                setAbsentMembers(absentMembers)
                setIsLoadingBar(false)

            }else{
                /**
                const result = await new Member().getAll()

                console.log(result)
                let temp = result.map((obj, key) => {

                    return {
                        value : obj["email"],
                        label : obj["full_name"] + " | " +  obj["email"]
                    }
                })

                 setRegistrants(temp)
                **/

                setIsLoadingBar(false)


            }



        } catch (e) {
            console.log(e)
            setIsLoadingBar(false)
        }

    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    const handleChange = (newValue, actionMeta) => {
        if(newValue===null){
            return setRecipients([])
        }
        setRecipients(newValue)
    };

    let lastSearchRequest = 0;
    const handleKeyDown = (e) =>{
        //console.log("Value", e.target.value)
        //If there is no class code, it will search through all members of Sinara
        if(!classCode || classCode === "") {
            console.log("Handle key down! classCode must be empty", classCode)
            let searchQuery = e.target.value
            if(searchQuery && searchQuery !== "") {
                let requestTime = Date.now()
                lastSearchRequest = requestTime
                searchMember(searchQuery, requestTime)
                    .then(result =>{
                        //Only check when the request is newer
                        if(result.requestTime >= lastSearchRequest) {
                            setRegistrants(result.recipients)
                        }

                    })
                    .catch(error=>{
                        console.error(error)
                    })
            }  else {
                setRegistrants([])
            }

        }

    }

    const handleAttachmentChanges = (newValue) =>{
        setAttachments(newValue)
    }

    const searchMember = async (searchQuery, requestTime) =>{
        if(!searchQuery || searchQuery === "") {
            return []
        }

        const result = await new Member().memberSearch(searchQuery)

        let temp = result.map((obj, key) => {
            return {
                value : obj["email"],
                label : obj["full_name"] + " | " +  obj["email"]
            }
        })
        return {
            requestTime,
            recipients: temp
        }

    }

    const renderBroadcastEmailBody = () => {

        const addAttachment = async function(addedFile){

            setIsUploadingAttachment(true)

            try {

                console.log(addedFile)
                let result = await new Email().addAttachment(addedFile[0])

                //
                console.log(result)

                let temp = [...attachments]

                temp.push({
                    value : result.location,
                    label : addedFile[0].name
                })

                setAttachments(temp)
                setIsUploadingAttachment(false)

            } catch (e) {
                let tempMessage = "Kesalahan Terjadi"

                if (e.msg) {
                    if (e.msg.message) {
                        tempMessage = e.msg.message
                    }
                }
                promptError(tempMessage)
                console.log(e)

                setIsUploadingAttachment(false)

            }

        }

        const addRegistrantsToRecipients=()=>{

            let temp = [...recipients]

            console.log("initial", temp)

            registrants.forEach((obj)=>{
                if(!temp.find((o)=>{return o.value===obj.value})){
                    temp.push(obj)
                }
            })

            setRecipients(temp)
        }

        const addAbsentRegistrants=()=>{

            let temp = [...recipients]

            console.log("initial", temp)

            absentMembers.forEach((obj)=>{
                if(!temp.find((o)=>{return o.value===obj.value})){
                    console.log(obj)
                    if(!obj.is_present){
                        temp.push(obj)
                    }
                }
            })

            setRecipients(temp)

        }

        return <>
            <div style={{
                color: Palette.PRIMARY,
                fontSize: "1.75em",
                fontFamily: "OpenSans-Bold",
                marginRight: 25
            }}>Kirim Email</div>

            <p style={{
                marginTop: "1em"
            }}>
                Tujuan Email :
            </p>

            <div style={{
                marginLeft: "1em",
                display: "flex",
                flexDirection: "column"
            }}>

                <CreatableSelect
                    isMulti
                    formatCreateLabel={(inputValue) => {
                        return "Tambah Tujuan: " + inputValue
                    }}
                    value={recipients}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    options={registrants}
                    disabled={isLoadingBar}
                />

                <span style={{
                    marginTop : "0.5em"
                }}>
                    {classCode && <CustomButton
                        style={{
                            fontSize : "0.8em",
                            fontFamily : "Poppins",
                            textTransform : "none"
                        }}
                        onClick={()=>addRegistrantsToRecipients()}
                        variant={"outlined"}>
                    + Tambah Peserta Terdaftar
                    </CustomButton>}
                </span>

                <span style={{
                    marginTop : "0.5em"
                }}>
                    {classCode && <CustomButton
                        style={{
                            fontSize : "0.8em",
                            fontFamily : "Poppins",
                            textTransform : "none"
                        }}
                        onClick={()=>addAbsentRegistrants()}
                        variant={"outlined"}>
                    + Tambah Peserta Absen
                    </CustomButton>}
                </span>
            </div>


            <p style={{
                marginTop: "1em"
            }}>
                Subyek Email :
            </p>

            <div style={{
                marginLeft: "1em",
            }}>
                <LagFreeTextEditor
                    disabled={isLoadingBar}
                    inputProps={{
                        style: {
                            fontFamily: "Montserrat"
                        }
                    }}
                    size={'small'}
                    fullWidth={true}
                    variant={"outlined"}
                    value={emailSubject}
                    changeValue={value => setEmailSubject(value)}
                    placeholder={"Subyek Email"}
                    style={{
                        marginTop: 0,
                        marginBottom: 0,
                        fontFamily: "Montserrat"
                    }}/>

            </div>

            <p style={{
                marginTop: "1em"
            }}>
                Lampiran :
            </p>

            <div style={{
                marginLeft: "1em",
                display: "flex",
                flexDirection: "column"
            }}>

                <CreatableSelect
                    isMulti
                    formatCreateLabel={(inputValue) => {
                        return "Tambah Tujuan: " + inputValue
                    }}
                    onChange={handleAttachmentChanges}
                    onClick={()=>{
                        console.log("woei")
                    }}
                    menuIsOpen={false}
                    value={attachments}
                />

                <span style={{
                    marginTop : "0.5em"
                }}>
                    <FileUpload
                        isLoading={isUploadingAttachment}
                        onDrop={addAttachment}
                    />
                </span>

            </div>

            <p style={{
                marginTop: "1em"
            }}>
                Isi Email :
            </p>

            <div style={{
                marginLeft: "1em",
            }}>
                <RichText
                    richTextRef={ref=>richTextEditor=ref}
                    onBlurAndSave={setEmailContent} value={emailContent}/>
            </div>

        </>
    }

    const renderButtons = () => {
        return <div style={{
            position: "fixed",
            width : "100%",
            bottom: "0em",
            right: "0em",
            paddingTop : "1em",
            paddingBottom : "1em",
            paddingRight : "2em",
            zIndex: "300",
            boxShadow: "1px 1px 1px 1px #888888",
            background : "white",
            display : "flex",
            flexDirection: "row",
            justifyContent : "flex-end"
        }}>
            <CustomButton
                onClick={() => processSubmit()}
                variant={"outlined"} color="primary">
                Kirim&nbsp;<MdSend/>
            </CustomButton>
        </div>
    }

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    return <div style={{
        padding: "1em"
    }}>
        {renderAlertBox()}
        {renderSuccessBox()}
        {renderBroadcastEmailBody()}
        {renderButtons()}
    </div>

}
