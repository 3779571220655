import React, {useEffect, useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup, Select,
} from "@material-ui/core";

import {Col, Container, ListGroup, Modal, ModalBody, ModalTitle, Row} from "react-bootstrap";
import CustomButton from "../CustomButton";
import ModalHeader from "react-bootstrap/ModalHeader";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";

import {FaTimes, MdAdd, MdClear} from "react-icons/all";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Palette from "../../../util/Palette";
import OptionsFieldsEditor from "../OptionsFieldsEditor";
import LagFreeTextEditor from "../LagFreeTextEditor";
import {FaSave} from "react-icons/fa";
import MenuItem from "@material-ui/core/MenuItem";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormFieldModal(props) {

    let [fieldName, setFieldName] = useState("")
    let [description, setDescription] = useState("")
    let [options, setOptions] = useState([])
    let [correctAnswer, setCorrectAnswer] = useState(0)
    let [type, setType] = useState("")
    let [errorMessage, setErrorMessage] = useState("")

    let [selectedTypeIndex, setSelectedTypeIndex] = useState(0)

    let [activeIndex, setActiveIndex] = useState(null)
    const [isUpdate, setUpdate] = useState(false);

    let defaultState = {
        fieldName: "",
        description: "",
        type: "isian",
        selectedTypeIndex: 0
    }

    function isObjEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    useEffect(() => {
        setErrorMessage("")
        setUpdate(false)

        if (!isObjEmpty(props.selectedSurvey)) {
            setUpdate(true)

            setFieldName(props.selectedSurvey.fieldName)
            setDescription(props.selectedSurvey.description)
            setType(props.selectedSurvey.type)
            setCorrectAnswer(props.selectedSurvey.correctAnswer ? props.selectedSurvey.correctAnswer : 0)

            if (props.selectedSurvey.options) setOptions(props.selectedSurvey.options)

            fieldTypes.forEach((obj, index) => {
                if (obj.name === props.selectedSurvey.type) {
                    setSelectedTypeIndex(index)
                }
            })

        } else {
            setFieldName(defaultState.fieldName)
            setDescription(defaultState.description)
            setType(defaultState.type)
            setSelectedTypeIndex(defaultState.selectedTypeIndex)
            setOptions([])
        }

    }, [props.selectedSurvey])

    const onSubmit = () => {

        if (fieldName.length === 0) {
            setErrorMessage("Harap mengisi kolom pertanyaan")
            return
        }

        if (fieldTypes[selectedTypeIndex].optionType && options.length === 0) {
            setErrorMessage("Harap membuat minimal satu opsi jawaban")
            return
        }

        let submittedOption = fieldTypes[selectedTypeIndex].optionType ? options : null

        console.log("submitted option " + submittedOption, correctAnswer)
        props.onSubmit(fieldName, type, description, submittedOption, activeIndex, correctAnswer)
    }

    const fieldTypes = [
        {
            name: "isian",
            optionType: false
        },
        // {
        //     name: "tanggal",
        //     optionType: false
        // },
        // {
        //     name: "checkbox",
        //     optionType: true,
        //     extra: () => renderCheckBoxParams()
        // },
        {
            label: "Pilihan Ganda",
            name: "option",
            optionType: true,
            extra: () => renderCheckBoxParams()
        },
        // {
        //     name: "checkbox",
        //     optionType: true,
        //     extra: () => renderCheckBoxParams()
        // },
        // {
        //     name: "Kota",
        //     optionType: false,
        // }
    ]

    const renderCheckBoxParams = () => {
        return <>
            <OptionsFieldsEditor
                options={options}
                onChange={(options) => {
                    setOptions(options)
                }}
            />
            <Container>
                <Row style={{
                    fontSize: "0.9em",
                    marginTop: "1em",
                    fontFamily: 'OpenSans-SemiBold'
                }}>
                    Kunci Jawaban
                </Row>
            </Container>
            <Row style={{
                marginInlineStart: "4%",
                marginInlineEnd: "4%",
                marginTop: "1em"
            }}>
                <Select value={correctAnswer} onChange={e => {
                    console.log(e.target.value)
                    setCorrectAnswer(e.target.value)
                }}>
                    <MenuItem value={0} key={-1}>
                        Harap Pilih Kunci Jawaban
                    </MenuItem>
                    {options.map((option, key) => (
                        <MenuItem value={option} key={key}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Row>
        </>
    }

    const changeSelectedType = (index) => {
        setSelectedTypeIndex(index)
        setType(fieldTypes[index].name)
    }

    const renderAlertBox = () => {
        return <div style={{
            marginTop: -5,
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }


    const renderModalFooter = () => {

        return <DialogActions style={{marginBottom: 15, marginTop: 20, marginRight: 15}}>

            <CustomButton
                onClick={props.onClose}
                style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                variant={"outlined"} color="primary">
                Batal
            </CustomButton>

            <CustomButton
                onClick={onSubmit}
                style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                variant={"contained"} color="primary">
                <>Simpan&nbsp;&nbsp;<FaSave/></>
            </CustomButton>
            {/*<CustomButton*/}
            {/*    color="primary"*/}
            {/*    onClick={() => {*/}
            {/*        onClose()*/}
            {/*    }}>*/}
            {/*    Batal*/}
            {/*</CustomButton>*/}
            {/*<CustomButton*/}
            {/*    color="primary"*/}
            {/*    disabled={false}*/}
            {/*    onClick={() => {*/}
            {/*        onSubmit()*/}
            {/*    }}>*/}
            {/*    Tambah*/}
            {/*</CustomButton>*/}
        </DialogActions>
    }


    return (
        <Dialog
            open={props.show}
            maxWidth="sm"
            style={{
                fontFamily: "Montserrat"
            }}
            fullWidth={true}
            onClose={() => props.onClose()}
            TransitionComponent={Transition}>
            <>
                <DialogTitle>
                    <Row>
                        <Col style={{
                            fontFamily: "OpenSans-Bold",
                            // fontWeight: "bold",
                            display: 'flex',
                            alignItems: 'center',
                            color: Palette.PRIMARY
                        }}>
                            {isUpdate ? 'Sunting' : 'Tambah'} Pertanyaan
                        </Col>
                    </Row>
                    <hr/>
                </DialogTitle>

                <DialogContent>

                    <Container>

                        {renderAlertBox()}

                        <Row style={{
                            // marginTop: "0.5em",
                            fontSize: "0.9em"
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    title={'Pertanyaan'}
                                    changeValue={value => setFieldName(value)}
                                    value={fieldName}
                                    placeholder={"Pertanyaan"}/>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col md={12}>*/}

                        {/*        <TextField*/}
                        {/*            fullWidth*/}
                        {/*            multiline*/}
                        {/*            inputProps={{*/}
                        {/*                style: {*/}
                        {/*                    fontFamily: "Montserrat"*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*            value={fieldName}*/}
                        {/*            placeholder={"Cth: Bagaimana cara berkomunikasi dengan efektif?"}*/}
                        {/*            onChange={evt => setFieldName(evt.target.value)}*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row style={{
                            marginTop: "1em",
                            fontSize: "0.9em"
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    title={'Keterangan Tambahan'}
                                    changeValue={value => setDescription(value)}
                                    value={description}
                                    placeholder={"Keterangan Tambahan"}/>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col md={12}>*/}

                        {/*        <TextField*/}
                        {/*            fullWidth*/}
                        {/*            inputProps={{*/}
                        {/*                style: {*/}
                        {/*                    fontFamily: "Montserrat"*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*            value={description}*/}
                        {/*            placeholder={"Cth: Jawab pertanyaan ini sesuai dengan apa yang sudah diajarkan"}*/}
                        {/*            onChange={evt => setDescription(evt.target.value)}*/}
                        {/*        />*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row style={{
                            marginTop: "1em",
                            fontSize: "0.9em",
                            marginBottom: "0.5em"
                        }}
                        >
                            <Col md={12} style={{fontFamily: 'OpenSans-SemiBold'}}>Jenis Pertanyaan</Col>
                        </Row>
                        <Row style={{
                            marginInlineStart: "2%",
                            marginInlineEnd: "2%"
                        }}>
                            <ButtonGroup
                                style={{
                                    marginLeft: "0.5em"
                                }}
                                color="primary" aria-label="outlined primary button group">
                                {
                                    fieldTypes.map((obj, key) => {
                                        return <CustomButton
                                            onClick={() => {
                                                changeSelectedType(key)
                                            }}
                                            style={{
                                                fontFamily: "OpenSans-SemiBold",
                                                marginTop: 5
                                            }}
                                            variant={selectedTypeIndex === key ? "contained" : "outlined"}>{obj.label ? obj.label : obj.name}</CustomButton>

                                    })
                                }
                            </ButtonGroup>

                        </Row>

                        {
                            fieldTypes[selectedTypeIndex].extra ?
                                fieldTypes[selectedTypeIndex].extra()
                                :
                                null
                        }

                    </Container>

                </DialogContent>
                {renderModalFooter()}
            </>
        </Dialog>
    );
}

