import React, {useEffect, useState} from "react";

import {
    Dialog,
    TextField,
    Slide,
    Grid,
    Button,
    Stepper,
    StepLabel,
    Step,
    DialogContent,
    DialogActions,
    ButtonGroup,
} from "@material-ui/core";

import {DialogTitle} from "../Dialog"

import {Col, Container, ListGroup, Modal, ModalBody, ModalTitle, Row, Tabs} from "react-bootstrap";
import CustomButton from "../CustomButton";
import ModalHeader from "react-bootstrap/ModalHeader";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";

import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Palette from "../../../util/Palette";
import OptionsFieldsEditor from "../OptionsFieldsEditor";
import textFormatter from "../../../util/textFormatter";
import {FiAlignJustify, FiCalendar, FiClock, FiInfo, FiMapPin, FiTv} from "react-icons/fi";
import {TiTicket} from "react-icons/ti";
import Tab from "react-bootstrap/Tab";
import {FaClipboardList, FaMailBulk, FaPlaneArrival, FaMobileAlt, FaDesktop, FaTicketAlt} from "react-icons/all";
import DetailSection from "../DetailSection";
import RegisterForm from "../RegisterForm";
import moment from "moment";

const Transition = React.forwardRef(function Transitipaon(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewModal(props) {

    const {event, session, onClose, url} = props;
    const [mobileMode, setMobileMode] = useState(false)

    return (
        <Dialog
            open={props.show}
            maxWidth="lg"
            style={{
                fontFamily: "Montserrat"
            }}
            fullWidth={true}
            onClose={() => onClose()}
            TransitionComponent={Transition}>
            <>
                <DialogTitle onClose={()=>onClose()}>
                    Preview
                </DialogTitle>

                <ModalBody style={{
                    padding: 0
                }}>
                    <div style={{position : "absolute", right : "1em"}}>
                        <IconButton
                            onClick={()=>setMobileMode(true)}
                            onMouseDown={e => e.preventDefault()}>
                            <FaMobileAlt color={mobileMode ? Palette.PRIMARY :"grey"}/>
                        </IconButton>
                        <IconButton
                            onClick={()=>setMobileMode(false)}
                            onMouseDown={e => e.preventDefault()}>
                            <FaDesktop color={!mobileMode ? Palette.PRIMARY :"grey"}/>
                        </IconButton>
                    </div>
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                        <Tab
                            eventKey="home"
                            title={
                                <span style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                 <FaPlaneArrival/>&nbsp;Halaman Landing
                                </span>
                            }
                        >
                            <Container fluid>

                                <div style={{
                                    width : "100%",
                                    display : "flex",
                                    flexDirection : "column",
                                    alignItems  :"center"
                                }}>
                                    <iframe
                                        style={{
                                            width : mobileMode? "30%" : "100%",
                                            height : "75vh"
                                        }}
                                        src={props.url}/>
                                </div>

                            </Container>
                        </Tab>
                        <Tab
                            eventKey="registration"
                            title={
                                <span style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <FaClipboardList/>&nbsp;Form Registrasi
                                </span>
                            }
                        >
                            <div style={{
                                width : "100%",
                                display : "flex",
                                flexDirection : "column",
                                alignItems  :"center"
                            }}>
                                <iframe
                                    style={{
                                        width : mobileMode? "30%" : "100%",
                                        height : "75vh"
                                    }}
                                    src={props.url+"/register"}/>
                            </div>
                        </Tab>
                        <Tab
                            eventKey="ticket"
                            title={
                                <span style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <FaTicketAlt/>&nbsp;Halaman Tiket
                                </span>
                            }
                        >
                            <div style={{
                                width : "100%",
                                display : "flex",
                                flexDirection : "column",
                                alignItems  :"center"
                            }}>
                                <iframe
                                    style={{
                                        width : mobileMode? "30%" : "100%",
                                        height : "75vh"
                                    }}
                                    src={props.url+"/ticket"}/>
                            </div>
                        </Tab>
                    </Tabs>


                </ModalBody>
            </>
        </Dialog>
    );
}

