import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {FiAlignJustify, FiCalendar, FiClock, FiInfo, FiMapPin, FiTv} from "react-icons/fi";
import {TiTicket} from "react-icons/ti";
import moment from "moment";
import textFormatter from "../../util/textFormatter";

export default function DetailSection(props) {
    const {event, children, register} = props;
    const [isRegistered, setRegistered] = useState(false);

    return (
        <div>
            {/*<Row>*/}
            {/*    <Button onClick={() => history.push('/' + url + '/registration-success', {id: 5, fullName: 'Timotius Eka Valentino', email: 'eka.valentino@gmail.com'})}>abc</Button>*/}
            {/*</Row>*/}
            <Row style={{height: '50vh', overflow: 'hidden'}}>
                <img src={event.event_banner_url}
                     style={{width: '100%', filter: 'blur(15px)', transform: 'scale(2)'}}/>
            </Row>

            <Container style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                top: '-47vh',
                marginBottom: '-47vh'
            }}>
                <Row>
                    <img src={event.event_banner_url}
                         style={{
                             objectFit: 'cover',
                             width: '100%',
                             maxHeight: '45vh',
                             borderTopLeftRadius: 10,
                             borderTopRightRadius: 10,
                         }}/>
                </Row>

                <Row style={{
                    backgroundColor: 'white',
                    boxShadow: '0 1px 1px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.24)',
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 20,
                    paddingTop: 25,
                    marginBottom: 30,
                }}>
                    <Container>
                        <Row>
                            <Col md={register ? 12 : 9}>
                                <Row style={{
                                    fontFamily: 'Nunito Sans',
                                    fontWeight: '700',
                                    fontSize: `calc(.7vw + 1rem)`,
                                    marginLeft: 5,
                                }}>
                                    {event.name}
                                </Row>
                                <Row style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '300',
                                    marginLeft: 5,
                                }}>
                                    <div style={{
                                        whiteSpace: "pre-line",
                                    }}>
                                        {event.short_description}
                                    </div>
                                </Row>
                            </Col>
                            {register ? null :
                                <Col>
                                        <Button variant={'outline-dark'}
                                                disabled={true}
                                                style={{
                                                    width: '100%',
                                                    borderRadius: 0,
                                                    minHeight: '45px',
                                                    fontFamily: 'Poppins',
                                                    fontSize: 'calc(.1vw + 1rem)',
                                                    marginTop: 15,
                                                    marginBottom: 15,
                                                    borderColor: isRegistered || (event.sessions && !event.sessions[0].allow_registration) ? 'grey' : 'black'
                                                }}>
                                            Daftar
                                        </Button>
                                </Col>
                            }
                        </Row>
                    </Container>

                    <span style={{
                        width: '100%',
                        height: 2,
                        backgroundColor: '#f2f2f2',
                        marginTop: 18,
                        marginBottom: 30
                    }}/>

                    {children ? children :
                        <>
                            {/*<Row style={{marginBottom: 5}}>*/}
                            {/*    <Col xs={2} md={1}*/}
                            {/*         style={{*/}
                            {/*             display: 'flex',*/}
                            {/*             alignItems: 'center',*/}
                            {/*             justifyContent: 'flex-end',*/}
                            {/*             marginLeft: 2*/}
                            {/*         }}>*/}
                            {/*        <FiInfo/>*/}
                            {/*    </Col>*/}
                            {/*    <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '500'}}>*/}
                            {/*        Status Registrasi*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            {/*<Row>*/}
                            {/*    <Col xs={2} md={1} style={{*/}
                            {/*        display: 'flex',*/}
                            {/*        alignItems: 'center',*/}
                            {/*        justifyContent: 'flex-end',*/}
                            {/*        marginLeft: 2*/}
                            {/*    }}/>*/}
                            {/*    <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '300'}}>*/}
                            {/*        Menunggu Pembayaran*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            <Row style={{
                                // marginTop: 30,
                                marginBottom: 5
                            }}>
                                <Col xs={2} md={1}
                                     style={{
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'flex-end',
                                         marginLeft: 2
                                     }}>
                                    <FiTv/>
                                </Col>
                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '500'}}>
                                    URL Streaming
                                </Col>
                            </Row>

                            <Row style={{marginTop: 30, marginBottom: 5}}>
                                <Col xs={2} md={1}
                                     style={{
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'flex-end',
                                         marginLeft: 2
                                     }}>
                                    <TiTicket/>
                                </Col>
                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '500'}}>
                                    Harga Tiket
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={2} md={1} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginLeft: 2
                                }}/>
                                <Col style={{padding: 0}}>
                                    <div style={{fontFamily: 'Poppins', fontWeight: '300'}}>
                                        {event.price === 0 ? 'Gratis' : event.price ? 'Rp' + textFormatter.moneyFormatter(event.price) : null}
                                    </div>
                                </Col>
                            </Row>

                            {/*<Row>*/}
                            {/*    <Col style={{*/}
                            {/*        display: 'flex',*/}
                            {/*        alignItems: 'center',*/}
                            {/*        marginLeft: 15,*/}
                            {/*        marginRight: 15,*/}
                            {/*        marginTop: 30*/}
                            {/*    }}>*/}
                            {/*        <FiMapPin style={{marginRight: 15}}/>*/}
                            {/*        <div style={{fontFamily: 'Poppins', fontWeight: '500'}}>Lokasi</div>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            {/*<Row>*/}
                            {/*    <Col style={{*/}
                            {/*        display: 'flex',*/}
                            {/*        alignItems: 'center',*/}
                            {/*        marginTop: 5,*/}
                            {/*        marginLeft: 15,*/}
                            {/*        marginRight: 15*/}
                            {/*    }}>*/}
                            {/*        <FiTv style={{marginRight: 15, opacity: 0}}/>*/}
                            {/*        <div style={{fontFamily: 'Poppins', fontWeight: '300'}}>*/}
                            {/*            {props.location}*/}
                            {/*        </div>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            <Row style={{marginTop: 30, marginBottom: 5}}>
                                <Col xs={2} md={1}
                                     style={{
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'flex-end',
                                         marginLeft: 2
                                     }}>
                                    <FiCalendar/>
                                </Col>
                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '500'}}>
                                    Tanggal
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={2} md={1} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginLeft: 2
                                }}/>
                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '300'}}>
                                    {event.date}
                                </Col>
                            </Row>

                            <Row style={{marginTop: 30, marginBottom: 5}}>
                                <Col xs={2} md={1}
                                     style={{
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'flex-end',
                                         marginLeft: 2
                                     }}>
                                    <FiClock/>
                                </Col>
                                <Col style={{padding: 0}}>
                                    <div style={{fontFamily: 'Poppins', fontWeight: '500'}}>Pukul</div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={2} md={1} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginLeft: 2
                                }}/>
                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '300'}}>
                                    {event.time}
                                </Col>
                            </Row>

                            <Row style={{marginTop: 30, marginBottom: 5}}>
                                <Col xs={2} md={1}
                                     style={{
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'flex-end',
                                         marginLeft: 2
                                     }}>
                                    <FiAlignJustify/>
                                </Col>
                                <Col style={{padding: 0}}>
                                    <div style={{fontFamily: 'Poppins', fontWeight: '500'}}>Deskripsi</div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={2} md={1} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    marginLeft: 2
                                }}/>
                                <Col style={{paddingLeft: 0, fontFamily: 'Poppins', fontWeight: '300', whiteSpace: "pre-line",}}>
                                    {event.description}
                                </Col>
                            </Row>
                        </>
                    }
                </Row>
            </Container>
        </div>
    )
}
