import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {Button, Col, Container, Dropdown, ListGroup, Nav, Navbar, NavDropdown, Row, Spinner} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'chartjs-plugin-datalabels';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";

import {
    FaPlus,
    FaClock,
    FaPen,
    FaSave,
    FaTimes,
    FaTrash,
    FaCaretUp,
    FaRegClone,
    FaExternalLinkAlt
} from "react-icons/fa";
import EditorPageWrapper from "../editor/EditorPageWrapper";
import IconButton from "@material-ui/core/IconButton";

import * as Scroll from 'react-scroll';
import {animateScroll as scroll} from 'react-scroll'

import 'moment/locale/id'

import Event, {EVENT_STATUS, PAYMENT_TYPE, PUBLISH_STATUS} from "../../../models/Event"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Card from "react-bootstrap/Card";
import Palette from "../../../util/Palette";
import LineChart from "../../reusable/Charts/LineChart";
import Divider from "@material-ui/core/Divider";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CustomButton from "../../reusable/CustomButton";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import ImageThumbnail from "../../reusable/ImageThumbnail";
import {withStyles} from "@material-ui/core";
import moment from "moment";
import GlobalData from "../../../util/GlobalData";
import PieChart from "../../reusable/Charts/PieChart";
import Class from "../../../models/Class";
import {FaMoneyBillWave, FaUserFriends, FiAlertTriangle} from "react-icons/all";
import {Bar, HorizontalBar, Line} from "react-chartjs-2";
import Dashboard from "../../../models/Dashboard";

let anchor
let popperFadeTimer;

let currentDate = new moment();
currentDate.set({hour: 0, minute: 0, second: 0, millisecond: 0})

let dates = []
dates.push(currentDate.format("D MMMM"))

let formattedCurrentDate = currentDate.format("D MMMM")

for (let i = 0; i < 6; i++) {
    currentDate.subtract(1, "days")
    dates.push(currentDate.format("D MMMM"))
}


dates.reverse()

export default function EventDetail(props) {

    let history = useHistory()
    const {event_url} = useParams()

    //Page State
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [isPopperVisible, setIsPopperVisible] = useState(false)

    const [waitingCount, setIsWaitingCount] = useState(10)
    const [paymentType, setPaymentType] = useState("FREE")
    const [eventStatus, setEventStatus] = useState("FINISHED")

    const [activeSession, setActiveSession] = useState({})
    const [activeEvent, setActiveEvent] = useState({})
    const [activeEventURL, setActiveEventURL] = useState(event_url)

    const popPopper = () => {
        setIsPopperVisible(true)
        clearTimeout(popperFadeTimer)
        popperFadeTimer = setTimeout(() => {
            setIsPopperVisible(false)
        }, 1500)
    }

    //Page Params
    const [currentEvent, setCurrentEvent] = useState({
        name: event_url ? event_url : "",
        iconUrl: "",
        shortDescription: "",
        eventRoute: "",
        additionalInformation: {
            registrationFormIntroduction: "",
        },
        metrics: {}

    })
    const [currentSession, setCurrentSession] = useState({
        name: "",
        speaker: "",
        quota: -1,
        price: 0,
        requirePayment: false,
        description: "",
        startDate: new Date(),
        startTime: new Date(),
        endTime: new Date(),
        imageUrl: "",
        onlineMode: true,
        streamUrl: "",
        onlinePayment: false,
        allowRegistration: true,
        selectedPaymentPreset: 0,
        additionalInformation: {}
    })

    const [eventMetrics, setEventMetrics] = useState({
        categorizedRegistrantCount: {}
    })

    const [registrationFields, setRegistrationFields] = useState([])

    //Prompt Params
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const [upcomingClass, setUpcomingClass] = useState([])
    const [pastClass, setPastClass] = useState([])

    const routerLocation = useLocation()

    const [registrationGraph, setRegistrationGraph] = useState({});
    const [top5CitiesGraph, setTop5CitiesGraph] = useState({});
    const [ageDemographicData, setAgeDemographicData] = useState([]);

    useEffect(() => {
        scroll.scrollTo(0, {
            smooth: false,
            duration: 0
        })

        getAllClass()
        getData()
    }, [routerLocation.pathname])

    const getData = async () => {
        console.log('getting data')

        try {
            const dashboardModel = new Dashboard();
            const data = await dashboardModel.getData();
            const registrationLabel = [];
            const registrationValue = [];
            const registrationColor = [];

            console.log('dashboard_data', data);

            data.memberLoginData.map((member, idx) => {
                registrationLabel.push(moment(member.register_date).format('DD-MMM-YY'))
                registrationValue.push(member.register_count);
                if(moment(member.register_date).format('DD-MMM-YY') === moment().format('DD-MMM-YY')) {
                    registrationColor.push('#4673c8')
                } else {
                    registrationColor.push('#b0aaaa')
                }

                if(idx === data.memberLoginData.length - 1) {
                    setRegistrationGraph({
                        label: registrationLabel,
                        value: registrationValue,
                        bgColor: registrationColor
                    })
                }
            })

            const citiesLabel = [];
            const citiesValue = [];
            const citiesColor = [];

            data.memberCityData.map((city, idx) => {
                if(idx < 5) {
                    citiesLabel.push(city.city);
                    citiesValue.push(city['count(id)'])
                    citiesColor.push('#4673c8')
                }

                if(idx === 4) {
                    setTop5CitiesGraph({
                        label: citiesLabel,
                        value: citiesValue,
                        bgColor: citiesColor
                    })
                }
            })

            const ageDemographicDataTemp = [];

            ageDemographicDataTemp.push(data.memberAgeData[0].below_17);
            ageDemographicDataTemp.push(data.memberAgeData[0].between_18_24);
            ageDemographicDataTemp.push(data.memberAgeData[0].between_25_39);
            ageDemographicDataTemp.push(data.memberAgeData[0].more_40);

            console.log(ageDemographicDataTemp)
            setAgeDemographicData(ageDemographicDataTemp)

        } catch (e) {
            console.log(e)
        }
    }

    const getAllClass = async () => {

    }

    const renderContent = () => {
        return <>

            <Container fluid style={{
                minHeight: "100vh"
            }}>

                <Row style={{marginTop: "1em"}}>
                    <Col md={12}>

                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em"
                        }}>
                            <Card.Body className="pb-0">

                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    paddingBottom: "1em",
                                    flexDirection: "column",
                                    fontSize: "1.05em"
                                }}
                                >
                                    <span style={{
                                        fontSize: "1.15em",
                                    }}>
                                        Kelas Mendatang
                                    </span>

                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "rgba(255,255,255,0.5)",
                                        height: "1px",
                                        marginTop: "0.5em",
                                        marginBottom: "0.5em"
                                    }}/>

                                    <br/>

                                    <Container fluid>
                                        <Row>
                                            {
                                                upcomingClass.map((obj, key) => {
                                                    if (key > 2) return
                                                    // console.log(obj)
                                                    return <Col md={4}>
                                                        <b>{obj.name}</b><br/>
                                                        {new moment(obj.start_time).format("D/MM/YYYY HH:mm")}
                                                        <br/>
                                                        <div>

                                                            <FaUserFriends/> {obj.metrics?.accepted}

                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            // justifyContent : "flex-end",
                                                            marginTop: 10
                                                        }}>
                                                            <CustomButton
                                                                onClick={() => history.push(`/editor/classDetail/${obj.code}`)}
                                                                variant={"outlined"}>Kelola</CustomButton>
                                                            <CustomButton
                                                                onClick={() => history.push(`/editor/visitors/${obj.code}`)}
                                                                variant={"outlined"} style={{marginLeft: 10}}>
                                                                Peserta</CustomButton>
                                                        </div>
                                                        {!obj.stream_url ?
                                                            <div style={{
                                                                color: "#ed5858",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                marginTop: "0.5em"
                                                            }}>
                                                                <FiAlertTriangle style={{marginRight: "1em"}}/>URL
                                                                Stream belum diunggah
                                                            </div>
                                                            : null}
                                                        {!obj.attachment_url ?
                                                            <div style={{
                                                                color: "#ed5858",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                marginTop: "0.5em"
                                                            }}>
                                                                <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                Materi kelas belum diunggah
                                                            </div>
                                                            : null}

                                                        {!obj.quiz_question_id ?
                                                            <div style={{
                                                                color: "#ed5858",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                marginTop: "0.5em"
                                                            }}>
                                                                <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                Tes akhir belum dibuat
                                                            </div>
                                                            : null}


                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Container>

                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row style={{marginTop: "1em"}}>
                    <Col md={12}>

                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em"
                        }}>
                            <Card.Body className="pb-0">

                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    paddingBottom: "1em",
                                    flexDirection: "column",
                                    fontSize: "1.05em"
                                }}
                                >
                                    <span style={{
                                        fontSize: "1.15em",
                                    }}>
                                        Kelas Sebelumnya
                                    </span>

                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "rgba(255,255,255,0.5)",
                                        height: "1px",
                                        marginTop: "0.5em",
                                        marginBottom: "0.5em"
                                    }}/>

                                    <br/>

                                    <Container fluid>
                                        <Row>
                                            {
                                                pastClass.map((obj, key) => {
                                                    if (key > 2) return
                                                    // console.log(obj)
                                                    return <Col md={4}>
                                                        <b>{obj.name}</b><br/>
                                                        {new moment(obj.start_time).format("D/MM/YYYY HH:mm")}
                                                        <br/>
                                                        <div>
                                                            <FaUserFriends/> {obj.metrics?.accepted}
                                                        </div>
                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            // justifyContent : "flex-end",
                                                            marginTop: 10
                                                        }}>
                                                            <CustomButton
                                                                onClick={() => history.push(`/editor/classDetail/${obj.code}`)}
                                                                variant={"outlined"}>Kelola</CustomButton>
                                                            <CustomButton
                                                                onClick={() => history.push(`/editor/visitors/${obj.code}`)}
                                                                variant={"outlined"} style={{marginLeft: 10}}>
                                                                Peserta</CustomButton>
                                                        </div>
                                                        {!obj.recording_url ?
                                                            <div style={{
                                                                color: "#ed5858",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                marginTop: "0.5em"
                                                            }}>
                                                                <FiAlertTriangle style={{marginRight: "1em"}}/>Tautan
                                                                Rekaman belum tersedia
                                                            </div>
                                                            : null}
                                                        {!obj.show_answers_to_user ?
                                                            <div style={{
                                                                color: "#ed5858",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                marginTop: "0.5em"
                                                            }}>
                                                                <FiAlertTriangle style={{marginRight: "1em"}}/>Pertanyaan
                                                                dan Jawaban belum di tampilkan
                                                            </div>
                                                            : null}


                                                        {obj.certificate_count === 0 ?
                                                            <div style={{
                                                                color: "#ed5858",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                marginTop: "0.5em"
                                                            }}>
                                                                <FiAlertTriangle style={{marginRight: "1em"}}/>
                                                                Sertifikat belum dikirim
                                                            </div>
                                                            : null}

                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Container>

                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} style={{marginBottom: 20, marginTop: 20}}>
                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em",
                            paddingBottom: 20
                        }}>
                            <Card.Body className="pb-0">
                                <div style={{marginBottom: 20}}>
                                   5 Besar Kota Asal Pendaftar
                                </div>

                                <Bar
                                    data={{
                                        labels: top5CitiesGraph.label,
                                        datasets: [
                                            {
                                                label: 'My First dataset',
                                                backgroundColor: top5CitiesGraph.bgColor,
                                                borderColor: 'rgba(255,99,132,1)',
                                                borderWidth: 1,
                                                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                hoverBorderColor: 'rgba(255,99,132,1)',
                                                data: top5CitiesGraph.value
                                            }
                                        ]
                                    }}
                                    height={"100px"}
                                    options={{
                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'white'
                                            }
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.0)",
                                                    },
                                                    ticks: {
                                                        fontColor: "black"
                                                    }
                                                }
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.1)",
                                                    },
                                                    ticks: {
                                                        suggestedMin: 0,
                                                        beginAtZero: true,
                                                        fontColor: "black"
                                                    }
                                                }
                                            ]
                                        },
                                        legend: {
                                            display: false
                                        },
                                        tooltips: {
                                            callbacks: {
                                                label: function (tooltipItem) {
                                                    return tooltipItem.yLabel;
                                                }
                                            }
                                        }
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={12} style={{marginBottom: 20}}>
                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em",
                            paddingBottom: 20
                        }}>
                            <Card.Body className="pb-0">
                                <div style={{marginBottom: 20}}>
                                   Pendaftar per Hari
                                </div>

                                <Bar
                                    data={{
                                        labels: registrationGraph.label,
                                        datasets: [
                                            {
                                                label: 'My First dataset',
                                                backgroundColor: registrationGraph.bgColor,
                                                borderColor: 'rgba(255,99,132,1)',
                                                borderWidth: 1,
                                                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                hoverBorderColor: 'rgba(255,99,132,1)',
                                                data: registrationGraph.value
                                            }
                                        ]
                                    }}
                                    height={"100px"}
                                    options={{
                                        plugins: {
                                            datalabels: {
                                                display: true,
                                                color: 'white'
                                            }
                                        },
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.0)",
                                                    },
                                                    ticks: {
                                                        fontColor: "black"
                                                    }
                                                }
                                            ],
                                            yAxes: [
                                                {
                                                    gridLines: {
                                                        zeroLineColor: "rgba(0,0,0,1)",
                                                        color: "rgba(255,255,255,0.1)",
                                                    },
                                                    ticks: {
                                                        suggestedMin: 0,
                                                        beginAtZero: true,
                                                        fontColor: "black"
                                                    }
                                                }
                                            ]
                                        },
                                        legend: {
                                            display: false
                                        },
                                        tooltips: {
                                            callbacks: {
                                                label: function (tooltipItem) {
                                                    return tooltipItem.yLabel;
                                                }
                                            }
                                        }
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>

                    {/*<Col md={6}>*/}
                    {/*    <Card className="text-white" style={{*/}
                    {/*        backgroundColor: Palette.PRIMARY,*/}
                    {/*        height: "100%"*/}
                    {/*    }}>*/}
                    {/*        <Card.Body className="pb-0">*/}
                    {/*            <div style={{*/}
                    {/*                fontFamily: "Poppins",*/}
                    {/*                display: "flex",*/}
                    {/*                height: "100%",*/}
                    {/*                paddingBottom: "1em",*/}
                    {/*                flexDirection: "column",*/}
                    {/*                fontSize: "1.05em"*/}
                    {/*            }}>*/}
                    {/*                <span style={{*/}
                    {/*                    fontSize: "1.15em",*/}
                    {/*                }}>*/}
                    {/*                    Data Kelas Bulan September*/}
                    {/*                </span>*/}
                    {/*                <div style={{*/}
                    {/*                    width: "100%",*/}
                    {/*                    backgroundColor: "rgba(255,255,255,0.5)",*/}
                    {/*                    height: "1px",*/}
                    {/*                    marginTop: "0.5em",*/}
                    {/*                    marginBottom: "0.5em"*/}
                    {/*                }}/>*/}

                    {/*                <br/>*/}

                    {/*                <div style={{*/}
                    {/*                    display: "flex",*/}
                    {/*                    flexDirection: "row"*/}
                    {/*                }}>*/}
                    {/*                    <a>*/}
                    {/*                        Jumlah Kelas : 12*/}
                    {/*                    </a>*/}
                    {/*                </div>*/}

                    {/*                <div style={{*/}
                    {/*                    display: "flex",*/}
                    {/*                    flexDirection: "row"*/}
                    {/*                }}>*/}
                    {/*                    <a>*/}
                    {/*                        Total Terdaftar : 938 &nbsp;&nbsp;*/}
                    {/*                    </a>*/}
                    {/*                    <span style={{*/}
                    {/*                        position: "inline",*/}
                    {/*                        display: "flex",*/}
                    {/*                        flexDirection: "row",*/}
                    {/*                        alignItems: "center", color: "Green"*/}
                    {/*                    }}>*/}

                    {/*                        <FaCaretUp/> 22*/}

                    {/*                    </span>*/}
                    {/*                </div>*/}

                    {/*                <br/>*/}

                    {/*                <div style={{*/}
                    {/*                    display: "flex",*/}
                    {/*                    flexDirection: "row"*/}
                    {/*                }}>*/}
                    {/*                    <a>*/}
                    {/*                        Pengunjung Situs : 1294 &nbsp;&nbsp;*/}
                    {/*                    </a>*/}
                    {/*                    <span style={{*/}
                    {/*                        position: "inline",*/}
                    {/*                        display: "flex",*/}
                    {/*                        flexDirection: "row",*/}
                    {/*                        alignItems: "center", color: "Green"*/}
                    {/*                    }}>*/}

                    {/*                        <FaCaretUp/> 90*/}

                    {/*                    </span>*/}
                    {/*                </div>*/}

                    {/*                <div style={{*/}
                    {/*                    display: "flex",*/}
                    {/*                    flexDirection: "row"*/}
                    {/*                }}>*/}
                    {/*                    <a>*/}
                    {/*                        Pemasukan : Rp140.700.000 &nbsp;&nbsp;*/}
                    {/*                    </a>*/}
                    {/*                    <span style={{*/}
                    {/*                        position: "inline",*/}
                    {/*                        display: "flex",*/}
                    {/*                        flexDirection: "row",*/}
                    {/*                        alignItems: "center", color: "Green"*/}
                    {/*                    }}>*/}

                    {/*                        <FaCaretUp/> Rp3.600.000*/}

                    {/*                    </span>*/}
                    {/*                </div>*/}

                    {/*                <span style={{*/}
                    {/*                    flex: 1,*/}
                    {/*                    display: "flex",*/}
                    {/*                    alignItems: "flex-end",*/}
                    {/*                }}>*/}

                    {/*                </span>*/}
                    {/*            </div>*/}
                    {/*        </Card.Body>*/}
                    {/*    </Card>*/}
                    {/*</Col>*/}

                    {/*<Col md={6}>*/}
                    {/*    <LineChart*/}
                    {/*        caption={*/}
                    {/*            <div style={{fontFamily: "Poppins"}}>*/}
                    {/*                <span style={{*/}
                    {/*                    fontSize: "1.2em",*/}
                    {/*                }}>*/}
                    {/*                    Pendaftaran Dalam 7 Hari Terakhir*/}
                    {/*                </span>*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*        backgroundColor={Palette.SECONDARY}*/}
                    {/*        variables={["Pendaftaran Terkonfirmasi",*/}
                    {/*            // "Pendaftaran", "Dilihat"*/}
                    {/*        ]}*/}
                    {/*        labels={*/}
                    {/*            // eventMetrics.viewCount ? eventMetrics.viewCount.labels : []*/}
                    {/*            [*/}
                    {/*                "22/8", "23/8", "24/8", "25/8", "26/8", "27/8", "28/8"*/}
                    {/*            ]*/}
                    {/*        }*/}
                    {/*        data={[*/}
                    {/*            // eventMetrics.acceptedCount ? eventMetrics.acceptedCount.data : [],*/}
                    {/*            // eventMetrics.registrationCount ? eventMetrics.registrationCount.data : [],*/}
                    {/*            [*/}
                    {/*                100, 204, 399, 292, 103, 121, 90*/}
                    {/*            ]*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*</Col>*/}

                </Row>
                <Row>
                    <Col md={12}>

                        <Card style={{
                            backgroundColor: 'white',
                            height: "100%",
                            marginTop: "2em"
                        }}>
                            <Card.Body className="pb-0">

                                <div style={{
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    paddingBottom: "1em",
                                    flexDirection: "column",
                                    fontSize: "1.05em"
                                }}
                                >
                                    <span style={{
                                        fontSize: "1.15em",
                                    }}>
                                        Sebaran Peserta
                                    </span>

                                    <div style={{
                                        width: "100%",
                                        backgroundColor: "rgba(255,255,255,0.5)",
                                        height: "1px",
                                        marginTop: "0.5em",
                                        marginBottom: "0.5em"
                                    }}/>

                                    <br/>
                                </div>

                                <Container>
                                    <Row>
                                        <Col md={6} style={{textAlign: "right",}}>

                                            <PieChart
                                                backgroundColor={Palette.SECONDARY}
                                                variables={["Pendaftaran Terkonfirmasi",
                                                    // "Pendaftaran", "Dilihat"
                                                ]}
                                                labels={
                                                    // eventMetrics.viewCount ? eventMetrics.viewCount.labels : []
                                                    [
                                                        "Pria", "Wanita"
                                                    ]
                                                }
                                                data={[
                                                    // eventMetrics.acceptedCount ? eventMetrics.acceptedCount.data : [],
                                                    // eventMetrics.registrationCount ? eventMetrics.registrationCount.data : [],

                                                    230, 240

                                                ]}
                                            />
                                            Jenis Kelamin
                                        </Col>
                                        <Col md={6} style={{textAlign: "right"}}>

                                            <PieChart
                                                backgroundColor={Palette.SECONDARY}
                                                variables={["Pendaftaran Terkonfirmasi",
                                                    // "Pendaftaran", "Dilihat"
                                                ]}
                                                labels={
                                                    // eventMetrics.viewCount ? eventMetrics.viewCount.labels : []
                                                    [
                                                        "~17", "18-24", "25-39", "40~"
                                                    ]
                                                }
                                                data={ageDemographicData}
                                            />
                                            Umur
                                        </Col>
                                    </Row>
                                </Container>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    return (
        <>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar}
                isLoadingFull={isLoading}
                activeTab={"dashboard"}
            >
                {renderContent()}
            </EditorPageWrapper>
        </>

    );

}
