import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useState} from "react";
import MobTable from "../../reusable/Table/MobTable";
import CustomButton from "../../reusable/CustomButton";
import {Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {Button, Col, Row} from "react-bootstrap";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FaPen, FaSave, FaTimes} from "react-icons/fa";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import ApiRequest from "../../../util/ApiRequest";
import Admin from "../../../models/Admin";
import User from "../../../models/User";
import Palette from "../../../util/Palette";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";

let adminModel = new Admin();

export default function AdminEditor(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [selectedAdmin, setSelectedAdmin] = useState({});
    const [adminTypeIndex, setAdminTypeIndex] = useState(2);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isAdminManagementModalVisible, setAdminManagementModalVisible] = useState(false);
    const [adminList, setAdminList] = useState([]);
    const [isEdit, setEdit] = useState(false);
    const [isBan, setBan] = useState(false);
    const [isUnban, setUnban] = useState(false);
    const [isBtnHovered, setBtnHovered] = useState(false);

    const adminTypes = {
        0: null,
        1: 'superadmin',
        2: 'admin'
    }

    let columns = [
        {
            Header: "Surel",
            accessor: "email",
            Cell: (cellInfo) => {
                // let rowInfo = cellInfo.row.values.role

                console.log(cellInfo.row.original.active);

                return <p>{cellInfo.row.values.email} </p>
            }
        },
        {
            Header: "Nama",
            accessor: "name"
        },
        {
            Header: "Tipe",
            accessor: "role",
            Cell: (cellInfo) => {
                let rowInfo = cellInfo.row.values.role

                return <p>{rowInfo === 'admin' ? 'Admin' : 'Super Admin'}</p>
            }
        }
    ]

    const interactions = [
        {
            name: "Sunting",
            action: (admin) => {
                setAdminManagementModalVisible(true)
                setEdit(true);
                console.log(admin)
                setAdminTypeIndex(admin.role === 'superadmin' ? 1 : 2);
                setSelectedAdmin(admin)
            },
            condition: (admin) => {
                return admin.active
            },
            style: {width: 100}
        }
    ];

    useEffect(() => {
        getAdminData()
    }, [])

    const getAdminData = async () => {
        const result = await adminModel.getAll();

        console.log(result)

        setAdminList(result)
    }

    const createNewAdmin = async () => {
        if (!selectedAdmin.email || !selectedAdmin.password || !selectedAdmin.name || !adminTypes[adminTypeIndex]) {
            setErrorMsg('Mohon mengisi formulir dengan lengkap')
        } else if (selectedAdmin.password !== confirmPassword) {
            setErrorMsg('Konfirmasi kata sandi salah')
        } else {
            try {
                let result = await adminModel.createNew({
                    ...selectedAdmin,
                    role: adminTypes[adminTypeIndex]
                })

                if (result.id) {
                    alert('Admin baru berhasil ditambahkan!');
                    setAdminManagementModalVisible(false);
                    getAdminData()
                }
            } catch (e) {
                if (e.msg && typeof e.msg === 'string') {
                    return setErrorMsg(e.msg);
                } else {
                    return setErrorMsg('Kesalahan terjadi');
                }
            }
        }
    }

    const updateAdmin = async () => {
        if (!selectedAdmin.email || !selectedAdmin.name || !adminTypes[adminTypeIndex]) {
            setErrorMsg('Mohon mengisi formulir dengan lengkap')
        } else {
            try {
                let result = await adminModel.update({
                    email: selectedAdmin.email,
                    name: selectedAdmin.name,
                    role: adminTypes[adminTypeIndex]
                }, selectedAdmin.id)

                if (result.affectedRows === 1) {
                    alert('Admin berhasil diperbaharui!');
                    setAdminManagementModalVisible(false);
                    getAdminData()
                }
            } catch (e) {
                if (e.msg && typeof e.msg === 'string') {
                    return setErrorMsg(e.msg);
                } else {
                    return setErrorMsg('Kesalahan terjadi');
                }
            }
        }
    }

    const banAdmin = async () => {
        try {
            let result = await adminModel.ban(selectedAdmin.id);

            if (result.affectedRows.affectedRows === 1) {
                alert('Admin berhasil ter-ban!');
                setBan(false);
                getAdminData()
            }
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                return alert(e.msg);
            } else {
                return alert('Kesalahan terjadi');
            }
        }
    }

    const unbanAdmin = async () => {
        try {
            let result = await adminModel.unban(selectedAdmin.id);

            if (result.affectedRows.affectedRows === 1) {
                alert('Admin berhasil ter-unban!');
                setUnban(false);
                getAdminData()
            }
        } catch (e) {
            if (e.msg && typeof e.msg === 'string') {
                return alert(e.msg);
            } else {
                return alert('Kesalahan terjadi');
            }
        }
    }

    return (
        <EditorPageWrapper
            activeTab={"admins"}
            isLoadingBar={isLoadingBar}
            isLoadingFull={isLoading}
        >
            <Dialog open={isBan || isUnban}
                    maxWidth="sm">
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                color: Palette.PRIMARY,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                Konfirmasi {isBan ? 'Ban' : 'Unban'} Admin
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>

                    <DialogContent style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                        Apakah anda yakin ingin {isBan ? 'ban' : 'unban'} <span
                        style={{fontFamily: 'OpenSans-Bold'}}>{selectedAdmin.name} ({selectedAdmin.email})</span> sebagai
                        admin?


                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 30,
                            marginRight: 15,
                            marginBottom: 15
                        }}>
                            <CustomButton
                                onClick={() => {
                                    setUnban(false)
                                    setBan(false)
                                }}
                                style={{borderWidth: 0, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                variant={"outlined"}
                                color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                style={{marginLeft: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                onClick={isBan ? banAdmin : unbanAdmin}
                                variant={"contained"}
                                color="secondary">
                                {isBan ? 'Ban' : 'Unban'}
                            </CustomButton>
                        </div>
                    </DialogContent>
                </>
            </Dialog>

            <Dialog
                open={isAdminManagementModalVisible}
                maxWidth="md"
                fullWidth={true}
            >
                <>
                    <DialogTitle onClose={() => this.onClose()}>
                        <Row>
                            <Col style={{
                                fontFamily: "OpenSans-Bold",
                                // fontWeight: "bold",
                                display: 'flex',
                                alignItems: 'center',
                                color: Palette.PRIMARY
                            }}>
                                {isEdit ? 'Sunting' : 'Tambah'} Admin
                            </Col>
                        </Row>
                        <hr/>
                    </DialogTitle>
                    <DialogContent>
                        <Collapse
                            in={errorMsg.length > 0}
                            style={{marginBottom: errorMsg.length > 0 ? '1.5em' : '0em'}}
                        >
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setErrorMsg("");
                                        }}
                                    >
                                        <FaTimes fontSize="inherit"/>
                                    </IconButton>
                                }
                            >
                                {errorMsg}
                            </Alert>
                        </Collapse>

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                        }}
                        >
                            <Col md={12} style={{fontFamily: 'OpenSans-SemiBold'}}>
                                <LagFreeTextEditor
                                    title={'Surel'}
                                    changeValue={value => setSelectedAdmin({
                                        ...selectedAdmin,
                                        email: value
                                    })}
                                    value={selectedAdmin.email}
                                    placeholder={"Surel"}/>
                            </Col>
                        </Row>
                        {/*<Row style={{*/}
                        {/*    paddingInlineStart: "2%",*/}
                        {/*    paddingInlineEnd: "2%",*/}
                        {/*}}*/}
                        {/*>*/}
                        {/*    <Col md={12} sm={12} style={{*/}
                        {/*        fontSize: "1.2em",*/}
                        {/*        display: "flex",*/}
                        {/*        alignItems: "flex-end"*/}
                        {/*    }}>*/}
                        {/*        <TextField*/}
                        {/*            // onBlur={()=>editEventState("nama", name)}*/}
                        {/*            onChange={evt => setSelectedAdmin({*/}
                        {/*                ...selectedAdmin,*/}
                        {/*                email: evt.target.value*/}
                        {/*            })}*/}
                        {/*            style={{*/}
                        {/*                marginLeft: "0.5em",*/}
                        {/*                style: {*/}
                        {/*                    fontSize: "1em",*/}
                        {/*                    fontFamily: "OpenSans-Regular",*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*            InputProps={{*/}
                        {/*                style: {*/}
                        {/*                    fontSize: "1em",*/}
                        {/*                    fontFamily: "OpenSans-Regular",*/}
                        {/*                    cursor: "pointer"*/}
                        {/*                }*/}
                        {/*            }}*/}
                        {/*            value={selectedAdmin.email}*/}
                        {/*            fullWidth placeholder={"Surel"}/>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}

                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '1em'
                        }}
                        >
                            <Col md={12}>
                                <LagFreeTextEditor
                                    title={'Nama'}
                                    changeValue={value => setSelectedAdmin({
                                        ...selectedAdmin,
                                        name: value
                                    })}
                                    value={selectedAdmin.name}
                                    placeholder={"Nama"}/>
                            </Col>
                        </Row>

                        {isEdit ? null :
                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                paddingTop: '1em'
                            }}
                            >
                                <Col md={12}>
                                    <LagFreeTextEditor
                                        title={'Kata Sandi'}
                                        changeValue={value => setSelectedAdmin({
                                            ...selectedAdmin,
                                            password: value
                                        })}
                                        type={'password'}
                                        value={selectedAdmin.password}
                                        placeholder={"Kata Sandi"}/>
                                </Col>
                            </Row>
                        }

                        {isEdit ? null :
                            <Row style={{
                                paddingInlineStart: "2%",
                                paddingInlineEnd: "2%",
                                paddingTop: '1em'
                            }}
                            >
                                <Col md={12}>
                                    <LagFreeTextEditor
                                        title={'Konfirmasi Kata Sandi'}
                                        changeValue={value => setConfirmPassword(value)}
                                        placeholder={"Konfirmasi Kata Sandi"}/>
                                </Col>
                            </Row>
                        }


                        {/*}
                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            marginTop: "1em"
                        }}
                        >
                            <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: '.9em'}}>Tipe Admin</Col>
                        </Row>
                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            marginTop: "0.5em"
                        }}
                        >
                            <Col md={4} style={{
                                fontSize: "1.2em",
                                display: "flex",
                                alignItems: "flex-end"
                            }}>
                                <Select
                                    fullWidth
                                    value={adminTypeIndex}
                                    onChange={evt => {
                                        setAdminTypeIndex(evt.target.value)
                                    }}
                                    style={{
                                        marginLeft: "0.5em",
                                        style: {
                                            fontSize: "1em",
                                        }
                                    }}
                                    defaultValue={0}
                                >
                                    <MenuItem style={{fontFamily: "OpenSans-Regular"}} value={0} disabled>Pilih Tipe
                                        Admin</MenuItem>


                                    <MenuItem value={1}>
                                        <span style={{fontFamily: "OpenSans-Regular"}}>Super Admin</span>
                                    </MenuItem>


                                    <MenuItem value={2}>
                                        <span style={{fontFamily: "OpenSans-Regular"}}>Admin</span>
                                    </MenuItem>
                                </Select>
                            </Col>
                        </Row>
                        {*/}


                        <Row style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '1em',
                            paddingBottom: '1em',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                        >
                            <CustomButton
                                style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                onClick={() => {
                                    setEdit(false)
                                    setSelectedAdmin({})
                                    setAdminManagementModalVisible(false)
                                }}
                                variant={"outlined"} color="primary">
                                Batal
                            </CustomButton>

                            <CustomButton
                                onClick={isEdit ? updateAdmin : createNewAdmin}
                                style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                                variant={"contained"} color="primary">
                                <>Simpan&nbsp;&nbsp;<FaSave/></>
                            </CustomButton>
                        </Row>
                    </DialogContent>
                    {/*{renderModalFooter()}*/}
                </>
            </Dialog>

            <div style={{display: 'flex', flexDirection: 'column', marginBottom: 30, marginTop: '1.75em'}}>
                <div style={{
                    color: Palette.PRIMARY,
                    fontSize: "1.75em",
                    fontFamily: "OpenSans-Bold",
                    marginRight: 25
                }}>Daftar Admin
                </div>

                <Button variant={'outline-primary'}
                        onMouseEnter={() => setBtnHovered(true)}
                        onMouseLeave={() => setBtnHovered(false)}
                        style={{
                            alignSelf: 'flex-start',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 30,
                            fontSize: '.8em',
                            fontFamily: 'OpenSans-SemiBold',
                            color: isBtnHovered ? 'white' : Palette.PRIMARY,
                            marginLeft: 2,
                            marginTop: 15
                        }}
                        onClick={() => {
                            setAdminManagementModalVisible(true)
                        }}
                >
                    + <span style={{marginLeft: 6}}>Tambah Admin</span>
                </Button>

                {/*<CustomButton*/}
                {/*    onClick={() => setAdminManagementModalVisible(true)}*/}
                {/*    style={{marginTop: 20, borderWidth: 0, fontFamily: 'OpenSans-SemiBold'}}*/}
                {/*    variant={"outlined"}>+ Tambah Admin</CustomButton>*/}
            </div>

            <MobTable
                columns={columns}
                data={adminList}
                interactions={interactions}
            />
        </EditorPageWrapper>
    )
}