import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {useState} from "react";
import {ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@material-ui/core";
import CustomButton from "../reusable/CustomButton";
import {FaTimes} from "react-icons/all";
import LinearProgress from "@material-ui/core/LinearProgress";
import Palette from "../../util/Palette";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import {useCookies} from "react-cookie";
import Form from "react-bootstrap/Form";
import {useHistory} from "react-router-dom";
import Admin from "../../models/Admin";

export default function LoginPageModalOnly(props) {

    const [cookie, setCookie, removeCookie] = useCookies()

    const [error, setError] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [loginCred, setLoginCred] = useState({})

    const history = useHistory()

    const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)

    if(isLoggedIn){
        history.push("/dashboard")
    }


    const loginMode=true

    const handleSubmit = async () => {

        let userModel = new Admin();

        if (!loginCred.email || !loginCred.password) {
            setError("Harap lengkapi email dan kata sandi")
            return
        }

        try {
            setError("")
            setIsLoading(true)
            let result = await userModel.login(loginCred)

            console.log(result);

            // setCookie("token", result.token, {path : "/", maxAge: 60 * 60 * 24 * 30})
            // Cookies.set('token', result.token, {expires: 30});
            let date = new Date()
            date.setDate(date.getDate() + 30)
            setCookie("token", result.token, {expires : date})

            setTimeout(function () {
                history.push("/dashboard")
            }, 100);

            setIsLoading(false)

        } catch (e) {
            setIsLoading(false)

            let msg = "Terjadi Kesalahan"

            console.log(e);

            if (e.msg) {
                if (e.code === "EMAIL_NOT_FOUND" || e.code === "PASSWORD_MISMATCH" || e.code === "CREDENTIAL_NOT_FOUND") {
                    msg = "Periksa kembali email dan kata sandi"
                }
            }

            setError(msg)
        }

    }

    const renderBody = () => {
        return <>
            <Form style={{
                marginTop: "1em",
                fontSize: "0.9em"
            }}>
                <Form.Group>
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                        value={loginCred.email}
                        onChange={evt => setLoginCred({
                            ...loginCred,
                            email: evt.target.value
                        })}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Kata Sandi</Form.Label>
                    <Form.Control
                        value={loginCred.password}
                        onChange={evt => setLoginCred({
                            ...loginCred,
                            password: evt.target.value
                        })}
                        onKeyPress={event => {
                            if (event.key === "Enter") {
                                handleSubmit();
                            }
                        }}
                        style={{fontSize: "1em"}} type="password" placeholder=""/>
                </Form.Group>
            </Form>

        </>
    }

    return <>
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
        >
            <Modal.Body>

                <Row>
                    <Col md={12}
                         style={{marginTop: "0.2em", display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                        <img
                            style={{
                                height : 180,
                                objectFit: "contain",
                            }}
                            src={require("../../asset/patjarmerah.png")}/>
                    </Col>
                </Row>


                <Row>
                    <Col md={12}>
                        {renderBody()}
                    </Col>
                </Row>

                <span style={{
                    color: "red",
                    fontSize: "0.8em"
                }}>
                    {error ? error : props.message}
                </span>

            </Modal.Body>
            <Modal.Footer>
                <Container style={{padding: 0}}>
                    <Row>
                        <Col md={6} style={{marginTop: '10px'}}>

                        </Col>
                        <Col md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                style={{backgroundColor: Palette.primary, borderWidth: 0}}
                                onClick={() => handleSubmit()}>
                                <div style={{color : "white"}}>{loginMode ? "Masuk" : "Daftar"}</div>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    </>

}
