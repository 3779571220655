import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaSave, FaTimes} from "react-icons/fa";
import LagFreeTextEditor from "./LagFreeTextEditor";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomButton from "./CustomButton";
import EditorPageWrapper from "../page/editor/EditorPageWrapper";
import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import {MdEvent} from "react-icons/md";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import Member from "../../models/Users";

export default function EditUserModal(props) {

    const [errorMsg, setErrorMsg] = useState('');

    const [selectedUser, setSelectedUser] = useState({})

    const updateUser = async () => {

        try{
            let member = new Member();
            let result = await member.update(selectedUser)

            console.log(result)

            props.onClose()
        }catch(e){
            setErrorMsg(JSON.stringify(e))
            console.log(e)
        }

    }

    useEffect(() => {

        console.log(props.user)
        setSelectedUser(props.user)

    }, [props.user])

    return <Dialog
        open={props.isOpen}
        maxWidth="md"
        fullWidth={true}
    >
        <>
            <DialogTitle onClose={() => this.onClose()}>
                <Row>
                    <Col style={{
                        fontFamily: "OpenSans-Bold",
                        // fontWeight: "bold",
                        display: 'flex',
                        alignItems: 'center',
                        color: Palette.PRIMARY
                    }}>
                        Sunting Pengguna
                    </Col>
                </Row>
                <hr/>
            </DialogTitle>
            <DialogContent>
                <Collapse
                    in={errorMsg.length > 0}
                    style={{marginBottom: errorMsg.length > 0 ? '1.5em' : '0em'}}
                >
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setErrorMsg("");
                                }}
                            >
                                <FaTimes fontSize="inherit"/>
                            </IconButton>
                        }
                    >
                        {errorMsg}
                    </Alert>
                </Collapse>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                }}
                >
                    <Col md={12} style={{fontFamily: 'OpenSans-SemiBold'}}>
                        <LagFreeTextEditor
                            title={'Surel'}
                            changeValue={value => setSelectedUser({
                                ...selectedUser,
                                email: value
                            })}
                            value={selectedUser.email}
                            placeholder={"Surel"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Nama'}
                            changeValue={value => setSelectedUser({
                                ...selectedUser,
                                full_name: value
                            })}
                            value={selectedUser.full_name}
                            placeholder={"Nama"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Nomor Ponsel'}
                            changeValue={value => setSelectedUser({
                                ...selectedUser,
                                phone_num: value
                            })}
                            value={selectedUser.phone_num}
                            placeholder={"Nomor Ponsel"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Organisasi'}
                            changeValue={value => setSelectedUser({
                                ...selectedUser,
                                organization: value
                            })}
                            value={selectedUser.organization}
                            placeholder={"Organisasi"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <LagFreeTextEditor
                            title={'Kota Tempat Tinggal'}
                            changeValue={value => setSelectedUser({
                                ...selectedUser,
                                city: value
                            })}
                            value={selectedUser.city}
                            placeholder={"Kota Tempat Tinggal"}/>
                    </Col>
                </Row>

                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <div>
                            Tanggal Lahir
                        </div>

                        <div style={{
                            paddingInlineStart: "2%",
                            paddingInlineEnd: "2%",
                            paddingTop: '1em'
                        }}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    allowKeyboardControl={false}
                                    autoOk={true}
                                    format="D MMM YYYY"
                                    InputProps={{
                                        style: {
                                            fontSize: "1em",
                                            fontFamily: "OpenSans-Regular",
                                            cursor: "pointer"
                                        },
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <MdEvent style={{color: Palette.PRIMARY}}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={selectedUser.date_of_birth}
                                    onChange={date => {
                                        setSelectedUser({
                                            ...selectedUser,
                                            date_of_birth: date
                                        })
                                    }}
                                    placeholder={`Tanggal Lahir`}/>
                            </MuiPickersUtilsProvider>
                        </div>

                    </Col>
                </Row>


                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em'
                }}
                >
                    <Col md={12}>
                        <div>
                            Jenis Kelamin
                        </div>

                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={selectedUser.gender}
                                        onChange={(e) => {

                                            setSelectedUser({
                                                ...selectedUser,
                                                gender: e.target.value
                                            })
                                        }}>
                                <FormControlLabel value="M" control={<Radio/>} label="Pria"/>
                                <FormControlLabel value="F" control={<Radio/>} label="Wanita"/>
                            </RadioGroup>
                        </FormControl>

                    </Col>
                </Row>


                <Row style={{
                    paddingInlineStart: "2%",
                    paddingInlineEnd: "2%",
                    paddingTop: '1em',
                    paddingBottom: '1em',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
                >
                    <CustomButton
                        style={{borderWidth: 0, marginRight: 10, width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        onClick={() => {
                            setSelectedUser({})
                            props.onClose()
                        }}
                        variant={"outlined"} color="primary">
                        Batal
                    </CustomButton>

                    <CustomButton
                        onClick={updateUser}
                        style={{color: 'white', width: 120, fontFamily: 'OpenSans-SemiBold'}}
                        variant={"contained"} color="primary">
                        <>Simpan&nbsp;&nbsp;<FaSave/></>
                    </CustomButton>
                </Row>
            </DialogContent>
            {/*{renderModalFooter()}*/}
        </>
    </Dialog>

}
