import ApiRequest from "../util/ApiRequest";

export default class Participation {

    getAll = async () => {
        return await ApiRequest.set("/v1/participant", "GET");
    }

    getOne = async (id) => {
        return await ApiRequest.set(`/v1/participant/${id}`, "GET");
    }

    accept = async (id) => {
        return await ApiRequest.set(`/v1/participant/${id}/status`, "POST", {
            status : "ACCEPTED"
        });
    }

    updateAbsent = async (id, isPresent) => {
        return await ApiRequest.set(`/v1/participant/${id}/present`, "POST", {
            isPresent : isPresent
        });
    }

    updateNote = async (id, note) =>{
        return await ApiRequest.set(`/v1/participant/${id}/note`, "PUT", {
            note: note
        });
    }

    updatePredicate = async (id, predicate) => {
        return await ApiRequest.set(`/v1/participant/${id}/predicate`, "POST", {
            predicate : predicate
        });
    }

    updatePredicates = async (participants, predicate) => {
        return await ApiRequest.set(`/v1/participants/predicate`, "PUT", {
            participants : participants,
            predicate : predicate
        });
    }

    reject = async (id, message) => {
        return await ApiRequest.set(`/v1/participant/${id}/status`, "POST", {
            status : "REJECTED",
            rejection_message : message
        });
    }


    sendScenarioEmail = async (participantId, scenario) =>{
        return await ApiRequest.set(`/v1/participant/${participantId}/resend/${scenario}`,"POST")
    }

    uploadCertificate = async(base64image, participantId, classCode) =>{

        let formData = new FormData();
        let decodedFile = b64toBlob(base64image)

        // formData.append('participantId', 39)
        // formData.append('classId', classCode)
        console.log(decodedFile.name)

        console.log("success", decodedFile)
        formData.append('upload', decodedFile, decodedFile.name);
        console.log("success")

        return await ApiRequest.setMultipart(`/v1/certificate/${classCode}/${participantId}`, "POST", formData);

    }


    uploadPDF = async(pdf, participantId, classCode) =>{

        let formData = new FormData();
        // let decodedFile = b64toBlob(base64image)

        // formData.append('participantId', 39)
        // formData.append('classId', classCode)

        // console.log("success", decodedFile)
        formData.append('upload', pdf, "certificate.pdf");
        console.log("success")

        return await ApiRequest.setMultipart(`/v1/certificate/${classCode}/${participantId}`, "POST", formData);

    }

    uploadSingleCertificate = async(file, participantId, classCode) =>{

        let formData = new FormData();

        // console.log('blob', file)
        formData.append('upload', file, file.name);

        return await ApiRequest.setMultipart(`/v1/certificate/${classCode}/${participantId}`, "POST", formData);

    }

    getParticipationToken = async(participantId)=>{
        return await ApiRequest.setMultipart(`/v1/participant/${participantId}/generateToken`, "GET");
    }


}

function b64toBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
}
