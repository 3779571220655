import {Line, Pie} from "react-chartjs-2";
import React from "react";
import Palette from "../../../util/Palette";
import Card from "react-bootstrap/Card";

export default function PieChart(props) {


    let formattedData = {
        labels: props.labels,
        datasets: [{
            data: props.data,
            label: props.labels,
            backgroundColor: ["#059ece", "#42c697", "#916fb5", "#ef6c6c", "#f4b571", "#d6639d", "#6accc0", "#4b74b5"]
        }]
    };

    let formattedOptions = {
        tooltips: {
            enabled: true,
        },
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'right'
        },
    };

    return <>
        <div className="text-white" style={{height: "100%"}}>
            <div style={{height: "calc(250px - 2em)"}}>
                <Pie
                    data={formattedData}
                    options={formattedOptions}
                />
            </div>
        </div>

    </>

}
