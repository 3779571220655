import {Button, Col, Row} from "react-bootstrap";
import Palette from "../../../util/Palette";
import {TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Topic from "../../../models/Topic";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select/Select";
import ManageTopicModal from "../ManageTopicModal";
import InputAdornment from "@material-ui/core/InputAdornment";
import LagFreeTextEditor from "../LagFreeTextEditor";
import CustomButton from "../CustomButton";
import {AiFillWarning, MdClear, MdFileDownload, MdOpenInNew} from "react-icons/all";
import textFormatter from "../../../util/textFormatter";
import FileUpload from "../FileUpload";
import Email from "../../../models/Email";
import IconButton from "@material-ui/core/IconButton";
import {MdCheckCircle} from "react-icons/md";

let speakerTimer;
let nameTimer;
let shortDescriptionTimer;
let longDescriptionTimer;
let urlTimer;
const topicModel = new Topic();

export default function AboutEditor(props) {

    let {editSessionState, editEventState, classObj, setClass} = props

    const [code, setCode] = useState(null);
    const [source, setSource] = useState('');
    const [streamUrl, setStreamUrl] = useState('');
    let [speaker, setSpeaker] = useState("")
    let [name, setName] = useState("")
    let [description, setDescription] = useState("")
    let [url, setURL] = useState("")
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(0);
    const [isBtnHovered, setBtnHovered] = useState(false);
    const [isAddTopicModalShown, setAddTopicModalShown] = useState(false);

    const [isUploadingAttachment, setIsUploadingAttachment] = useState(false)
    const [attachment, setAttachment] = useState({})

    useEffect(() => {
        getTopic();

        setCode(props.classObj.code);
        setSource(props.classObj.source);
        setSelectedTopic(props.classObj.topic_code)
        setName(props.classObj.name)
        setStreamUrl(props.classObj.stream_url);

        setSpeaker(props.speaker)
        setDescription(props.description)

        setURL(props.url)
    }, [props.speaker, props.shortDescription, props.longDescription, props.name, props.classObj])

    const getTopic = async () => {
        const result = await topicModel.getAll();

        setTopics(result)
    }

    const editSpeaker = (value) => {
        setSpeaker(value)

        setClass({
            ...classObj,
            speaker: value
        })
    }

    const editDescription = (value) => {
        setDescription(value)

        setClass({
            ...classObj,
            description: value
        })
    }

    const editName = (value) => {
        setName(value)

        setClass({
            ...classObj,
            name: value
        })
    }

    const addAttachment = async function (addedFile) {

        setIsUploadingAttachment(true)

        try {

            console.log(addedFile)
            let result = await new Email().addAttachment(addedFile[0])

            //
            console.log(result)

            setAttachment({
                value: result.location,
                label: addedFile[0].name
            })

            setClass({
                ...classObj,
                attachment_url: result.location
            })

            setIsUploadingAttachment(false)

        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"

            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            alert(tempMessage)
            console.log(e)

            setIsUploadingAttachment(false)

        }

    }

    const validURL = (str) => {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    return <>
    <ManageTopicModal visible={isAddTopicModalShown} hide={() => {
        setAddTopicModalShown(false)
    }} updateData={getTopic}/>

    <Row style={{marginTop: 30}}>
        <Col md={12}>
            <p style={{
                color: Palette.PRIMARY,
                fontFamily: "OpenSans-Bold",
                fontSize: "1.2em"
            }}>Deskripsi Kelas</p>
        </Col>
    </Row>

    <Row style={{
        paddingInlineStart: "2%",
        paddingInlineEnd: "2%",
        marginTop: "1em",
        fontFamily: 'OpenSans-SemiBold'
    }}
    ><Col md={12}>
        <LagFreeTextEditor
            title={'Kode Kelas'}
            disabled={props.update}
            changeValue={value => {
                if (!props.update) {
                    setClass({
                        ...classObj,
                        code: value
                    })
                }
            }}
            subtitle={'(akan dibuat secara acak jika dibiarkan kosong)'}
            value={code}
            placeholder={"Kode Kelas"}/>
    </Col>
    </Row>

    {
        props.update ?
            <Row style={{
                paddingInlineStart: "2%",
                paddingInlineEnd: "2%",
                marginTop: "1em",
                fontFamily: 'OpenSans-SemiBold'
            }}
            ><Col md={12}>
                <LagFreeTextEditor


                    InputProps={{
                        style: {
                            fontFamily: "OpenSans-Regular"
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {

                                    <IconButton
                                        onMouseDown={e => e.preventDefault()}
                                    >
                                        <a target="_blank" href={`https://sinara.narabahasa.id/seminar/detail/${code}`}>
                                            <MdOpenInNew style={{color: Palette.PRIMARY}}/>
                                        </a>
                                    </IconButton>

                                }

                            </InputAdornment>
                        ),
                    }}

                    title={'Tautan Kelas'}
                    disabled={props.update}
                    changeValue={value => {
                        if (!props.update) {
                            setClass({
                                ...classObj,
                                code: value
                            })
                        }
                    }}
                    value={`https://s.narabahasa.id/${code}`}
                    placeholder={"Kode Kelas"}/>
            </Col>
            </Row>
            :
            null
    }

    <Row style={{
        paddingInlineStart: "2%",
        paddingInlineEnd: "2%",
        marginTop: "1em",
        fontFamily: 'OpenSans-SemiBold'
    }}
    >
        <Col md={12}>
            <LagFreeTextEditor
                title={'Tautan Streaming'}
                subtitle={'(kosongkan bila belum tersedia)'}
                changeValue={value => setClass({
                    ...classObj,
                    stream_url: value
                })}
                value={streamUrl}
                placeholder={"Tautan Streaming"}/>
            {
                !validURL(classObj.stream_url) ?
                    <div style={{
                        color: "grey",
                        fontWeight: "lighter"
                    }}>
                        <AiFillWarning
                            style={{marginRight: 5, marginLeft: 10, color: Palette.ORANGE, fontSize: "1.2em"}}/>
                        Peringatan : Pastikan URL yang diketik valid (contoh :
                        https://us02web.zoom.us/j/82590270612)
                    </div>
                    :
                    null
            }

        </Col>
    </Row>

        {/*}
    <Row style={{
        paddingInlineStart: "2%",
        paddingInlineEnd: "2%",
        marginTop: "1em",
        fontFamily: 'OpenSans-SemiBold'
    }}
    >
        <Col md={12}>
            <LagFreeTextEditor
                title={'Tautan Rekaman'}
                subtitle={'(kosongkan bila belum tersedia)'}
                changeValue={value => setClass({
                    ...classObj,
                    recording_url: value
                })}
                value={classObj.recording_url}
                placeholder={"Tautan Rekaman"}/>
            {
                !validURL(classObj.recording_url) ?
                    <div style={{
                        color: "grey",
                        fontWeight: "lighter"
                    }}>
                        <AiFillWarning
                            style={{marginRight: 5, marginLeft: 10, color: Palette.ORANGE, fontSize: "1.2em"}}/>
                        Peringatan : Pastikan URL yang diketik valid (contoh :
                        https://us02web.zoom.us/j/82590270612)
                    </div>
                    :
                    null
            }

        </Col>
    </Row>
    {*/}

    <Row style={{
        paddingInlineStart: "2%",
        paddingInlineEnd: "2%",
        marginTop: "1em",
        fontFamily: 'OpenSans-SemiBold'
    }}
    >
        <Col md={12}>
            <LagFreeTextEditor
                title={'Nama Kelas'}
                changeValue={value => editName(value)}
                value={name}
                placeholder={"Nama Kelas"}/>
        </Col>
    </Row>

    <Row style={{
        paddingInlineStart: "2%",
        paddingInlineEnd: "2%",
        marginTop: "1em",
        fontFamily: 'OpenSans-SemiBold'
    }}
    >
        <Col md={12}>
            <LagFreeTextEditor
                title={'Pembicara'}
                changeValue={value => editSpeaker(value)}
                value={classObj.speaker}
                placeholder={"Pembicara"}/>
        </Col>
    </Row>

    <Row style={{
        paddingInlineStart: "2%",
        paddingInlineEnd: "2%",
        marginTop: "1em",
        fontFamily: 'OpenSans-SemiBold'
    }}
    >
        <Col md={12}>
            <LagFreeTextEditor
                title={'Deskripsi'}
                multiline={true}
                rows={4}
                changeValue={value => editDescription(value)}
                value={classObj.description}
                placeholder={"Deskripsi"}/>
        </Col>
    </Row>
    {/*<Row style={{*/}
    {/*    paddingInlineStart: "2%",*/}
    {/*    paddingInlineEnd: "2%",*/}
    {/*}}*/}
    {/*>*/}
    {/*    <Col md={12} sm={12} style={{*/}
    {/*        fontSize: "1.2em",*/}
    {/*        display: "flex",*/}
    {/*        alignItems: "flex-end"*/}
    {/*    }}>*/}
    {/*        <TextField*/}
    {/*            // onBlur={()=>editEventState("nama", name)}*/}
    {/*            onChange={evt => editName(evt.target.value)}*/}
    {/*            style={{*/}
    {/*                marginLeft: "0.5em",*/}
    {/*                fontSize: "1em",*/}
    {/*                fontFamily: 'OpenSans-Regular'*/}
    {/*            }}*/}
    {/*            inputProps={{*/}
    {/*                style: {*/}
    {/*                    fontSize: "1em",*/}
    {/*                    fontFamily: 'OpenSans-Regular',*/}
    {/*                    cursor: "pointer"*/}
    {/*                }*/}
    {/*            }}*/}
    {/*            value={name} fullWidth placeholder={"Nama Kelas"}/>*/}
    {/*    </Col>*/}
    {/*</Row>*/}


    {/*}
        <Row style={{
            paddingInlineStart: "2%",
            paddingInlineEnd: "2%",
            marginTop: "1em",
            fontFamily: 'OpenSans-SemiBold'
        }}
        >
            <Col md={12}>
                <p style={{
                    marginTop: "1em"
                }}>
                    Materi :
                </p>

                <div style={{
                    marginLeft: "1em",
                    display: "flex",
                    flexDirection: "column"
                }}>

                    {/*<CreatableSelect*/}
    {/*    isMulti*/}
    {/*    formatCreateLabel={(inputValue) => {*/}
    {/*        return "Tambah Tujuan: " + inputValue*/}
    {/*    }}*/}
    {/*    onChange={handleAttachmentChanges}*/}
    {/*    onClick={()=>{*/}
    {/*        console.log("woei")*/}
    {/*    }}*/}
    {/*    menuIsOpen={false}*/}
    {/*    value={attachments}*/}
    {/*/>*/}

    {/*}

    <div style={{
        marginTop: "0.5em"
    }}>
        <a
            style={{
                display: classObj.attachment_url ? null : "none"
            }}
            href={classObj.attachment_url} target={'_blank'} download>
            <CustomButton
                style={{
                    fontSize: "0.85em",
                    fontFamily: "Poppins",
                    textTransform: "none",
                    marginBottom: "1em",
                }}
                variant={"outlined"}>
                {classObj.attachment_url ? textFormatter.getAttachmentURL(classObj.attachment_url) : ""}
                <MdFileDownload style={{marginLeft: "0.5em"}}/>
            </CustomButton>
        </a>

        <FileUpload
            style={{
                marginTop: "0.5em"
            }}
            label={classObj.attachment_url ? "Ubah Lampiran" : "+ Unggah Lampiran"}
            isLoading={isUploadingAttachment}
            onDrop={addAttachment}
        />
    </div>

    </div>
</Col>
</Row>
{*/}

</>

}
