import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Modal,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Spinner,
    Tabs
} from "react-bootstrap"


import 'bootstrap/dist/css/bootstrap.min.css';

import PropTypes from 'prop-types'

import 'moment/locale/id'
import {TextField} from "@material-ui/core"
import Email from "../../../../../models/Email"
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import {FaTimes} from "react-icons/fa";
import {MdAdd} from "react-icons/all";
import {animateScroll as scroll} from "react-scroll";

import RichText from "../../../../reusable/RichText"

import CustomButton from "../../../../reusable/CustomButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TemplateImageAdapter from "./ckeditor-plugin/TemplateImageAdapter"
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic';

export default function EmailTemplateEditorDialog(props) {


    const mystyle = {
        color: "green",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial",
    };

    EmailTemplateEditorDialog.propTypes = {
        show: PropTypes.bool,
        handleClose: PropTypes.func.isRequired,
        emailTemplate: PropTypes.object,
        mode: PropTypes.oneOf(["edit", "create"]),
        onSaveSuccess: PropTypes.func,
        activeSession: PropTypes.object.isRequired
    }

    let [templateName, setTemplateName] = useState("")
    let [subjectTemplate, setSubjectTemplate] = useState("")
    let [bodyTemplate, setBodyTemplate] = useState("")
    let [scenario, setScenario] = useState("")

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    //Refresh the content everytime prop changes, and set to state so doesnt affect the original source
    useEffect(() => {

        console.log(props.mode)

        if (props.mode === "edit") {
            setTemplateName(props.emailTemplate.template_name)
            setSubjectTemplate(props.emailTemplate.subject_template)
            setBodyTemplate(props.emailTemplate.body_template)
            setScenario(props.emailTemplate.scenario)
            setBodyTemplate(props.emailTemplate.body_template)
            setScenario(props.emailTemplate.scenario)

            console.log(props.emailTemplate)

        } else if (props.mode === "create") {
            setTemplateName("")
            setSubjectTemplate("")
            setBodyTemplate("")
            setScenario("GENERAL")
        }

    }, [props])


    const submitForm = () => {
        setErrorMessage("")
        setSuccessMessage("")
        if (props.mode === "edit") {

            let templateId = props.emailTemplate.id

            bodyTemplate = bodyTemplate.replace(/(<p><\/p>)+/g, "<br>")

            let templateObject = {
                body_template: bodyTemplate,
                template_name: templateName,
                subject_template: subjectTemplate,
                scenario: scenario ? scenario.toUpperCase() : null
            }

            let emailRequest = new Email()

            emailRequest.editTemplate(templateId, templateObject).then((result) => {
                promptSuccess("Template Tersimpan!")
                props.onSaveSuccess(templateObject)
            }).catch((error) => {
                // alert(error)
                promptError(error.msg ? JSON.stringify(error.msg) : "")
                console.error(error)
            })
        }
        if (props.mode === "create") {


            Email.addTemplate(templateName, subjectTemplate, bodyTemplate, scenario).then((response) => {
                props.onSaveSuccess(response)
            }).catch((error) => {
                alert(error)
                console.error(error)
            })

        }
    }


    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const promptError = (msg) => {
        setErrorMessage(msg)
        scroll.scrollTo(0)
    }

    const promptSuccess = (msg) => {
        setSuccessMessage(msg)
        scroll.scrollTo(0)
    }

    const handleClose = () => {
        setErrorMessage("")
        setSuccessMessage("")
        props.handleClose()
    }

    let wildcards = [
        {
            value: 0,
            dropdownComponent: <MenuItem style={{fontFamily: "Poppins"}} value={0} disabled><MdAdd/>&nbsp;Pilih Wildcard</MenuItem>,
        },
        {
            string: "Nama Peserta",
            value : "%NAMA_PESERTA%"
        },
        {
            string: "Nama Peserta dengan Titel",
            value : "%NAMA_PESERTA_DENGA_TITEL%"
        },
        {
            string: "Surel Peserta",
            value : "%SUREL_PESERTA%"
        },
        {
            string: "Nama Kelas",
            value : "%NAMA_KELAS%"
        },
        {
            string: "Nama Narasumber",
            value : "%NAMA_NARASUMBER%"
        },
        {
            string: "Kode Kelas",
            value : "%KODE_KELAS%"
        },
        {
            string: "Tanggal dan Waktu Kelas",
            value: "%TANGGAL_WAKTU_KELAS%"
        },
        {
            value : "%TAUTAN_KELAS%"
        },
        {
            string: "Jumlah yang Dibayarkan (untuk kelas berbayar)",
            value: "%JUMLAH_YANG_DIBAYARKAN%"
        },
        {
            string: "Tautan Pembayaran (untuk kelas berbayar)",
            value: "%TAUTAN_PEMBAYARAN%"
        },
        {
            string: "Order ID Transaksi (untuk kelas berbayar)",
            value: "%ORDER_ID%"
        },
        {
            string: "Waktu Kadaluarsa Pembayaran",
            value: "%WAKTU_KADALUARSA_PEMBAYARAN%"
        },
        {
            string: "Alasan Penolakan (hanya untuk template penolakan)",
            value: "%ALASAN_PENOLAKAN%"
        }
    ]

    let scenarioSelections = [
        {
            value: 0,
            dropdownComponent: <MenuItem style={{fontFamily: "Poppins"}} value={0} disabled><MdAdd/>&nbsp;Pilih tipe skenario</MenuItem>,
        },
        {
            string: "Menunggu Pembayaran",
            value : "WAITING"
        },
        {
            string: "Pendaftaran Berhasil",
            value : "ACCEPTED"
        },
        {
            string: "Masuk Daftar Antrian",
            value : "PENDING"
        },
        {
            string: "Pendaftaran Ditolak",
            value : "REJECTED"
        },
        {
            string: "Pengingat Kelas",
            value : "REMINDER"
        },
        {
            string: "Pengingat Kelas Setelah Dimulai",
            value : "CLASS_START_REMINDER"
        }
    ]

    console.log(scenario + "is the scenario")

    return (<Modal show={props.show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
            Ubah Template
        </Modal.Header>
        <Modal.Body>
            {renderSuccessBox()}
            {renderAlertBox()}
            <Row>
                <Col xs={3}>Nama Template</Col>
                <Col xs={9}>
                    <div style={{
                        marginLeft: "1em",
                    }}>
                        <TextField
                            inputProps={{
                                style: {
                                    fontFamily: "Montserrat"
                                }
                            }}
                            size={'small'}
                            fullWidth={true}
                            variant={"outlined"}
                            value={templateName}
                            onChange={evt => setTemplateName(evt.target.value)}
                            placeholder={"Nama Template"}
                            style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontFamily: "Montserrat"
                            }}/>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={3}>Subyek Template</Col>
                <Col xs={9}>
                    <div style={{
                        marginLeft: "1em",
                    }}>
                        <TextField
                        inputProps={{
                        style: {
                            fontFamily: "Montserrat"
                        }
                    }}
                        size={'small'}
                        fullWidth={true}
                        variant={"outlined"}
                        value={subjectTemplate}
                        onChange={evt => setSubjectTemplate(evt.target.value)}
                        placeholder={"Nama Template"}
                        style={{
                        marginTop: 0,
                        marginBottom: 0,
                        fontFamily: "Montserrat"
                    }}/>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>Isi Email</Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {/*<TextField
                        multiline={true}
                        rows={4}
                        style={{width : "100%"}}
                        value={bodyTemplate} onChange={(e)=>{setBodyTemplate(e.target.value)}}/>*/}
                    <RichText onBlurAndSave={setBodyTemplate} value={bodyTemplate}/>
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    Daftar Wildcard Template
                </Col>
                <Col xs={8}>
                    <Select
                        fullWidth
                        value={0}
                        onChange={evt => {
                            navigator.clipboard.writeText(evt.target.value).then(result=>{
                                alert(`${evt.target.value} copied to clipboard!`)
                            })
                        }}
                        IconComponent={()=>(<></>)}
                        style={{
                            marginLeft: "0.5em",
                            style: {
                                fontSize: "1em",
                            }
                        }}
                        placeholder={"Pilih Salah Satu"}
                        defaultValue={0}
                    >
                        {
                            wildcards.map((obj, key) => {
                                return obj.dropdownComponent ? obj.dropdownComponent
                                    :
                                    <MenuItem value={obj.value}>
                                        <span style={{fontFamily: "Poppins"}}>{obj.string}</span>
                                    </MenuItem>
                            })
                        }
                    </Select>
                </Col>
            </Row>
            <Row style={{marginTop : 10}}>
                <Col xs={12}>
                    Tipe Skenario
                </Col>
                <Col xs={12}>
                    <Select
                        fullWidth
                        value={scenario ? scenario : props.emailTemplate?.scenario}
                        onChange={evt => {
                            setScenario(evt.target.value)
                        }}
                        IconComponent={()=>(<></>)}
                        style={{
                            marginLeft: "0.5em",
                            style: {
                                fontSize: "1em",
                            }
                        }}
                        placeholder={"Pilih Salah Satu"}
                        defaultValue={0}
                    >
                        {
                            scenarioSelections.map((obj, key) => {
                                return obj.dropdownComponent ? obj.dropdownComponent
                                    :
                                    <MenuItem value={obj.value}>
                                        <span style={{fontFamily: "Poppins"}}>{obj.string}</span>
                                    </MenuItem>
                            })
                        }
                    </Select>
                </Col>
            </Row>
        </Modal.Body>
        {<Modal.Footer>
            <div style={{
                position : "absolute",
                bottom : "0.75rem",
                left : 12
            }}>
            </div>

            <Button variant="secondary" onClick={handleClose}>
                Tutup
            </Button>
            <Button variant="primary" onClick={submitForm}>
                Simpan
            </Button>
        </Modal.Footer>}
    </Modal>)


}
