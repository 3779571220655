import React, {Component} from 'react';
import {BrowserRouter, HashRouter, Route, useHistory, Switch} from "react-router-dom"
import './App.css';
import Home from "./component/page/Home";
import {ParallaxProvider} from "react-scroll-parallax";
import {CookiesProvider} from "react-cookie";
import GlobalData from "./util/GlobalData";
import CreateEvent from "./component/page/CreateEvent";
import Pricing from "./component/page/Pricing";
import NotFound from "./component/page/NotFound";
import Forbidden from "./component/page/Forbidden";
import EditorMain from "./component/page/editor/EditorMain";
import EventDetail from "./component/page/editor/EventDetail";
import UserManagement from "./component/page/editor/UserManagement/UserManagement";
import EmailManagementForClass from "./component/page/editor/EmailManagement/EmailManagementForClass";
import EventMain from "./component/page/editor/EventMain";
import Profile from "./component/page/editor/Profile";
import LoginPageModalOnly from "./component/page/LoginPageModalOnly";
import UserList from "./component/page/editor/UserList";
import ClassDetail from "./component/page/editor/ClassDetail";
import CreateClass from "./component/page/CreateClass";
import Admin from "./component/page/editor/Admin";
import DashboardPage from "./component/page/editor/DashboardPage";
import CertificatePage from "./component/page/editor/CertificatePage";
import EmailManagementForGlobal from "./component/page/editor/EmailManagement/EmailManagementForGlobal";
import PreviewPage from "./component/page/PreviewPage";

class App extends Component {

    constructor(props) {
        super(props)
    }

    // async startMQTT(){
    //     let mqttManager = new MQTTManager();
    //     await mqttManager.initialize()
    // }

    render() {

        // const history = useHistory()

        return (
            <>
                <CookiesProvider>
                    <ParallaxProvider>
                        <BrowserRouter basename="/">
                            <Switch>

                                {/*<Route exact path= "/" component={Home}/>*/}
                                <Route exact path="/" component={LoginPageModalOnly}/>
                                <Route exact path="/forbidden" component={LoginPageModalOnly}/>

                                <Route exact path= "/create-class" component={CreateClass}/>

                                <Route exact path="/editor/dashboard" component={DashboardPage}/>

                                <Route exact path="/editor/preview/:event_url" component={PreviewPage}/>
                                <Route exact path="/editor/dashboard/:event_url" component={EventMain}/>
                                <Route exact path="/editor/detail/:event_url" component={ClassDetail}/>
                                <Route exact path="/editor/visitors/:event_url" component={UserManagement}/>
                                <Route exact path="/editor/email/:event_url" component={EmailManagementForClass}/>

                                <Route exact path="/editor/certificate/" component={CertificatePage}/>

                                <Route exact path="/editor" component={EditorMain}/>
                                <Route exact path="/profile" component={Profile}/>

                                <Route exact path="/dashboard" component={DashboardPage}/>
                                <Route exact path="/users" component={UserList}/>
                                <Route exact path="/editor/email" component={EmailManagementForGlobal}/>
                                <Route exact path="/admins" component={Admin}/>
                                <Route exact path="/editor/classDetail/:event_url" component={ClassDetail}/>

                                <Route path="/not-found/" component={NotFound} />

                                <Route path="*" component={NotFound} />
                            </Switch>
                        </BrowserRouter>
                    </ParallaxProvider>
                </CookiesProvider>
            </>

        );
    }


}


export default App;
