import ApiRequest from "../util/ApiRequest";

export default class Topic {
    getAll = async () => {
        return await ApiRequest.set("/v1/topic", "GET");
    }

    create = async (topic) => {
        return await ApiRequest.set("/v1/topic", "POST", topic);
    }

    update = async (code, topic) => {
        return await ApiRequest.set(`/v1/topic/${code}`, "PUT", topic);
    }

    delete = async (code) => {
        return await ApiRequest.set(`/v1/topic/${code}`, "DELETE")
    }

    undelete = async (code) => {
        return await ApiRequest.set(`/v1/topic/${code}/switch/activate`, "POST")
    }
}