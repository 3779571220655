import EditorPageWrapper from "./EditorPageWrapper";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Col, Container, ListGroup, Modal, Row} from "react-bootstrap";
import Palette from "../../../util/Palette";
import {
    FaFileDownload,
    FaTimes,
} from "react-icons/all";
import LagFreeTextEditor from "../../reusable/LagFreeTextEditor";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import MobTable from "../../reusable/Table/MobTable"
import Slider from '@material-ui/core/Slider';
import MomentUtils from "@date-io/moment";
import {Stage, Layer, Rect, Text, Image} from 'react-konva';
import TextField from "@material-ui/core/TextField";
import Participation from "../../../models/Participation";
import {useParams} from "react-router-dom";
import Class from "../../../models/Class";
import textFormatter from "../../../util/textFormatter";
import FileUpload from "../../reusable/FileUpload";
import ColorPicker from "material-ui-color-picker";

import {jsPDF} from "jspdf";
import CertificatePredicateModal from "../../reusable/modals/CertificatePredicateModal";
import MultiPredicateModal from "../../reusable/modals/MultiPredicateModal";
import Papa from 'papaparse';
import Email_Template from "./Email_Template";

import _ from "lodash"
import Email from "../../../models/Email";
import InputAdornment from "@material-ui/core/InputAdornment";
import {MdEvent, MdSchedule} from "react-icons/md";
import moment from "moment";
import {
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {CSVLink} from "react-csv";

let containerRef;
let stageRef;
let stageRef2;
let imageRef;
let imageRef2;

export default function CertificatePage(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBar, setIsLoadingBar] = useState(false)
    const [iconLoading, setIsIconLoading] = useState(false)
    const [classDateFormatted, setClassDateFormatted] = useState(null)
    const [classTimeFormatted, setClassTimeFormatted] = useState(null)
    const [classDate, setClassDate] = useState(null)
    const [classDateHC, setClassDateHC] = useState(null)
    const [classTime, setClassTime] = useState(null)
    const [emailSubject, setEmailSubject] = useState(null);
    const [className, setClassName] = useState(null)
    const [speakerName, setSpeakerName] = useState(null);

    const [showMultiPredicateModal, setShowMultiPredicateModal] = useState(false)
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    const [showPredicateModal, setShowPredicateModal] = useState(false)
    const [activeParticipant, setActiveParticipant] = useState({})

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    const [top, setTop] = useState(35.9)
    const [left, setLeft] = useState(63.3)

    const [numberPrefix, setNumberPrefix] = useState("001")
    const [numberSuffix, setNumberSuffix] = useState("/NBKD77/10/20")
    const [uploadedImage, setUploadedImage] = useState(null)

    const [fontSize, setFontSize] = useState(22)

    const [secondTop, setSecondTop] = useState(71)
    const [secondLeft, setSecondLeft] = useState(58)
    const [secondFontSize, setSecondFontSize] = useState(8)

    const [secondTextColor, setSecondTextColor] = useState("#e9eaec")
    const [predicate, setPredicate] = useState("Istimewa")
    const [isSendingEmail, setIsSendingEmail] = useState(false);

    const [canvasWidth, setCanvasWidth] = useState(0)
    const [canvasHeight, setCanvasHeight] = useState(0)

    const {event_url} = useParams()

    const [registrants, setRegistrants] = useState([])

    const [name, setName] = useState(null)
    const [testingName, setTestingName] = useState(null)
    const [textColor, setTextColor] = useState("#a52620")

    const [backgroundImageURL, setBackgroundImageURL] = useState(require("../../../asset/WA.jpeg"))
    const [templateName, setTemplateName] = useState("")
    const [uploadedUsersCSV, setUploadedUsersCSV] = useState([]);
    const [isCsvUploaded, setIsCsvUploaded] = useState(false);

    const generateEmailContent = (participantName) => {
        return ('')
    }

    const columns = [
        {
            Header: "Nama Lengkap",
            accessor: "FullName"
        },
        {
            Header: "Email",
            accessor: "Email"
        }
    ]
    const [csvHeader, setCsvHeader] = useState(columns);
    const [csvData, setCsvData] = useState([])

    const [isUploading, setIsUploading] = useState(false)
    const [successCount, setSuccessCount] = useState(0)

    const sendEmail = async (picture) => {
        const emailModel = new Email();

        try {
            //console.log(stageRef) //Base 64
            let imageLink = await emailModel.addAttachment(picture)
            let email;
            console.log('From image link', imageLink);

            for (const i in csvData) {
                email = await emailModel.sendBatch({
                    recipients: [csvData[i].Email],
                    subject: emailSubject,
                    content: generateEmailContent(csvData[i].FullName),
                    attachments: [{
                        filename: 'sertifikat.jpg',
                        path: imageLink.location
                    }]
                });

            }
            return email;
        } catch (e) {
            return e;
        }
    }

    const uploadPhoto = async () => {
        let emailModel = new Email();
    }

    const sendCertificate = async () => {
        let emailModel = new Email();
        let result;
        let resultBlob;
        let imageLink;
        let email;
        let success = [];

        for (const j in csvData) {
            console.log('UPLOADING EMAIL', j);
            setName(csvData[j].FullName);
            setTestingName(csvData[j].FullName);

            //Convert stageRef jadi blob
            result = stageRef.toDataURL({pixelRatio: 2, quality: 0.1});
            resultBlob = await convertCertificateToBlob(result);
            setIsLoadingBar(true);

            //Kirim Email
            try {
                //console.log(stageRef) //Base 64
                imageLink = await emailModel.addAttachment(resultBlob);
                console.log('From image link', imageLink);
                email = await emailModel.sendBatch({
                    recipients: [csvData[j].Email],
                    subject: emailSubject,
                    content: Email_Template(className, classDateFormatted + classTimeFormatted, speakerName, csvData[j].FullName),
                    attachments: [{
                        filename: 'sertifikat.jpg',
                        path: imageLink.location
                    }]
                });
                success.push(csvData[j].Email);
                console.log(email)
            } catch (e) {
                console.log(e);
            }
        }
        setIsLoadingBar(false);
        let successMessage = '';
        for (const i in success) {
            successMessage = successMessage + ',' + success[i];
        }
        alert('File telah dikirim ke email: ' + successMessage)
    }

    const dummyUsers = [
        {
            FullName: 'Raka Zuhdi',
            Email: 'rakazhp@gmail.com'
        },
        {
            FullName: 'Daniel Amazia',
            Email: 'DanielAmazia@gmail.com'
        }
    ]

    const convertCertificateToBlob = async (result) => {
        let responseBlob = await fetch(result);
        let resultBlob = await responseBlob.blob();
        console.log('blobbed base64', responseBlob)
        return resultBlob;
    }

    const getParticipants = async () => {

        setIsLoading(true)

        let classModel = new Class()
        let result = await classModel.getClassParticipant(event_url)
        console.log(result)

        let totalIncome = 0;

        let registeredRegistrants = []

        for (let r of result) {

            if (r.status === "ACCEPTED") {
                registeredRegistrants.push(
                    {
                        ...r.member,
                        ...r,
                        full_name_sorter: r.member?.full_name.toUpperCase()
                    }
                )
                setName(r.member?.full_name)
                setTestingName(r.member?.full_name)
            }

        }

        setIsLoading(false)

        setRegistrants(registeredRegistrants)

    }

    useEffect(() => {
        getParticipants()
    }, [])

    const renderAlertBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={errorMessage.length > 0}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErrorMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {errorMessage}
                </Alert>
            </Collapse>
        </div>
    }

    const renderSuccessBox = () => {
        return <div style={{
            marginBottom: "1em"
        }}>
            <Collapse in={successMessage.length > 0}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setSuccessMessage("");
                            }}
                        >
                            <FaTimes fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {successMessage}
                </Alert>
            </Collapse>
        </div>
    }

    let tryImage = new window.Image();
    // tryImage.src="https://patjarmerah.com/static/media/festival-buku-asia-logo.54f7ac9e.png"
    tryImage.src = backgroundImageURL
    tryImage.onload = () => {
        if (imageRef) {
            imageRef.getLayer().batchDraw()
        }
    }

    const renderNewEditor = () => {

        let realWidth = 3503
        let realHeight = 2480

        return <div style={{width: "100%"}} ref={ref => {
            if (canvasWidth === 0 && ref !== undefined && ref?.clientWidth) {
                setCanvasWidth(640)
                setCanvasHeight(452)
            }
        }}>
            <Stage
                ref={ref => stageRef = ref}
                style={{
                    background: "#ffefff",
                    width: "100%",
                    height: canvasHeight
                }}
                width={canvasWidth} height={canvasHeight}>
                <Layer>
                    {/*UNTUK DEFINE GAMBAR*/}
                    <Image
                        ref={ref => {
                            imageRef = ref
                            // console.log(ref)
                            // console.log(ref?.image())
                        }}
                        width={canvasWidth}
                        height={canvasHeight}
                        image={tryImage}/>
                    {/*UNTUK MASUKIN TULISAN*/}
                    <Text
                        align={"center"}
                        fontSize={fontSize}
                        fontStyle={"bold"}
                        fill={textColor}
                        x={canvasWidth * left / 100}
                        y={canvasHeight * top / 100}
                        offsetY={fontSize / 2}
                        offsetX={canvasWidth / 2}
                        width={canvasWidth}
                        text={name ? name.toUpperCase() : ""}/>
                </Layer>
            </Stage>
        </div>
    }

    const waitTimeout = (ms) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, ms)
        })
    }

    const generateAllCertificate = async () => {

        let participation = new Participation()

        let success = 0
        setIsUploading(true)

        try {

            let number = 0;

            let sortedRegistrants = _.orderBy(registrants, (obj) => {
                return obj?.member?.full_name.toLowerCase()
            }, ['asc'])

            for (let r of sortedRegistrants) {

                setName(r?.member?.full_name)

                number++;

                console.log(number + " " + r.id + " generating for " + r?.member?.full_name)

                setNumberPrefix(textFormatter.zeroPadder(number, 3))

                if (!r.predicate) {
                    continue
                }

                setPredicate(r.predicate)

                await waitTimeout(200)

                let result = stageRef.toDataURL({pixelRatio: 1})

                const pdf = new jsPDF(
                    'l', 'mm', [297, 210]
                );

                pdf.addImage(result, 'PNG', 0, 0, 297, 210, undefined, "FAST");

                let pdfResult = pdf.output('blob');

                console.log(pdfResult)

                try {
                    let result = await participation.uploadSingleCertificate(pdfResult, r.id, event_url)
                    console.log(result)
                    console.log("success " + r.id)

                    success++;
                    setSuccessCount(success)
                } catch (e) {
                    console.log(e)
                }

            }
        } catch (e) {
            setIsUploading(false)
            alert("Sukses mengunggah " + success + "/" + registrants.length)

            console.log(e)

            getParticipants()

            return
        }

        setIsUploading(false)
        alert("Sukses mengunggah " + success + "/" + registrants.length)

    }

    const renderContent = () => {
        return <div style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "OpenSans-Regular",
        }}>

            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{
                    color: Palette.PRIMARY,
                    fontSize: "1.75em",
                    marginRight: 25,
                }}>
                    Sertifikat
                </div>

            </div>

            <div>
                <CSVLink
                  style={{marginLeft: "1em", marginRight: "1em", color: 'white'}}
                  separator={";"}
                  data={
                      `FullName;Email
Nama1;nama1@gmail.com
Nama2;nama2@gmail.com`
                  }
                  filename={`template.csv`}
                  className="btn btn-primary"
                  target="_blank"
                >
                    Unduh Contoh Format &nbsp;&nbsp;<FaFileDownload/>
                </CSVLink>

                <Button
                    style={{
                        marginRight: 10,
                        color: "white"
                    }}
                    variant={"primary"}
                    onClick={() => {
                        setIsUploadModalOpen(true)
                    }}
                >
                    Upload CSV&nbsp;&nbsp;
                </Button>

            </div>

            <div style={{
                paddingTop: "1em",
                paddingBottom: "1em",
            }}>
                <MobTable
                    columns={csvHeader} data={csvData}/>
            </div>

            <Container fluid>
                <Row>
                    <Col md={6} style={{marginTop: 20}}>
                        {/*{renderOldEditor()}*/}
                        {renderNewEditor()}
                    </Col>
                    <Col md={12}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <b>Template Sertifikat</b>
                            </div>
                            <div>
                                Harap Unggah Template Gambar (Rasio A4)
                                <div style={{
                                    marginTop: 10,
                                    fontSize: "1.1em",
                                    display: "flex",
                                    flexDirection: "row"
                                }}>
                                    <FileUpload
                                        allowedType={["image/png", "image/jpeg", "image/jpg "]}
                                        text={"Unggah Halaman 1"}
                                        onDrop={async (result) => {
                                            let r = URL.createObjectURL(result[0])
                                            setUploadedImage(result[0]);
                                            console.log(result[0])

                                            setBackgroundImageURL(r)
                                            setTemplateName(result[0]?.name)
                                        }}
                                    />

                                    <div style={{width: "1em"}}/>
                                </div>

                            </div>
                            <div style={{display: "flex", flexDirection: "row", marginTop: 20}}>
                                <Button
                                    onClick={async () => {
                                        //Convert stageRef jadi blob
                                        const pdf = new jsPDF(
                                            'l', 'mm', [200, 150]
                                        );
                                        let result = stageRef.toDataURL({pixelRatio: 1})

                                        pdf.addImage(result, 'JPEG', 0, 0, 200, 150, '', "FAST");
                                        pdf.save("download.pdf");

                                        let a = document.createElement("a"); //Create <a>
                                        a.href = result; //Image Base64 Goes here
                                        a.download = "Image.png"; //File name Here
                                        a.click(); //Downloaded file
                                    }}
                                    disabled={isLoadingBar}
                                    variant={"primary"}
                                    style={{color: "white"}}>
                                    Download Satu
                                </Button>
                                {/*}
                                <Button
                                    onClick={() => {
                                        generateAllCertificate()
                                    }}
                                    disabled={isUploading}
                                    variant={"primary"} style={{marginLeft: 10, color: "white"}}>
                                    Generate dan upload untuk semua
                                </Button>
                                {*/}
                                <Button
                                    style={{marginLeft: 10}}
                                    onClick={() => {
                                        if (csvData.length === 0) {
                                            console.log('Error', 'CSV is MISSING')
                                            return alert('Mohon upload daftar email (file csv) terlebih dahulu!');
                                        }
                                        if(!className || !classDateFormatted || !speakerName){
                                            return alert('Mohon isi form dibawah dengan lengkap!')
                                        }
                                        sendCertificate()
                                    }}
                                >
                                    Upload dan kirim sertifikat ke email.
                                </Button>
                                {
                                    isUploading ?
                                        <div style={{
                                            marginLeft: 10,
                                            display: "flex", justifyContent: "center", alignItems: "center"
                                        }}>
                                            ({successCount}/{csvData.length})
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <LagFreeTextEditor
                            label={"Nama Peserta (Untuk Testing)"}
                            fullWidth
                            value={name}
                            disabled={isCsvUploaded}
                            placeholder={"Untuk Testing"}
                            changeValue={value => {
                                setName(value)
                                setTestingName(value)
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={12} style={{paddingTop: 30}}>
                        <b>Halaman</b>
                    </Col>
                    <Col md={4} style={{
                        paddingTop: 20,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end"
                    }}
                    >
                        <ColorPicker
                            label={"Warna Text"}
                            name='color'
                            fullWidth={true}
                            style={{
                                flex: 1
                            }}
                            value={textColor}
                            defaultValue={"Klik Untuk Mengubah Warna"}
                            // value={this.state.color} - for controlled component
                            onChange={color => setTextColor(color)}
                        />
                        {textColor}
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Ukuran Font"}
                                    onChange={e => {
                                        setFontSize(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isLoadingBar}
                                    value={fontSize}/>
                                <Slider
                                    value={fontSize}
                                    min={1}
                                    step={1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setFontSize(value)
                                    }}
                                    disabled={isLoadingBar}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>

                    </Col>

                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Posisi Horizontal"}
                                    onChange={e => {
                                        setLeft(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isLoadingBar}
                                    value={left}/>
                                <Slider
                                    value={left}
                                    min={1}
                                    step={0.1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setLeft(value)
                                    }}
                                    disabled={isLoadingBar}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>

                    </Col>

                    <Col md={4}>
                        <div style={{marginTop: "1em"}}>
                            <div>
                                <TextField
                                    label={"Posisi Vertikal"}
                                    onChange={e => {
                                        setTop(e.target.value)
                                        // console.log(value + " ! " + fontSize)
                                    }}
                                    disabled={isLoadingBar}
                                    value={top}/>
                                <Slider
                                    value={top}
                                    min={1}
                                    step={0.1}
                                    max={100}
                                    scale={(x) => x}
                                    // getAriaValueText={valueLabelFormat}
                                    // valueLabelFormat={valueLabelFormat}
                                    onChange={(e, value) => {
                                        // console.log(value)
                                        setTop(value)
                                    }}
                                    disabled={isLoadingBar}
                                    style={{flex: 1}}
                                    // valueLabelDisplay="auto"
                                    // aria-labelledby="non-linear-slider"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{marginTop: "1em"}}>
                            <b>Email</b>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LagFreeTextEditor
                            label={"Subjek Email"}
                            fullWidth
                            value={emailSubject}
                            disabled={isLoadingBar}
                            placeholder={"Subjek Email"}
                            changeValue={value => {
                                setEmailSubject(value);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LagFreeTextEditor
                            label={"Nama Pembicara"}
                            fullWidth
                            value={speakerName}
                            disabled={isLoadingBar}
                            placeholder={"Nama Pembicara"}
                            changeValue={value => {
                                setSpeakerName(value);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LagFreeTextEditor
                            label={"Nama Kelas"}
                            fullWidth
                            value={className}
                            disabled={isLoadingBar}
                            placeholder={"Nama Kelas"}
                            changeValue={value => {
                                setClassName(value);
                            }}
                        />
                    </Col>
                </Row>
                <div style={{marginLeft:10, marginTop: 30}}>
                    <Row>
                        <div><b>Tanggal & Waktu</b></div>
                    </Row>
                    <Row style={{marginRight: 10}}>
                        <Col md={6} style={{marginTop: 20}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    allowKeyboardControl={false}
                                    // disablePast={true}
                                    autoOk={true}
                                    // disabled={publishStatus === PUBLISH_STATUS.DONE}
                                    // minDateMessage={"Tanggal mulai harus lebih kecil dari tanggal berakhir"}
                                    /*
                                    style={{
                                        marginLeft: "0.5em",
                                        width: "15em",
                                        cursor: "pointer",
                                        fontSize: '1.2em'
                                    }}
                                     */
                                    format="D MMM YYYY"
                                    InputProps={{
                                        style: {
                                            fontSize: "1em",
                                            fontFamily: "OpenSans-Regular",
                                            cursor: "pointer"
                                        },
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <MdEvent style={{color: Palette.PRIMARY}}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={classDate}
                                    onChange={date => {
                                        let result = new moment(date).format("dddd, D MMMM")
                                        console.log('formatted', result)
                                        setClassDate(date)
                                        setClassDateFormatted(result);
                                    }}
                                    placeholder={'Tanggal Kelas'}/>
                            </MuiPickersUtilsProvider>
                        </Col>

                        <Col md={6} style={{marginTop: 20}}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <TimePicker
                                    allowKeyboardControl={false}
                                    // disablePast={true}
                                    autoOk={true}
                                    // disabled={publishStatus === PUBLISH_STATUS.DONE}
                                    // minDateMessage={"Tanggal mulai harus lebih kecil dari tanggal berakhir"}
                                    /*
                                    style={{
                                        marginLeft: "0.5em",
                                        width: "15em",
                                        cursor: "pointer",
                                        fontSize: '1.2em'
                                    }}

                                     */

                                    format="HH:mm"
                                    InputProps={{
                                        style: {
                                            fontSize: "1em",
                                            fontFamily: "OpenSans-Regular",
                                            cursor: "pointer"
                                        },
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton>
                                                    <MdSchedule style={{color: Palette.PRIMARY}}/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={classTime}
                                    onChange={time => {
                                        let result = new moment(time).format("HH:mm")
                                        let format = ', pukul ' + result + ' WIB';
                                        console.log('formatted time', format)
                                        setClassTime(time)
                                        setClassTimeFormatted(format);
                                    }}
                                    placeholder={'Waktu Kelas'}/>
                            </MuiPickersUtilsProvider>
                        </Col>

                    </Row>
                </div>
            </Container>
        </div>
    }

    return (
        <>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isUploadModalOpen}
                onHide={() => setIsUploadModalOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Kirim ke banyak email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileUpload
                        allowedType={["text/csv", "application/vnd.ms-excel"]}
                        hideSpinner={true}
                        text={"Unggah File CSV"}
                        // isLoading={isLoadingBar}
                        buttonStyle={{
                            fontSize: "2e0m"
                        }}
                        onDrop={async (result) => {

                            let reader = new FileReader();
                            reader.readAsText(result[0], "UTF-8");
                            reader.onload = async (e) => {

                                console.log(Papa.BAD_DELIMITERS)
                                let stringCSV = e.target.result

                                let parseResult = Papa.parse(stringCSV, {
                                    delimiter: ";",
                                    header: true,
                                    skipEmptyLines: true
                                });
                                console.log(parseResult)


                                setName(parseResult.data[0].FullName);
                                setTestingName(parseResult.data[0].FullName);

                                setUploadedUsersCSV(parseResult.data)
                                setCsvData(parseResult.data)
                                setIsCsvUploaded(true);
                            }
                        }}
                    />
                    <MobTable columns={csvHeader} data={csvData}></MobTable>
                    <Button
                        onClick={(event => setIsUploadModalOpen(false))}
                        style={{float: 'right', marginTop: 20}}
                    >
                        Kembali
                    </Button>
                </Modal.Body>

            </Modal>
            <EditorPageWrapper
                isLoadingBar={isLoadingBar || isUploading}
                isLoadingFull={isLoading}
                activeTab={"certificate"}
            >

                {renderAlertBox()}
                {renderSuccessBox()}

                {
                    renderContent()
                }


                <MultiPredicateModal
                    show={showMultiPredicateModal}
                    participations={registrants}
                    onClose={(refresh) => {
                        setShowMultiPredicateModal(false)
                        console.log("is refresh ? " + refresh)
                        if (refresh) {
                            console.log("on lcose true")
                            getParticipants()
                        }
                    }}
                />

                <CertificatePredicateModal
                    show={showPredicateModal}
                    participant={activeParticipant}
                    onClose={(refresh) => {
                        setShowPredicateModal(false)
                        setActiveParticipant({})
                        console.log("is refresh ? " + refresh)
                        if (refresh) {
                            console.log("on lcose true")
                            getParticipants()
                        }
                    }}
                />

            </EditorPageWrapper>
        </>
    );

}
